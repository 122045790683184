import useAppDispatch from "@/hooks/useAppDispatch";
import useAppSelector from "@/hooks/useAppSelector";
import useModalAuth from "@/hooks/useModalAuth";
import { COUNTRYCODE, GOOGLE_MAP_LIBRARIES, GOOGLE_MAPS_API_KEY } from "@/recourses/constants";
import { genericsAction } from "@/store/generics";
import { useJsApiLoader } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import useAddressModal from "@/hooks/useAddressModal";
import useScrollPosition from "@/hooks/useScrollPosition";
import { pageView } from "@/utils/analyticsV2";
import { useLocation } from "react-router-dom";
import useAnalytics from "@/hooks/useAnalytics";
import useModalHearAboutUs from "@/hooks/useModalHearAboutUs";
import useQueryParams from "@/hooks/useQueryParams";
import { getCities } from "@/utils/CountriesConfig";

const cities = getCities(COUNTRYCODE);

export default function useRootLayout() {
  const dispatch = useAppDispatch();
  const { queryParams, setQueryParams } = useQueryParams();
  const { handleCloseModalAuth, isOpen: isAuthModalOpen } = useModalAuth();
  const { openModalHearAboutUs, handleCloseModalHearAboutUs, type } = useModalHearAboutUs();
  const storeId = useAppSelector((state) => state.cart.storeId);
  const showLocationModal = useAppSelector((state) => state.popUpsReminder.showLocationModal);
  const [oldStoreId, setOldStoreId] = useState<number | undefined>(storeId);
  const { pathname } = useLocation();
  const { pageView: pageViewHook } = useAnalytics();
  const [resetScroll, setResetScroll] = useState(false);
  const [region, setRegion] = useState<CityType | Omit<LocationType, "test" | "isPickUp"> | null>(null);

  const listStores = cities?.filter((city) => city.is_available || city.is_new);
  const regionId = queryParams?.get("region_id");

  const {
    isOpen: isAddressModalOpen,
    toggleAddressModal: setIsAddressModalOpen,
    handleChangeLocation,
  } = useAddressModal();

  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
    libraries: GOOGLE_MAP_LIBRARIES,
    language: "es",
    region: "CO",
  });

  const handleCloseModalInformativeChangeLocation = () => {
    setOldStoreId(storeId);
  };

  useEffect(() => {
    dispatch(genericsAction.setIsLoadedScriptGoogleMaps({ isLoaded, loadError }));
  }, [isLoaded]);

  useEffect(() => {
    if (region?.lat) return;
    setOldStoreId(storeId);
  }, [storeId !== undefined, region?.lat]);

  useEffect(() => {
    pageView.eventLocation();
    pageView.eventTiming();
  }, []);

  const handleReset = () => {
    setResetScroll((prev) => !prev); // Cambia el valor de resetScroll para activar el reset
  };

  const checkPageCategory = () => {
    pageViewHook.eventPageCategory();
    handleReset();
  };

  useEffect(() => {
    // Search region id (google) on location and set region
    if (regionId) {
      const region = listStores?.find((city) => city.region_id === regionId) ?? null;
      setRegion(region);

      if (region) {
        void handleChangeLocation(region).then(() => {
          setQueryParams({ region_id: null });
        });
      } else {
        setQueryParams({ region_id: null });
      }
    }
  }, [regionId]);

  useEffect(() => {
    // 2- History Change event
    pageView.eventPathname(pathname);

    // 3- Event with information aditional
    checkPageCategory();
  }, [pathname]);

  useScrollPosition({
    threshold: 25,
    callback: () => pageView.eventPageScroll(25),
    reset: resetScroll,
  });
  useScrollPosition({
    threshold: 50,
    callback: () => pageView.eventPageScroll(50),
    reset: resetScroll,
  });
  useScrollPosition({
    threshold: 75,
    callback: () => pageView.eventPageScroll(75),
    reset: resetScroll,
  });
  useScrollPosition({
    threshold: 100,
    callback: () => pageView.eventPageScroll(100),
    reset: resetScroll,
  });

  return {
    handleCloseModalAuth,
    isAuthModalOpen,
    oldStoreId,
    storeId,
    isOpenModalInformativeChangeLocation:
      storeId !== undefined && storeId !== oldStoreId && !showLocationModal && !region,
    isAddressModalOpen: isAddressModalOpen && !region,
    handleCloseModalInformativeChangeLocation,
    setIsAddressModalOpen,
    openModalHearAboutUs,
    handleCloseModalHearAboutUs,
    typeModalHearAboutUs: type,
  };
}
