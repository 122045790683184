import Modal from "@/components/Atoms/Modal";
import tw, { css } from "twin.macro";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Image from "@/components/Atoms/Image";
import srcImgHearAboutUs from "@/assets/common/hearAboutUs.webp";
import IconHeart from "@/components/Icons/V2/Controllers/heart-gradient.svg?react";
import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";

import IconPassed from "@/components/Icons/V2/Controllers/radio-checked-outline-gradient.svg?react";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import Select, { type OptionSelect, type SelectProps } from "../Select";
import Button from "@/components/Atoms/Button";

import {
  StyledContentHearAboutUs,
  StyledContentRoot,
  StyledHeaderAboutUs,
  StyledModalHearAboutUsRegistrationRoot,
  StyledRowContent,
} from "./styles";

const memberBenefits = [
  "Elige entre más de 1500 productos saludables.",
  "Recibe información sobre salud y belleza.",
  "Accede a promociones y eventos especiales.",
];
interface ModalHearAboutUsRegistrationProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSelect: (select: OptionSelect | null) => void;
  handleSubmit: () => void;
  isSubmitDisabled: boolean;
  options: SelectProps["options"];
}

const ModalHearAboutUsRegistration = ({
  isOpen,
  handleClose,
  handleSelect,
  handleSubmit,
  isSubmitDisabled,
  options,
}: ModalHearAboutUsRegistrationProps) => {
  const isMedium = useMediaQuery("(min-width: {medium})");
  return (
    <Modal
      preventScroll={!isMedium}
      closeOutSise
      handleClose={handleClose}
      isOpen={isOpen}
      classes={{
        backdrop: css`
          ${tw`overflow-auto z-[52]`}
          ${!isMedium ? tw`block` : tw`flex`}
        `,
        card: css`
          ${tw`p-0`}
          ${isMedium ? tw`rounded-xl` : tw`rounded-none h-[100dvh] pb-m  overflow-auto `}
        `,
      }}
    >
      <StyledModalHearAboutUsRegistrationRoot>
        {isMedium && <Image src={srcImgHearAboutUs} />}

        <StyledContentRoot>
          {isMedium && (
            <IconClose
              tw=" self-start justify-self-end cursor-pointer "
              width={24}
              height={24}
              onClick={handleClose}
            />
          )}

          <StyledContentHearAboutUs>
            {!isMedium && (
              <StyledHeaderAboutUs>
                <IconClose width={24} height={24} onClick={handleClose} />
              </StyledHeaderAboutUs>
            )}
            <IconHeart width={60} height={60} />
            <Heading type="h4">Ahora eres parte de Fithub</Heading>
            <Paragraph sizeVariant="md">
              Ahora haces parte de la comunidad que evoluciona tus hábitos.
            </Paragraph>

            {memberBenefits.map((benefit, index) => {
              return (
                <StyledRowContent key={index}>
                  <IconPassed width={!isMedium ? 20 : 24} height={!isMedium ? 20 : 24}></IconPassed>
                  <Paragraph sizeVariant="sm"> {benefit}</Paragraph>
                </StyledRowContent>
              );
            })}

            <Heading type="h6">Para terminar</Heading>

            <Select label="¿Dónde nos conociste?" onChange={handleSelect} options={options || []} />
          </StyledContentHearAboutUs>
          <Button
            sizeVariant="lg"
            color="secondary"
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
            tw="h-max self-end medium:(self-start)"
          >
            Enviar
          </Button>
        </StyledContentRoot>
      </StyledModalHearAboutUsRegistrationRoot>
    </Modal>
  );
};
export default ModalHearAboutUsRegistration;
