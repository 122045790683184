import posthog from "posthog-js";
import { AddDetailView, ApiClient, AddPurchase, Batch, AddCartAddition } from "recombee-js-api-client";
import { APP_RECOMBEE_DB, APP_RECOMBEE_PUBLIC_KEY } from "../constants";

export const recombeeClient = new ApiClient(APP_RECOMBEE_DB, APP_RECOMBEE_PUBLIC_KEY);

export const addDetailViewProduct = async (itemdID = "", recommId?: string | null) => {
  const userID = posthog.get_distinct_id();

  const recombeeOptions: Record<string, any> = {
    timestamp: new Date().toISOString(),
    cascadeCreate: true,
  };
  if (recommId) {
    recombeeOptions.recommId = recommId;
  }

  try {
    const add = new AddDetailView(userID, itemdID, recombeeOptions);
    return await recombeeClient.send(add);
  } catch (error) {
    console.warn("Add DetailView product", error);
    return null;
  }
};

export const addPurchaseOrderProduct = async (
  itemdIds: Array<{ itemID: string; amount: number; price: number; recommId?: string }>
) => {
  const userID = posthog.get_distinct_id();

  const recombeeOptions: Record<string, any> = {
    timestamp: new Date().toISOString(),
    cascadeCreate: true,
  };

  const purchases = itemdIds.map(({ itemID, price, amount, recommId }) => {
    recombeeOptions.price = price;
    recombeeOptions.quantity = amount;

    if (recommId) {
      recombeeOptions.recommId = recommId;
    }
    return new AddPurchase(userID, itemID, recombeeOptions);
  });

  try {
    return await recombeeClient.send(new Batch(purchases, { distinctRecomms: true }));
  } catch (error) {
    console.warn("Add Purchase product", error);
    return null;
  }
};

export const AddCartProduct = async (
  itemdID = "",
  options?: { amount?: number; price?: number; recommId?: string }
) => {
  const userID = posthog.get_distinct_id();

  const recombeeOptions: Record<string, any> = {
    timestamp: new Date().toISOString(),
    cascadeCreate: true,
    ...options,
  };

  try {
    const add = new AddCartAddition(userID, itemdID, recombeeOptions);
    return await recombeeClient.send(add);
  } catch (error) {
    console.warn("Add cartAddition product", error);
    return null;
  }
};
