import { type UserPriceType } from "../../store/user";

const getProductsTotal = (cart: Cart[]): number => {
  let sum = 0;
  for (const { availability, quantity } of cart) {
    sum += availability.priceUndiscounted.gross * quantity;
  }
  return sum;
};

const getProductsDiscounts = (cart: Cart[], hasMembership: boolean = false): discountsSummary => {
  let regularDiscountSum = 0;
  let membershipDiscountSum = 0;

  for (const { availability, quantity } of cart) {
    if (hasMembership && availability.priceMembership?.gross) {
      membershipDiscountSum +=
        (availability.priceUndiscounted.gross - availability.priceMembership?.gross) * quantity;
    } else if (availability.discount) {
      regularDiscountSum += availability.discount.gross * quantity;
    }
  }
  return {
    regularDiscounts: regularDiscountSum,
    membershipDiscounts: membershipDiscountSum,
  };
};

const getCreditsAvailable = (productsSubtotal: number, fitpoints: PriceType | undefined): creditsSummary => {
  const totalFriendpoints = fitpoints ? Number(fitpoints.gross) : 0;
  const availableFriendpoints = Math.min(productsSubtotal, fitpoints ? Number(fitpoints.gross) : 0);

  return {
    creditsTotal: totalFriendpoints,
    creditsAvailable: availableFriendpoints,
  };
};

const getReferralCreditsAvailable = (
  productsSubtotal: number,
  friendpointInfo: UserPriceType | undefined
): referralCreditsSummary => {
  const totalFriendPoints = friendpointInfo ? Number(friendpointInfo.gross) : 0;
  const availableFriendPoints =
    totalFriendPoints > 0 ? Math.min(Math.floor(productsSubtotal / 2), totalFriendPoints) : 0;

  return {
    referralCreditsTotal: totalFriendPoints,
    referralCreditsAvailable: availableFriendPoints,
  };
};

const getCouponDiscount = (total: number, coupon: Coupon | undefined | null): number => {
  if (coupon && coupon.is_valid) {
    // Check that min_amount has passed
    if (coupon.min_amount && total < Number(coupon.min_amount.gross)) return 0;

    if (coupon.discount_value_type === "percentage") {
      const value = total * (coupon.discount_value / 100);
      // Check that discount_maximum_value is present, in that case
      // returns the min value between total and it
      if (coupon.discount_maximum_value && Number(coupon.discount_maximum_value.gross) > 0)
        return Math.min(Number(coupon.discount_maximum_value.gross), value);
      return value;
    } else if (coupon.discount_value_type === "fixed") {
      return Math.min(coupon.discount_value, total);
    }
  }
  return 0;
};
const getService = (serviceInfo: getDeliveryPriceResponse | undefined): number | undefined => {
  if (!!serviceInfo && serviceInfo.price) {
    return Number(serviceInfo.price.gross);
  }
  return undefined;
};

const getProductsSummaryInfo = (
  products: Cart[],
  fitpoints: PriceType | undefined,
  friendpointInfo: UserPriceType | undefined,
  couponInfo: Coupon | null,
  config: getProductParams | undefined = {
    hasMembership: false,
    useCoupon: false,
    useFitpoints: false,
    useFriendPoints: false,
  }
): getProductsSummaryInfoResponse => {
  // Get products subtotal without discounts
  const productsSubtotal = getProductsTotal(products);

  // Get products discounts (regular and membership)
  const productsDiscount = getProductsDiscounts(products, config.hasMembership);

  // Get credits available (friendpoints)
  const creditsSummary = config.useFitpoints
    ? getCreditsAvailable(productsSubtotal, fitpoints)
    : {
        creditsTotal: 0,
        creditsAvailable: 0,
      };

  const productDiscoutedTotal =
    productsSubtotal - productsDiscount.regularDiscounts - productsDiscount.membershipDiscounts;
  // Get products total with discounts and credits
  let productsSemiTotal =
    productsSubtotal -
    productsDiscount.membershipDiscounts -
    productsDiscount.regularDiscounts -
    creditsSummary.creditsAvailable;

  // Get referral credits available (friendpoints)
  const referralCreditsSummary = getReferralCreditsAvailable(productsSemiTotal, friendpointInfo);

  // apply referral credits to products total
  productsSemiTotal =
    productsSemiTotal - (config.useFriendPoints ? referralCreditsSummary.referralCreditsAvailable : 0);

  // Get coupon discount
  const couponDiscount = config.useCoupon ? getCouponDiscount(productsSemiTotal, couponInfo) : 0;

  // Get products total with discounts, credits and coupon
  const ProductsTotal = Math.max(productsSemiTotal - couponDiscount, 0);

  return {
    productsSubtotal,
    productDiscoutedTotal,
    productsDiscount,
    creditsSummary,
    referralCreditsSummary,
    couponDiscount,
    ProductsTotal,
  };
};

export {
  getProductsTotal,
  getProductsDiscounts,
  getCreditsAvailable,
  getReferralCreditsAvailable,
  getProductsSummaryInfo,
  getService,
};
