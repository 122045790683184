import { countries } from "../../utils/CountriesConfig";

const latitude = Number.parseFloat(countries[0].cities[0].lat);
const longitude = Number.parseFloat(countries[0].cities[0].lng);

export const locationInitialState: LocationType = {
  address: countries[0].cities[0].name,
  extra: "",
  lat: latitude,
  lng: longitude,
  test: true,
  isPickUp: false,
  city: countries[0].cities[0].name,
};

export const locationEmptyState: LocationType = {
  address: "",
  extra: "",
  lat: latitude,
  lng: longitude,
  test: true,
  isPickUp: false,
};

export const availableStoreSliceEmptyState: AvailableStoresSliceType = {
  city: "",
  name: "",
  stores: [],
  type: "",
  validateAt: undefined,
};

export const emptyState: CartStoreAttr = {
  location: locationInitialState,
  availableStores: availableStoreSliceEmptyState,
  storeId: undefined,
  items: [],
  last_change_items: undefined,
  lastUsedAddresses: {},
};
