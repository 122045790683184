import type { TSize } from "@/types/common";
import tw, { css, styled, type TwStyle } from "twin.macro";

const stylesLabel = css<{ $size?: TSize; $textColor?: TwStyle }>`
  & > p {
    ${tw`font-bold
    `}
    ${({ $textColor }) =>
      $textColor &&
      css`
        ${$textColor}
      `}
  }
`;

export const StyledWrapperChip = styled.div<{ $bgColor: TwStyle; $borderColor: TwStyle; $size?: TSize }>`
  ${tw`relative p-[0.25rem 0.25rem 0.25rem 0.5rem] flex items-center justify-center rounded-[6.25rem 0rem 0rem 6.25rem] border-t border-b border-l`}
  ${({ $bgColor, $borderColor }) => css`
    ${$bgColor}
    ${$borderColor}
  `}
  &:before {
    ${({ $borderColor }) => css`
      ${$borderColor}
    `}
    ${tw`border-l-transparent h-full [content: ''] absolute border-solid z-10 rotate-180 [border-width: 0.64rem 0.08em 0.63rem 0.4em] -right-[7px]
      medium:([border-width: 0.71rem 0.15em 0.7rem 0.5em] -right-[10px])
      large:([border-width: 0.76rem 0.25em 0.75rem 0.5em] -right-[12px])
    `}
    ${({ $size }) => {
      if ($size) {
        switch ($size) {
          case "small":
            return tw`[border-width: 0.64rem 0.08em 0.63rem 0.4em]! -right-[7px]!`;
          case "medium":
            return tw`[border-width: 0.71rem 0.15em 0.7rem 0.5em]! -right-[10px]!`;
          case "large":
            return tw`[border-width: 0.76rem 0.25em 0.75rem 0.5em]! -right-[12px]!`;
        }
      }
    }}
  }
  ${stylesLabel}
`;

export const StyledWrapperRoundedChip = styled.div<{ $bgColor: TwStyle; $textColor: TwStyle; $size?: TSize }>`
  ${tw`relative py-1 px-2 flex items-center justify-center rounded-[6.25rem]`}
  ${({ $bgColor, $textColor }) => css`
    ${$bgColor}
    ${$textColor}
  `}
  ${stylesLabel}
`;
