const ORDER_MIN_AMOUNT: string = import.meta.env.REACT_APP_ORDER_MIN_AMOUNT || "";

const isValidateServicePrice = (value: any): boolean => {
  value = cleaningNullValue(value);

  if (!value.id) return false;
  if (!value.address) return false;
  if (!value.total || value.total <= 0) return false;
  return true;
};

const canCreateOrder = (value: any, total: number): boolean => {
  value = cleaningNullValue(value);
  if (!value.service) return false;
  if (!value.user_payment_method) return false;
  if (total < parseFloat(ORDER_MIN_AMOUNT) && total > 0) return false;
  return true;
};

const cleaningNullValue = (object: any): any => {
  for (const key in object) {
    if (object[key] === null || object[key] === undefined) {
      delete object[key];
    }
  }
  return object;
};

const getColorFromStatus = (status): any => {
  switch (status) {
    case "created":
      return {
        primary: "#34b6e4",
        secondary: "#def6ff",
      };
    case "reserved":
      return {
        primary: "#34b6e4",
        secondary: "#def6ff",
      };
    case "in_progress":
      return {
        primary: "#F96800",
        secondary: "#fff3ed",
      };
    case "shipped":
      return {
        primary: "#F96800",
        secondary: "#fff3ed",
      };
    case "cancelled":
      return {
        primary: "#f63939",
        secondary: "#fedede",
      };
    case "completed":
      return {
        primary: "#53da3f",
        secondary: "#eaffe7",
      };
    case "confirmed":
      return {
        primary: "#53da3f",
        secondary: "#eaffe7",
      };
    default:
      return {
        primary: "#34b6e4",
        secondary: "#def6ff",
      };
  }
};

export { isValidateServicePrice, canCreateOrder, getColorFromStatus, cleaningNullValue };
