import { useMemo } from "react";
import posthog from "posthog-js";
import { CHRISTMAS_THEME } from "@/recourses/constants";

export default function useFeatureFlags() {
  const isChristmasTheme = useMemo(() => {
    const posthogChristmasTheme = posthog.isFeatureEnabled("christmasTheme");

    if (posthogChristmasTheme) {
      return posthogChristmasTheme;
    }

    return CHRISTMAS_THEME;
  }, []);

  return {
    isChristmasTheme,
  };
}
