import type { HTMLAttributes } from "react";
import type { CSSProperties } from "styled-components";
import type { ITypographyCommon, THeading } from "@/types/common";
import { StyledWrapperHeading } from "./styles";

export interface IHeading extends HTMLAttributes<HTMLHeadingElement>, Omit<ITypographyCommon, "sizeVariant"> {
  fontWeight?: CSSProperties["fontWeight"];
  type?: THeading;
  variant?: THeading;
}

const Heading = ({ size, color, type = "h2", variant, fontWeight, disabled = false, ...props }: IHeading) => {
  return (
    <StyledWrapperHeading
      as={type}
      $type={type}
      $variant={variant}
      $fontWeight={fontWeight}
      {...props}
      $size={size}
      $color={color}
      $disabled={disabled}
    />
  );
};

export default Heading;
