import useElementOnScreen from "@/hooks/useElementOnScreen";
import { generateLead } from "@/utils/analyticsV2";
import { useState } from "react";

const idPromo = "newsletter_footer";

const NewLetter = () => {
  const [oneTimeAnalytic, setOneTimeAnalytic] = useState(false);

  useElementOnScreen({
    id: idPromo,
    waitFor: !oneTimeAnalytic,
    callback: (show) => {
      if (show) {
        generateLead.generateLeadForm({
          value: undefined,
          currency: undefined,
          formName: idPromo,
          formLength: 1,
          formFields: ["email"],
        });
        setOneTimeAnalytic(true);
      }
    },
  });

  return (
    <div id={idPromo}>
      <div className="klaviyo-form-Ximaid KlaviyoForm" />
      <div className="klaviyo-form-Tyx4tm" tw="w-full"></div>
    </div>
  );
};

export default NewLetter;
