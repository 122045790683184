import { request } from "../../utils/request";

import http from "@/utils/http";
import * as storeUrl from "@/recourses/urls/store";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;

const AVALIABLE_STORES_COORDS = (lat: number, lng: number): string => `lat=${lat}&lng=${lng}`;
const STORE = "stores";
const PICK_UP_STORE = "pick-up-stores/";
const SERVICE = "services/";

const getAvaliableStores = async (_key: string, lat: number, lng: number): Promise<AvaliableStoresAttr> => {
  const response = await http.get(storeUrl.storesAvailable(lat, lng), { isAuth: false });
  return response.data;
};

const getStoreServices = async (storeId: any, lat: number, lng: number): Promise<AvailableServiceAttr[]> => {
  const response = await request<AvailableServiceAttr[]>(
    `${BASE_API_URL}api/v1/${STORE}/${storeId}/services/?${AVALIABLE_STORES_COORDS(lat, lng)}`
  );
  return response.data;
};

const getPickUpStores = async (): Promise<PickUpStore[]> => {
  const response = await request<PickUpStore[]>(`${BASE_API_URL}api/v1/${PICK_UP_STORE}`);
  return response.data;
};

const getPickUpStoreServices = async (storeId?: number): Promise<AvailableServiceAttr> => {
  const response = await request<AvailableServiceAttr>(`${BASE_API_URL}api/v1/${SERVICE}${storeId}`);
  return response.data;
};

export { getAvaliableStores, getStoreServices, getPickUpStores, getPickUpStoreServices };
