export interface CountryITem {
  country: string;
  code: string;
  iso: string;
}

export const countryList: CountryITem[] = [
  { country: "España", code: "34", iso: "ES" },
  { country: "Afganistán", code: "93", iso: "AF" },
  { country: "Albania", code: "355", iso: "AL" },
  { country: "Alemania", code: "49", iso: "DE" },
  { country: "Andorra", code: "376", iso: "AD" },
  { country: "Angola", code: "244", iso: "AO" },
  { country: "Anguila", code: "1264", iso: "AI" },
  { country: "Antártida", code: "672", iso: "AQ" },
  { country: "Antigua y Barbuda", code: "1268", iso: "AG" },
  { country: "Arabia Saudí", code: "966", iso: "SA" },
  { country: "Argelia", code: "213", iso: "DZ" },
  { country: "Argentina", code: "54", iso: "AR" },
  { country: "Armenia", code: "374", iso: "AM" },
  { country: "Aruba", code: "297", iso: "AW" },
  { country: "Australia", code: "61", iso: "AU" },
  { country: "Austria", code: "43", iso: "AT" },
  { country: "Azerbaiyán", code: "994", iso: "AZ" },
  { country: "Bahamas", code: "1242", iso: "BS" },
  { country: "Bahréin", code: "973", iso: "BH" },
  { country: "Bangladesh", code: "880", iso: "BD" },
  { country: "Barbados", code: "1246", iso: "BB" },
  { country: "Belarús", code: "375", iso: "BY" },
  { country: "Bélgica", code: "32", iso: "BE" },
  { country: "Belice", code: "501", iso: "BZ" },
  { country: "Benín", code: "229", iso: "BJ" },
  { country: "Bermudas", code: "1441", iso: "BM" },
  { country: "Bolivia", code: "591", iso: "BO" },
  { country: "Bosnia y Herzegovina", code: "387", iso: "BA" },
  { country: "Botsuana", code: "267", iso: "BW" },
  { country: "Brasil", code: "55", iso: "BR" },
  { country: "Brunei", code: "673", iso: "BN" },
  { country: "Bulgaria", code: "359", iso: "BG" },
  { country: "Burkina Faso", code: "226", iso: "BF" },
  { country: "Burundi", code: "257", iso: "BI" },
  { country: "Bután", code: "975", iso: "BT" },
  { country: "Cabo Verde", code: "238", iso: "CV" },
  { country: "Camboya", code: "855", iso: "KH" },
  { country: "Camerún", code: "237", iso: "CM" },
  { country: "Canadá", code: "1", iso: "CA" },
  { country: "Chad", code: "235", iso: "TD" },
  { country: "Chile", code: "56", iso: "CL" },
  { country: "China", code: "86", iso: "CN" },
  { country: "Chipre", code: "357", iso: "CY" },
  { country: "Colombia", code: "57", iso: "CO" },
  { country: "Comoras", code: "269", iso: "KM" },
  { country: "Corea del Norte", code: "850", iso: "KP" },
  { country: "Corea del Sur", code: "82", iso: "KR" },
  { country: "Costa de Marfil", code: "225", iso: "CI" },
  { country: "Costa Rica", code: "506", iso: "CR" },
  { country: "Croacia", code: "385", iso: "HR" },
  { country: "Cuba", code: "53", iso: "CU" },
  { country: "Curaçao", code: "599", iso: "CW" },
  { country: "Dinamarca", code: "45", iso: "DK" },
  { country: "Dominica", code: "1767", iso: "DM" },
  { country: "Ecuador", code: "593", iso: "EC" },
  { country: "Egipto", code: "20", iso: "EG" },
  { country: "El Salvador", code: "503", iso: "SV" },
  { country: "Emiratos Árabes Unidos", code: "971", iso: "AE" },
  { country: "Eritrea", code: "291", iso: "ER" },
  { country: "Eslovaquia", code: "421", iso: "SK" },
  { country: "Eslovenia", code: "386", iso: "SI" },
  { country: "Estados Unidos", code: "1", iso: "US" },
  { country: "Estonia", code: "372", iso: "EE" },
  { country: "Etiopía", code: "251", iso: "ET" },
  { country: "Filipinas", code: "63", iso: "PH" },
  { country: "Finlandia", code: "358", iso: "FI" },
  { country: "Fiyi", code: "679", iso: "FJ" },
  { country: "Francia", code: "33", iso: "FR" },
  { country: "Gabón", code: "241", iso: "GA" },
  { country: "Gambia", code: "220", iso: "GM" },
  { country: "Georgia", code: "995", iso: "GE" },
  { country: "Ghana", code: "233", iso: "GH" },
  { country: "Gibraltar", code: "350", iso: "GI" },
  { country: "Granada", code: "1473", iso: "GD" },
  { country: "Grecia", code: "30", iso: "GR" },
  { country: "Groenlandia", code: "299", iso: "GL" },
  { country: "Guam", code: "1671", iso: "GU" },
  { country: "Guatemala", code: "502", iso: "GT" },
  { country: "Guernesey", code: "44", iso: "GG" },
  { country: "Guinea Ecuatorial", code: "240", iso: "GQ" },
  { country: "Guinea-Bissau", code: "245", iso: "GW" },
  { country: "Guinea", code: "224", iso: "GN" },
  { country: "Guyana", code: "592", iso: "GY" },
  { country: "Haití", code: "509", iso: "HT" },
  { country: "Honduras", code: "504", iso: "HN" },
  { country: "Hong Kong", code: "852", iso: "HK" },
  { country: "Hungría", code: "36", iso: "HU" },
  { country: "India", code: "91", iso: "IN" },
  { country: "Indonesia", code: "62", iso: "ID" },
  { country: "Irán", code: "98", iso: "IR" },
  { country: "Iraq", code: "964", iso: "IQ" },
  { country: "Irlanda", code: "353", iso: "IE" },
  { country: "Isla de Man", code: "44", iso: "IM" },
  { country: "Isla de Navidad", code: "61", iso: "CX" },
  { country: "Islandia", code: "354", iso: "IS" },
  { country: "Islas Caimán", code: "1345", iso: "KY" },
  { country: "Islas Cocos", code: "61", iso: "CC" },
  { country: "Islas Cook", code: "682", iso: "CK" },
  { country: "Islas Feroe", code: "298", iso: "FO" },
  { country: "Islas Malvinas", code: "500", iso: "FK" },
  { country: "Islas Marianas del Norte", code: "1", iso: "MP" },
  { country: "Islas Marshall", code: "692", iso: "MH" },
  { country: "Islas Salomón", code: "677", iso: "SB" },
  { country: "Islas Turcas y Caicos", code: "1649", iso: "TC" },
  { country: "Islas Vírgenes Americanas", code: "1340", iso: "VI" },
  { country: "Islas Vírgenes Británicas", code: "1284", iso: "VG" },
  { country: "Israel", code: "972", iso: "IL" },
  { country: "Italia", code: "39", iso: "IT" },
  { country: "Jamaica", code: "1", iso: "JM" },
  { country: "Japón", code: "81", iso: "JP" },
  { country: "Jersey", code: "44", iso: "JE" },
  { country: "Jordania", code: "962", iso: "JO" },
  { country: "Kazajstán", code: "7", iso: "KZ" },
  { country: "Kenia", code: "254", iso: "KE" },
  { country: "Kirguistán", code: "996", iso: "KG" },
  { country: "Kiribati", code: "686", iso: "KI" },
  { country: "Kosovo", code: "383", iso: "XK" },
  { country: "Kuwait", code: "965", iso: "KW" },
  { country: "Laos", code: "856", iso: "LA" },
  { country: "Lesotho", code: "266", iso: "LS" },
  { country: "Letonia", code: "371", iso: "LV" },
  { country: "Líbano", code: "961", iso: "LB" },
  { country: "Liberia", code: "231", iso: "LR" },
  { country: "Libia", code: "218", iso: "LY" },
  { country: "Liechtenstein", code: "423", iso: "LI" },
  { country: "Lituania", code: "370", iso: "LT" },
  { country: "Luxemburgo", code: "352", iso: "LU" },
  { country: "Macao", code: "853", iso: "MO" },
  { country: "Macedonia", code: "389", iso: "MK" },
  { country: "Madagascar", code: "261", iso: "MG" },
  { country: "Malasia", code: "60", iso: "MY" },
  { country: "Malawi", code: "265", iso: "MW" },
  { country: "Maldivas", code: "960", iso: "MV" },
  { country: "Mali", code: "223", iso: "ML" },
  { country: "Malta", code: "356", iso: "MT" },
  { country: "Marruecos", code: "212", iso: "MA" },
  { country: "Mauricio", code: "230", iso: "MU" },
  { country: "Mauritania", code: "222", iso: "MR" },
  { country: "Mayotte", code: "262", iso: "YT" },
  { country: "México", code: "52", iso: "MX" },
  { country: "Micronesia", code: "691", iso: "FM" },
  { country: "Moldavia", code: "373", iso: "MD" },
  { country: "Mónaco", code: "377", iso: "MC" },
  { country: "Mongolia", code: "976", iso: "MN" },
  { country: "Montenegro", code: "382", iso: "ME" },
  { country: "Montserrat", code: "1664", iso: "MS" },
  { country: "Mozambique", code: "258", iso: "MZ" },
  { country: "Myanmar", code: "95", iso: "MM" },
  { country: "Namibia", code: "264", iso: "NA" },
  { country: "Nauru", code: "674", iso: "NR" },
  { country: "Nepal", code: "977", iso: "NP" },
  { country: "Nicaragua", code: "505", iso: "NI" },
  { country: "Níger", code: "227", iso: "NE" },
  { country: "Nigeria", code: "234", iso: "NG" },
  { country: "Niue", code: "683", iso: "NU" },
  { country: "Noruega", code: "47", iso: "NO" },
  { country: "Nueva Caledonia", code: "687", iso: "NC" },
  { country: "Nueva Zelanda", code: "64", iso: "NZ" },
  { country: "Omán", code: "968", iso: "OM" },
  { country: "Países Bajos", code: "31", iso: "NL" },
  { country: "Pakistán", code: "92", iso: "PK" },
  { country: "Palau", code: "680", iso: "PW" },
  { country: "Palestina", code: "970", iso: "PS" },
  { country: "Panamá", code: "507", iso: "PA" },
  { country: "Papúa Nueva Guinea", code: "675", iso: "PG" },
  { country: "Paraguay", code: "595", iso: "PY" },
  { country: "Perú", code: "51", iso: "PE" },
  { country: "Pitcairn", code: "64", iso: "PN" },
  { country: "Polinesia Francesa", code: "689", iso: "PF" },
  { country: "Polonia", code: "48", iso: "PL" },
  { country: "Portugal", code: "351", iso: "PT" },
  { country: "Puerto Rico", code: "1787", iso: "PR" },
  { country: "Puerto Rico", code: "1939", iso: "PR" },
  { country: "Qatar", code: "974", iso: "QA" },
  { country: "Reino Unido", code: "44", iso: "GB" },
  { country: "República Centroafricana", code: "236", iso: "CF" },
  { country: "República Checa", code: "420", iso: "CZ" },
  { country: "República del Congo", code: "242", iso: "CG" },
  { country: "República Democrática del Congo", code: "243", iso: "CD" },
  { country: "República Dominicana", code: "1809", iso: "DO" },
  { country: "República Dominicana", code: "1829", iso: "DO" },
  { country: "República Dominicana", code: "1849", iso: "DO" },
  { country: "Reunión", code: "262", iso: "RE" },
  { country: "Ruanda", code: "250", iso: "RW" },
  { country: "Rumanía", code: "40", iso: "RO" },
  { country: "Rusia", code: "7", iso: "RU" },
  { country: "Sáhara Occidental", code: "212", iso: "EH" },
  { country: "Samoa Americana", code: "1684", iso: "AS" },
  { country: "Samoa", code: "685", iso: "WS" },
  { country: "San Bartolomé", code: "590", iso: "BL" },
  { country: "San Cristóbal y Nieves", code: "1869", iso: "KN" },
  { country: "San Marino", code: "378", iso: "SM" },
  { country: "Sint Maarten", code: "1", iso: "SX" },
  { country: "Saint-Martin", code: "590", iso: "MF" },
  { country: "San Pedro y Miquelón", code: "508", iso: "PM" },
  { country: "San Vicente y las Granadinas", code: "1784", iso: "VC" },
  { country: "Santa Elena", code: "290", iso: "SH" },
  { country: "Santa Lucía", code: "1758", iso: "LC" },
  { country: "Santo Tomé y Príncipe", code: "239", iso: "ST" },
  { country: "Senegal", code: "221", iso: "SN" },
  { country: "Serbia", code: "381", iso: "RS" },
  { country: "Seychelles", code: "248", iso: "SC" },
  { country: "Sierra Leona", code: "232", iso: "SL" },
  { country: "Singapur", code: "65", iso: "SG" },
  { country: "Siria", code: "963", iso: "SY" },
  { country: "Somalia", code: "252", iso: "SO" },
  { country: "Sri Lanka", code: "94", iso: "LK" },
  { country: "Suazilandia", code: "268", iso: "SZ" },
  { country: "Sudáfrica", code: "27", iso: "ZA" },
  { country: "Sudán del Sur", code: "211", iso: "SS" },
  { country: "Sudán", code: "249", iso: "SD" },
  { country: "Suecia", code: "46", iso: "SE" },
  { country: "Suiza", code: "41", iso: "CH" },
  { country: "Surinam", code: "597", iso: "SR" },
  { country: "Svalbard y Jan Mayen", code: "47", iso: "SJ" },
  { country: "Tailandia", code: "66", iso: "TH" },
  { country: "Taiwan", code: "886", iso: "TW" },
  { country: "Tanzania", code: "255", iso: "TZ" },
  { country: "Tayikistán", code: "992", iso: "TJ" },
  { country: "Territorio Británico del Océano Índico", code: "246", iso: "IO" },
  { country: "Timor Oriental", code: "670", iso: "TL" },
  { country: "Togo", code: "228", iso: "TG" },
  { country: "Tokelau", code: "690", iso: "TK" },
  { country: "Tonga", code: "676", iso: "TO" },
  { country: "Trinidad y Tobago", code: "1", iso: "TT" },
  { country: "Túnez", code: "216", iso: "TN" },
  { country: "Turkmenistán", code: "90", iso: "TR" },
  { country: "Turkmenistán", code: "993", iso: "TM" },
  { country: "Tuvalu", code: "688", iso: "TV" },
  { country: "Ucrania", code: "380", iso: "UA" },
  { country: "Uganda", code: "256", iso: "UG" },
  { country: "Uruguay", code: "598", iso: "UY" },
  { country: "Uzbekistán", code: "998", iso: "UZ" },
  { country: "Vanuatu", code: "678", iso: "VU" },
  { country: "Vaticano", code: "379", iso: "VA" },
  { country: "Venezuela", code: "58", iso: "VE" },
  { country: "Vietnam", code: "84", iso: "VN" },
  { country: "Wallis y Futuna", code: "681", iso: "WF" },
  { country: "Yemen", code: "967", iso: "YE" },
  { country: "Yibuti", code: "253", iso: "DJ" },
  { country: "Zambia", code: "260", iso: "ZM" },
  { country: "Zimbabue", code: "263", iso: "ZW" },
];
