import IconShoppingCart from "@iconsV2/Illustration/ShoppingCartOrange.svg?react";
import Loader from "@/components/Icons/V2/Loader/loader.svg?react";

import tw, { styled } from "twin.macro";

const baseStyles = {
  content: {
    base: tw`flex flex-col p-s gap-s`,
  },
};

export const StyledIconShoppingCart = styled(IconShoppingCart)`
  ${tw`w-[2.75rem] h-[2.31rem]`}
`;

export const StyledContent = styled.div`
  ${baseStyles.content.base}
  ${tw`bg-neutral-99`}
`;

export const StyledContentHeading = styled.div`
  ${tw`w-full flex gap-sm items-center`}
`;

export const StyledContentAllProducts = styled.div`
  ${baseStyles.content.base}
  ${tw`bg-neutral-99 overflow-y-auto max-h-[489px]`}
`;

export const StyledContentProductsColumn = styled.div`
  ${tw`flex flex-col w-full gap-s`}
`;

export const StyledContentProductsRow = styled.div`
  ${tw`grid grid-cols-2 w-full gap-s all-child:w-full`}

  & >a {
    ${tw`w-full`}
  }
`;

export const StyledContentBottom = styled.div`
  ${baseStyles.content.base}
  ${tw`bg-secondary-100`}
`;

export const StyledLoaderLoading = styled(Loader)`
  ${tw`animate-spin w-14 h-14`}
`;

export const StyledWrapperLoader = styled.div`
  ${tw`absolute top-0 left-0 w-full h-full bg-white bg-opacity-90 flex items-center justify-center z-10`}
`;
