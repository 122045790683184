import { type RouteObject } from "react-router-dom";
import routesNewProfile from "./routesNewProfile";

const privateRoutes: RouteObject[] = [
  {
    path: "profile",
    lazy: async () => {
      const { default: ProfileLayout } = await import("@/components/Layouts/ProfileLayout");
      return {
        element: <ProfileLayout />,
      };
    },
    children: routesNewProfile,
  },

  // {
  //   path: "profile/plus",
  //   lazy: async () => {
  //     const { ProfileMembership } = await import("@/screens/Profile/ProfileMembership");
  //     return {
  //       element: <ProfileMembership />,
  //     };
  //   },
  // },
  {
    path: "referral",
    lazy: async () => {
      const { Referral } = await import("@/screens/Referral");
      return {
        element: <Referral />,
      };
    },
  },
  // {
  //   path: "payment-method/validate",
  //   lazy: async () => {
  //     const { PaymentMethodValidate } = await import("@/screens/PaymentMethodValidate");
  //     return {
  //       element: <PaymentMethodValidate />,
  //     };
  //   },
  // },
  {
    path: "referred",
    lazy: async () => {
      const { Referred } = await import("@/screens/Referred");
      return {
        element: <Referred />,
      };
    },
  },
  {
    path: "orders-old",
    lazy: async () => {
      const { Orders } = await import("@/screens/Orders");
      return {
        element: <Orders />,
      };
    },
  },
  {
    path: "orders-old/:orderId",
    lazy: async () => {
      const { Order } = await import("@/screens/Order");
      return {
        element: <Order />,
      };
    },
  },
];

export default privateRoutes;
