import Paragraph from "@/components/Atoms/Paragraph";
import Popover, { PopoverContent, PopoverTrigger } from "@/components/Atoms/Popover";
import PopoverButton from "@/components/Atoms/PopoverButton";
import { GET_DOCUMENT_TYPE } from "@/graphql/user";
import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import tw, { css } from "twin.macro";
import Input from "@/components/Molecules/Input";
import { StyledIconChevronDown } from "@/components/Molecules/PhoneInput/styles";
import { type TSize } from "@/types/common";

interface IdentificationType {
  value: string;
  label: string;
}

interface SelectDocumentTypeProps {
  onOptionSelect: (option: IdentificationType | undefined) => void;
  selectedOption?: string;
  errors: boolean;
  textHelp: string;
  size?: TSize;
}

const SelectDocumentType = ({
  onOptionSelect,
  selectedOption,
  errors,
  textHelp,
  size,
}: SelectDocumentTypeProps) => {
  const { data: documentTypesData, loading } = useQuery(GET_DOCUMENT_TYPE);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<IdentificationType[]>([]);

  useEffect(() => {
    if (!loading && documentTypesData?.identificationTypes) {
      setOptions(documentTypesData.identificationTypes as IdentificationType[]);
    }
  }, [documentTypesData, loading]);

  const selectedOptionLabel = options.find((option) => option.value === selectedOption)?.label;

  return (
    <Popover
      open={open}
      onOpenChange={() => setOpen(!open)}
      placement="bottom-start"
      shift={false}
      flip={false}
      withReferenceWidth
    >
      <PopoverTrigger tw="w-full">
        <div onClick={() => setOpen(!open)}>
          <Input
            errors={errors}
            textHelp={textHelp}
            label="Tipo de documento"
            placeholder="Selecciona un tipo de documento *"
            value={selectedOptionLabel ?? ""}
            name="document_type"
            type="text"
            readOnly
            tw="w-full"
            endIcon={<StyledIconChevronDown $open={open} />}
            size={size}
          />
        </div>
      </PopoverTrigger>
      <PopoverContent
        classes={{
          root: css`
            ${tw`z-[52] h-80 overflow-y-auto flex flex-col gap-s`}
          `,
        }}
      >
        {options.length > 0 ? (
          options.map((item, index) => (
            <PopoverButton
              key={index}
              onClick={() => {
                onOptionSelect(item);
                setOpen(false);
              }}
            >
              {item.label}
            </PopoverButton>
          ))
        ) : (
          <Paragraph sizeVariant="sm">No options available</Paragraph>
        )}
      </PopoverContent>
    </Popover>
  );
};

export default SelectDocumentType;
