import { createRoot } from "react-dom/client";
import posthog from "posthog-js";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import dayjsTimezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import es from "dayjs/locale/es";

import { App } from "./App";
import { initializeSentry } from "./recourses/sentry";
import { EventBusHandler } from "./utils/Eventbus";
import { POSTHOG_API_KEY } from "./recourses/constants";

dayjs.extend(utc);
dayjs.extend(dayjsTimezone);
dayjs.extend(relativeTime);
dayjs.tz.setDefault("America/Bogota");
dayjs.locale(es);

/* PostHog initialization */
if (POSTHOG_API_KEY) {
  posthog.init(POSTHOG_API_KEY, {
    api_host: "https://app.posthog.com",
    autocapture: false,
    disable_session_recording: false,
  });
}

initializeSentry();

document.addEventListener("DOMContentLoaded", () => {
  setTimeout(() => {
    EventBusHandler.emit("app:ready");
  }, 1000);
});

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);
