import { StyledHambuguerButton, StyledHambuguerLine } from "./styles";

const HamburguerButton = ({ open, handleOpen }: { open: boolean; handleOpen: (value) => void }) => {
  return (
    <StyledHambuguerButton className="group" onClick={() => handleOpen(!open)}>
      <StyledHambuguerLine $open={open} />
      <StyledHambuguerLine $open={open} />
      <StyledHambuguerLine $open={open} />
    </StyledHambuguerButton>
  );
};

export default HamburguerButton;
