import "twin.macro";
import { type PropsWithChildren, forwardRef, useEffect } from "react";
import { createPortal } from "react-dom";

import { StyledWrapperBackDrop, StyledContent } from "./styles";
import type { TwStyle } from "twin.macro";
import type { RuleSet } from "styled-components";

type IBackDropProps = PropsWithChildren<{
  onClose?: () => void;
  isAdjust?: boolean;
  classes?: {
    root?: RuleSet<object> | TwStyle;
    backDrop?: RuleSet<object> | TwStyle;
  };
  showCenter?: boolean;
  preventScroll?: boolean;
  [key: string]: any;
}>;

const BackDrop = forwardRef<HTMLDivElement, IBackDropProps>(
  ({ children, onClose, isAdjust, classes, showCenter = true, preventScroll = false, ...props }, ref) => {
    const handleCloseWithEscape = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        onClose?.();
      }
    };

    useEffect(() => {
      if (onClose) {
        document.addEventListener("keydown", handleCloseWithEscape);

        return () => {
          document.removeEventListener("keydown", handleCloseWithEscape);
        };
      }
    }, [onClose]);

    useEffect(() => {
      if (preventScroll) {
        document.body.style.overflow = "hidden";

        return () => {
          document.body.style.overflow = "auto";
        };
      }
    }, [preventScroll]);

    return createPortal(
      <StyledWrapperBackDrop
        $isAdjust={isAdjust}
        onClick={(e) => {
          e.preventDefault();
          onClose?.();
        }}
        ref={ref}
        $classess={classes?.root}
        $showCenter={showCenter}
        {...props}
      >
        <StyledContent $classess={classes?.backDrop} onClick={(e) => e.stopPropagation()}>
          {children}
        </StyledContent>
      </StyledWrapperBackDrop>,
      document.body
    );
  }
);

export default BackDrop;
