import { type RouteObject } from "react-router-dom";

const newPrivateRoutes: RouteObject[] = [
  {
    path: "orders",
    lazy: async () => {
      const { default: Orders } = await import("@/Pages/Orders");
      return {
        element: <Orders />,
      };
    },
  },
  {
    path: "orders/:orderId",
    lazy: async () => {
      const { default: Order } = await import("@/Pages/Order");
      return {
        element: <Order />,
      };
    },
  },
];

export default newPrivateRoutes;
