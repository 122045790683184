import type { ITypographyStyledCommon } from "@/types/common";
import tw, { css, styled, type TwStyle } from "twin.macro";
import type { CSSProperties, RuleSet } from "styled-components";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import ButtonText from "../ButtonText";

interface IButtonStyled extends Omit<ITypographyStyledCommon, "$sizeVariant"> {
  disabled?: boolean;
  $fullWidth?: boolean;
  $rounded?: boolean;
  $justify?: CSSProperties["justifyContent"];
  $active?: boolean;
  $classes?: RuleSet<object> | TwStyle;
  [key: string]: any;
}

const buttonStyles = {
  base: tw`inline-flex items-center cursor-pointer
           transition-all ease-in-out duration-300 gap-2`,
  sizes: {
    button: {
      small: tw`py-xxs px-s gap-xxs`,
      medium: tw`py-xs px-sm gap-s`,
      large: tw`py-s px-m gap-m`,
    },
    icon: {
      small: tw`w-4 h-4`,
      medium: tw`w-5 h-5`,
      large: tw`w-6 h-6`,
    },
  },

  colors: {
    primary: {
      default: {
        default: tw`text-primaryV2-40 hover:(bg-primaryV2-90)`,
        active: tw`bg-primaryV2-90`,
        disabled: tw`text-neutral-80`,
      },
    },
    secondary: {
      default: {
        default: tw`text-secondary-40 hover:(bg-secondary-90)`,
        active: tw`bg-secondary-90`,
        disabled: tw`text-neutral-80`,
      },
    },
    neutral: {
      default: {
        default: tw`text-neutral-10 hover:(text-neutral-0 bg-secondary-99)`,
        active: tw`bg-secondary-99`,
        disabled: tw`text-neutral-80`,
      },
    },
  },
  fullWidth: tw`w-full`,
  disabled: tw`cursor-not-allowed`,
  rounded: tw`rounded-round`,
};

const iconStyles = {
  base: tw`fill-current`,
  path: tw`fill-current`,
};

export const StyledStartIcon = styled.span<Pick<IButtonStyled, "$size">>`
  & > svg {
    ${iconStyles.base}
    & > path {
      ${iconStyles.path}
    }

    ${({ $size }) =>
      applyResponsiveStyles({
        stylesBreakpoints: buttonStyles.sizes.icon,
        size: $size,
      })}
  }
`;

export const StyledWrapperContent = styled.div<Pick<IButtonStyled, "$justify">>`
  ${tw`flex w-full`}

  ${({ $justify }) => {
    return css`
      justify-content: ${$justify};
    `;
  }}
`;

export const StyledEndIcon = styled.span<Pick<IButtonStyled, "$size">>`
  & > svg {
    ${iconStyles.base}
    & > path {
      ${iconStyles.path}
    }

    ${({ $size }) =>
      applyResponsiveStyles({
        stylesBreakpoints: buttonStyles.sizes.icon,
        size: $size,
      })}
  }
`;

export const StyledButtonText = styled(ButtonText)`
  ${tw`text-current transition-none`}
`;

export const StyledWrapperBtnCategory = styled.button<IButtonStyled>`
  ${buttonStyles.base}

  ${({ $fullWidth }) => $fullWidth && buttonStyles.fullWidth}

  ${({ disabled }) => disabled && buttonStyles.disabled}

  ${({ $rounded }) => $rounded && buttonStyles.rounded}

  ${({ $active, $color }) => {
    if ($active) {
      return buttonStyles.colors[$color ?? "primary"].default.active;
    }
  }}

  ${({ $color, disabled }) =>
    buttonStyles.colors[$color ?? "primary"].default[disabled ? "disabled" : "default"]}


  ${({ $size }) =>
    applyResponsiveStyles({
      stylesBreakpoints: buttonStyles.sizes.button,
      size: $size,
    })}

  ${({ $classes }) => $classes && $classes}
`;
