import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

export interface User {
  id: number;
  publicId: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  creditAvaliable: UserPriceType;
  referralCreditAvailable: UserPriceType | null;
  birthday: string | null;
  gender: "male" | "female" | "other" | null;
  hearAboutUs: string | null;
  identification: string | null;
  identificationType: string | null;
  membership_savings: PriceType;
  membership: Membership | null;
}

export interface UserPriceType {
  gross: string;
  grossLocalized: string;
  net: string;
  netLocalized: string;
  currency: string;
}

export const userSlice = createSlice({
  name: "user",
  initialState: null as User | null,
  reducers: {
    setUser: (_state: User | null, action: PayloadAction<User>): User => ({
      ...action.payload,
    }),
    emptyUser: () => null,
  },
});

export const userAction = userSlice.actions;
