import Card from "@/components/Atoms/Card";
import tw from "twin.macro";
import RadioButton from "@/components/Atoms/Radiobutton";
import ProductCardTag from "@/components/Atoms/ProductCardTag";

interface AddressSelectionCardProps {
  listStores: CityType[] | undefined;
  location: LocationType;
  setSelectAddress: (city: CityType) => void;
  isDefaultSelected?: boolean;
  onSelectNoAvailable: (city: CityType) => void;
}
const AddressSelectionCard = ({
  listStores,
  location,
  setSelectAddress,
  isDefaultSelected,
  onSelectNoAvailable,
}: AddressSelectionCardProps): JSX.Element => {
  return (
    <Card
      withShadow={false}
      classes={{
        root: tw`border border-neutral-90`,
      }}
    >
      <div>
        {listStores?.map((city: CityType, index: number) => {
          const isSelected = location?.city?.toLowerCase() === city.code.toLowerCase();

          return (
            <div
              tw="flex items-center border-b last:border-none cursor-pointer"
              onClick={() => {
                // If is available change address
                if (city.is_available) {
                  setSelectAddress(city);
                } else {
                  // If is not available set city name in feedback form
                  onSelectNoAvailable(city);
                }
              }}
              key={index}
            >
              <RadioButton
                name="city"
                color="primary"
                id="city-option"
                label={city.name}
                // size="large"
                classes={{
                  root: tw`p-2 w-full pointer-events-auto`,
                }}
                checked={!isDefaultSelected ? isSelected : undefined}
                disabled={!city.is_available}
              />
              {city.is_new && <ProductCardTag type="new" />}
            </div>
          );
        })}
      </div>
    </Card>
  );
};
export default AddressSelectionCard;
