import { type UseFormMethods } from "react-hook-form";
import {
  StyledActionsWrapper,
  StyledBodyInfoModal,
  StyledNotificationBox,
  StyledPrimaryActionsWrapper,
  StyledWrapperContent,
  StyledWrapperRight,
} from "../AuthStepsModal/styles";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import IconAuth from "@/components/Atoms/IconAuth";
import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";
import {
  StyledTips,
  StyledIconPassed,
  StyledIconUnpassed,
  StyledTipsBody,
  StyledTipsWrapper,
} from "./styles";
import TogglePasswordInput from "@/components/Atoms/TogglePasswordInput";
import Button from "@/components/Atoms/Button";
import { useState } from "react";
import Notification from "@/components/Atoms/Notification";
import StepOrBackHeader from "@/components/Atoms/StepOrBackHeader/inde";
import { type AuthStep } from "@/components/Navbar/AuthModal/types";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface PasswordUpdateProps {
  controller: Pick<UseFormMethods, "register">;
  email: string;
  loading: boolean;
  onClose: () => void;
  fetchingError: string[] | string;
  cleanErrors: () => void;
  isMobile: boolean;
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
}

const PasswordUpdate = ({
  controller: { register },
  loading,
  onClose,
  fetchingError,
  cleanErrors,
  isMobile,
  setStep,
}: PasswordUpdateProps) => {
  const [passwordMeetsRequirements, setPasswordMeetsRequirements] = useState({
    length: true,
    specialCharacter: true,
  });
  const isLarge = useMediaQuery("(min-width: {large})");
  const [hasInteracted, setHasInteracted] = useState(false);

  function handleChange(event) {
    cleanErrors();
    const value = event.target.value;

    setPasswordMeetsRequirements({
      length: value.length >= 8,
      specialCharacter: isPasswordComplex(String(value)),
    });

    if (!hasInteracted) {
      setHasInteracted(true);
    }
  }

  function isPasswordComplex(password: string) {
    // Check that there is at least one number, one letter, and one special character
    const regex = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[@*#%$]).+$/;
    return regex.test(password);
  }
  const isButtonEnabled =
    hasInteracted && passwordMeetsRequirements.length && passwordMeetsRequirements.specialCharacter;
  return (
    <StyledWrapperRight $hMax={true}>
      <StyledWrapperContent>
        {!isMobile && <IconClose tw="h-ml w-ml justify-self-end cursor-pointer" onClick={onClose} />}
        {isMobile && <StepOrBackHeader onBack={() => setStep("login")} />}
        <IconAuth type="exit" />
        <StyledBodyInfoModal>
          <Heading type="h2">Reestablece tu contraseña</Heading>
          <Paragraph sizeVariant="md">
            Ten en cuenta los siguientes tips al momento de crear tu contraseña
          </Paragraph>
        </StyledBodyInfoModal>
        <StyledTipsWrapper
          isPassed={passwordMeetsRequirements.length && passwordMeetsRequirements.specialCharacter}
        >
          <StyledTipsBody>
            <StyledTips>
              {passwordMeetsRequirements.specialCharacter ? <StyledIconPassed /> : <StyledIconUnpassed />}
              <Paragraph sizeVariant="sm">
                Incluye números, letras y caracteres especiales como *,@,#,%,$
              </Paragraph>
            </StyledTips>

            <StyledTips>
              {passwordMeetsRequirements.length ? <StyledIconPassed /> : <StyledIconUnpassed />}
              <Paragraph sizeVariant="sm">Supera los 8 caracteres en tu contraseña</Paragraph>
            </StyledTips>
          </StyledTipsBody>
        </StyledTipsWrapper>
        <TogglePasswordInput
          name="newPassword"
          size={isLarge ? "large" : "medium"}
          label="Nueva contraseña"
          placeHolder="Ingresa tu nueva contraseña"
          onChange={handleChange}
          ref={register({
            required: "Necesitamos tu contraseña",
            minLength: {
              value: 8,
              message: "Tu contraseña debe tener al menos 8 caracteres",
            },
          })}
        />
      </StyledWrapperContent>

      <StyledActionsWrapper>
        <StyledPrimaryActionsWrapper>
          <Button
            color="secondary"
            sizeVariant="lg"
            variant="filled"
            type="submit"
            disabled={!isButtonEnabled && !loading}
            fullWidth
          >
            Cambiar contraseña
          </Button>
        </StyledPrimaryActionsWrapper>

        <StyledNotificationBox visible={!!fetchingError && fetchingError?.length > 0}>
          <Notification type="error" messages={fetchingError} hFull />
        </StyledNotificationBox>
      </StyledActionsWrapper>
    </StyledWrapperRight>
  );
};

export default PasswordUpdate;
