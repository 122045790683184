import React, { type ButtonHTMLAttributes, type RefObject } from "react";
import tw, { css, theme } from "twin.macro";

type ButtonProps = {
  isPrimary?: boolean;
  isSecondary?: boolean;
  isSmall?: boolean;
  isMini?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  buttonRef?: RefObject<HTMLButtonElement>;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "disabled">;

/**
 * @example
 * // Always add a default width if you are using the isLoading prop.
 * <Button tw="w-20" isLoading/>
 * <Button css={css`width: 7rem;`} isLoading/>
 */
export const Button = ({
  isPrimary = true,
  isSecondary = false,
  isSmall = false,
  isMini = false,
  isDisabled = false,
  isLoading = false,
  buttonRef,
  children,
  ...buttonProps
}: ButtonProps): React.ReactElement => (
  <button
    {...buttonProps}
    ref={buttonRef}
    disabled={isDisabled || isLoading}
    css={css`
      ${[
        tw`font-bold text-base focus:outline-none h-12 px-6 rounded-md`,
        isPrimary && !isMini && tw`bg-primary-200 text-white border-0`,
        isSecondary && !isMini && tw`bg-transparent text-primary-200 border-primary-200 border-2`,
        isSmall && tw`text-sm h-8 px-3`,
        isMini &&
          tw`flex justify-center items-center shadow-md bg-white text-primary-200 font-normal h-6 text-xs text-center `,
        isPrimary && isDisabled && tw`cursor-not-allowed bg-gray-300 bg-opacity-50`,
        isSecondary &&
          isDisabled &&
          tw`cursor-not-allowed border-gray-300 border-opacity-50 text-gray-300 bg-opacity-50`,
        isLoading && tw`cursor-not-allowed`,
      ]}
    `}
  >
    {isLoading ? (
      <div
        css={css`
          & {
            border-top-color: ${isSecondary ? theme`colors.transparent` : theme`colors.primary.200`};
            animation: spinner 1.5s linear infinite;
          }
          @keyframes spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
          ${[
            tw`mx-auto rounded-full border-4 border-t-4 border-white h-8 w-8`,
            isSecondary && tw`border-primary-200`,
            isSmall && tw`h-5 w-5 border-2 border-t-2`,
          ]}
        `}
      />
    ) : (
      children
    )}
  </button>
);
