import tw, { styled } from "twin.macro";
import { FloatingArrow } from "@floating-ui/react";

export const StyledWrapperArrowSvg = styled(FloatingArrow)`
  ${tw`filter drop-shadow-lg`}
  & > path {
    ${tw`fill-white`}
  }
`;

export const StyledWrapperTrigger = styled.div`
  ${tw`w-fit`}
`;
