import tw, { styled } from "twin.macro";
import type { AddDollarToKeys } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import type { IProductCardGallerySkeleton } from ".";

// MD is equal to LG
const styles = {
  card: {
    sm: {
      vertical: {
        small: tw`w-[7rem] min-h-[10rem]`,
        medium: tw`w-[8rem] min-h-[11rem] rounded-md`,
        large: tw`w-[8.5rem] min-h-[13rem] rounded-md`,
      },
      horizontal: {
        small: tw`w-[10.125rem] min-h-[6rem]`,
        medium: tw`w-[11.125rem] min-h-[6rem] rounded-md`,
        large: tw`w-[11.125rem] min-h-[8rem] rounded-md`,
      },
    },
    md: {
      vertical: {
        small: tw`w-[8.75rem] min-h-[18.75rem]`,
        medium: tw`w-[10.625rem] min-h-[21.5rem] rounded-md`,
        large: tw`w-[12.5rem] min-h-[25.0625rem] rounded-md`,
      },
      horizontal: {
        small: tw`h-[8.75rem]`,
        medium: tw`h-[10.625rem] rounded-md`,
        large: tw`h-[12.5rem] rounded-md`,
      },
    },
    lg: {
      vertical: {
        small: tw`w-[8.75rem] min-h-[18.75rem]`,
        medium: tw`w-[10.625rem] min-h-[21.5rem] rounded-md`,
        large: tw`w-[12.5rem] min-h-[25.0625rem] rounded-md`,
      },
      horizontal: {
        small: tw`h-[8.75rem]`,
        medium: tw`h-[10.625rem] rounded-md`,
        large: tw`h-[12.5rem] rounded-md`,
      },
    },
  },
  figure: {
    sm: {
      vertical: {
        small: tw`w-[2.5rem] h-[2.5rem] p-1.5`,
        medium: tw`w-[2.5rem] h-[2.5rem] p-1.5`,
        large: tw`w-[3.5rem] h-[3.5rem] p-1.5`,
      },
      horizontal: {
        small: tw`w-[2.5rem] h-[2.5rem] p-1.5`,
        medium: tw`w-[2.5rem] h-[2.5rem] p-1.5`,
        large: tw`w-[3.5rem] h-[3.5rem] p-1.5`,
      },
    },
    md: {
      vertical: {
        small: tw`w-[8.75rem] h-[8.75rem] p-1.5`,
        medium: tw`w-[10.625rem] h-[10.625rem] p-0`,
        large: tw`w-[12.5rem] h-[12.5rem] p-0`,
      },
      horizontal: {
        small: tw`w-[8.75rem] h-[8.75rem] p-1.5`,
        medium: tw`w-[10.625rem] h-[10.625rem] p-0`,
        large: tw`w-[12.5rem] h-[12.5rem] p-0`,
      },
    },
    lg: {
      vertical: {
        small: tw`w-[8.75rem] h-[8.75rem] p-1.5`,
        medium: tw`w-[10.625rem] h-[10.625rem] p-0`,
        large: tw`w-[12.5rem] h-[12.5rem] p-0`,
      },
      horizontal: {
        small: tw`w-[8.75rem] h-[8.75rem] p-1.5`,
        medium: tw`w-[10.625rem] h-[10.625rem] p-0`,
        large: tw`w-[12.5rem] h-[12.5rem] p-0`,
      },
    },
    rounded: {
      medium: tw`rounded-none`,
      large: tw`rounded-none`,
    },
  },
  content: {
    sm: tw`p-1.5`,
    md: tw`p-4`,
    lg: tw`p-4`,
  },
  text: {
    sm: { small: tw`h-3`, medium: tw`h-4`, large: tw`h-5` },
    md: { small: tw`h-4`, medium: tw`h-5`, large: tw`h-6` },
    lg: { small: tw`h-4`, medium: tw`h-5`, large: tw`h-6` },
  },
  button: {
    sm: { small: tw`h-5`, medium: tw`h-5`, large: tw`h-5` },
    md: { small: tw`h-5`, medium: tw`h-7`, large: tw`h-9` },
    lg: { small: tw`h-5`, medium: tw`h-7`, large: tw`h-9` },
  },
};

export const StyledFigure = styled.figure`
  ${tw`rounded-lg w-full h-full bg-primaryV2-80`}
`;

export const StyledWrapperFigure = styled.div``;

export const StyledWrapperContent = styled.div`
  ${tw`flex flex-col items-start justify-between self-stretch`}
`;

export const StyledWrapperContentText = styled.div`
  ${tw`flex flex-col items-start justify-between self-stretch max-w-[10rem] gap-4`}
`;

export const StyledWrapperTitle = styled.div`
  ${tw`bg-primaryV2-70 w-[55%]`}
`;

export const StyledWrapperDescription = styled.div`
  ${tw`bg-primaryV2-70 w-full`}
`;

export const StyledWrapperButton = styled.div`
  ${tw`bg-cta-shopping-default rounded-round h-5 w-full`}
`;

export const StyledWrapperCard = styled.div<AddDollarToKeys<Omit<IProductCardGallerySkeleton, "key">>>`
  ${tw`grid border animate-pulse1 rounded-lg overflow-hidden`}

  ${({ $orientation }) =>
    $orientation === "vertical" ? tw`grid-rows-[min-content_1fr]` : tw`grid-cols-[min-content_1fr]`}


   ${({ $size, $orientation, $sizeVariant }) =>
    applyResponsiveStyles({
      stylesBreakpoints: styles.card[$sizeVariant][$orientation === "vertical" ? "vertical" : "horizontal"],
      size: $size,
    })}

  & ${StyledWrapperFigure} {
    ${({ $size, $orientation, $sizeVariant }) =>
      applyResponsiveStyles({
        stylesBreakpoints:
          styles.figure[$sizeVariant][$orientation === "vertical" ? "vertical" : "horizontal"],
        size: $size,
      })}
  }

  & ${StyledFigure} {
    ${({ $size, $sizeVariant }) =>
      $sizeVariant !== "sm" &&
      applyResponsiveStyles({
        stylesBreakpoints: styles.figure.rounded,
        size: $size,
      })}
  }

  & ${StyledWrapperContent} {
    ${({ $sizeVariant }) => styles.content[$sizeVariant]}
  }

  & ${StyledWrapperTitle}, & ${StyledWrapperDescription} {
    ${({ $size, $sizeVariant }) =>
      applyResponsiveStyles({
        stylesBreakpoints: styles.text[$sizeVariant],
        size: $size,
      })}
  }

  & ${StyledWrapperButton} {
    ${({ $size, $sizeVariant }) =>
      applyResponsiveStyles({
        stylesBreakpoints: styles.button[$sizeVariant],
        size: $size,
      })}
  }
`;
