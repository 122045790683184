import {
  forwardRef,
  type CSSProperties,
  type ButtonHTMLAttributes,
  type FC,
  type PropsWithRef,
  type ReactNode,
} from "react";

import type { ITypographyCommon, TColor } from "@/types/common";

import { StyledEndIcon, StyledStartIcon, StyledWrapperButton, StyledWrapperChildren } from "./styles";

export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement>, ITypographyCommon {
  variant?: "filled" | "outlined" | "text";
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  justify?: CSSProperties["justifyContent"];
  color?: TColor;
  fontWeight?: CSSProperties["fontWeight"];
  [key: string]: any;
}

const Button: FC<PropsWithRef<IButton>> = forwardRef(
  (
    {
      children,
      size,
      sizeVariant,
      startIcon,
      endIcon,
      color = "primary",
      variant = "filled",
      fullWidth = false,
      justify = "center",
      fontWeight,
      ...props
    },
    ref
  ) => {
    if (!sizeVariant) {
      throw new TypeError("sizeVariant is required");
    }

    return (
      <StyledWrapperButton
        ref={ref}
        {...props}
        $sizeVariant={sizeVariant}
        $size={size}
        $color={color}
        $variant={variant}
        $fullWidth={fullWidth}
        $justify={justify}
      >
        {startIcon && (
          <StyledStartIcon $size={size} $sizeVariant={sizeVariant}>
            {startIcon}
          </StyledStartIcon>
        )}

        {children && (
          <StyledWrapperChildren sizeVariant={sizeVariant} color={color} fontWeight={fontWeight} size={size}>
            {children}
          </StyledWrapperChildren>
        )}

        {endIcon && (
          <StyledEndIcon $size={size} $sizeVariant={sizeVariant}>
            {endIcon}
          </StyledEndIcon>
        )}
      </StyledWrapperButton>
    );
  }
);

export default Button;
