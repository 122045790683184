import { Suspense, useEffect, useState } from "react";
import type { TOrientation, TSize } from "@/types/common";
import IconOtra from "@iconsV2/Illustration/otra.svg?react";
import useAppSelector from "@/hooks/useAppSelector";
import InfoPopup from "@/components/Molecules/InfoPopup";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import ButtonText from "../ButtonText";
import Caption from "../Caption";
import { StyledWrapperCiity, StyledWrapperText } from "./styles";
import { getCorrectCityName } from "@/utils/CorrectCityNames";
import ModalAddressForm from "@/components/Organisms/ModalAddressForm";

export interface CiityProps {
  size?: TSize;
  open: boolean;
  handleChangeOpen: (open: boolean) => void;
  withPopover?: boolean;
  textDirection?: TOrientation;
  fullWidth?: boolean;
  [key: string]: any;
}

const Ciity = ({
  size,
  open,
  handleChangeOpen,
  withPopover = true,
  textDirection,
  fullWidth,
  ...props
}: CiityProps) => {
  const isMedium = useMediaQuery("(min-width: {medium})");
  const location = useAppSelector((state) => state.cart.location);
  const showAddressReminder = useAppSelector((state) => state.popUpsReminder.showAddressReminder);

  const [showPopUps, setshowPopUps] = useState(false);

  const closePopUps = () => {
    if (!showPopUps) return;
    setshowPopUps(false);
  };

  useEffect(() => {
    if (location?.test && showAddressReminder) {
      setshowPopUps(true);
    } else {
      if (showPopUps) {
        setshowPopUps(false);
      }
    }
  }, [location?.test, showAddressReminder]);

  return (
    <>
      <Suspense fallback={""}>
        {open && <ModalAddressForm isOpen={open} toggleAddressModal={handleChangeOpen} />}
      </Suspense>

      <InfoPopup
        minWidth={isMedium ? "14.9rem" : "12.3413rem"}
        title="¿Te encuentras aquí?"
        description={
          isMedium ? "Elige tu ubicación actual para mostrarte los productos disponibles." : undefined
        }
        popoverProps={{
          open: showPopUps && withPopover,
          withReferenceWidth: true,
          arrow: true,
          placement: "bottom-start",
        }}
        onClick={closePopUps}
      >
        <StyledWrapperCiity
          onClick={() => {
            handleChangeOpen(true);
            closePopUps();
          }}
          $size={size}
          $fullWidth={fullWidth}
          {...props}
        >
          <IconOtra />
          <StyledWrapperText $size={size} $textDirection={textDirection}>
            <Caption>Estás {isMedium && "ubicado"} en</Caption>
            <ButtonText sizeVariant={isMedium ? "sm" : "md"} size={size} tw="capitalize">
              {getCorrectCityName(location?.city ? location?.city : location?.address)}
            </ButtonText>
          </StyledWrapperText>
        </StyledWrapperCiity>
      </InfoPopup>
    </>
  );
};

export default Ciity;
