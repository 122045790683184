import { useEffect, useId, useRef } from "react";
import Swiper from "swiper";
import { theme } from "twin.macro";
import HubCard from "@/components/Atoms/HubCard";
import { type IHub } from "@/types/hubs";
import HubLoader from "../HubLoader";
import { A11y, Navigation } from "swiper/modules";
import SwiperNavButtons from "@components/Molecules/SwiperNavButtons";
import {
  StyledButtonNextContainerHubs,
  StyledButtonPrevContainerHubs,
  StyledHubsSkeletonWrapper,
  StyledWrapperHubs,
  StyledWrapperHubsRoot,
} from "./styles";

interface IHubProps {
  hubs: IHub[];
  isLoading: boolean;
}

const HubsSection = ({ isLoading, hubs }: IHubProps): React.ReactElement => {
  const id = useId();
  const swiperRef = useRef<Swiper | null>(null);
  const swiperClassName = `swiper-hubs-container-${id.replace(/:/g, "")}`;
  const swiperNavigationClassName = `swiper-navigation-${id.replace(/:/g, "")}`;

  useEffect(() => {
    if (hubs) {
      swiperRef.current = new Swiper(`.${swiperClassName}`, {
        modules: [A11y, Navigation],
        grabCursor: true,
        slidesPerView: "auto",

        spaceBetween: 6,

        navigation: {
          nextEl: `.${swiperNavigationClassName}-next`,
          prevEl: `.${swiperNavigationClassName}-prev`,
          hiddenClass: "swiper-navigation-hidden",
          disabledClass: "swiper-navigation-disabled",
        },
      });
    }

    return () => {
      swiperRef.current?.destroy();
    };
  }, [JSON.stringify(hubs)]);

  return (
    <StyledWrapperHubsRoot className="hubsSection">
      <StyledButtonNextContainerHubs>
        <SwiperNavButtons
          isCircular
          type="next"
          buttonProps={{
            className: `${swiperNavigationClassName}-next swipperNavButtonHubs`,
          }}
        />
      </StyledButtonNextContainerHubs>

      <StyledButtonPrevContainerHubs>
        <SwiperNavButtons
          isCircular
          type="prev"
          buttonProps={{
            className: `${swiperNavigationClassName}-prev swipperNavButtonHubs`,
          }}
        />
      </StyledButtonPrevContainerHubs>
      <div className={`swiper ${swiperClassName}`} tw="px-s medium:px-0 max-w-full">
        <StyledWrapperHubs className="swiper-wrapper">
          {!isLoading ? (
            <>
              {hubs.map((hub) => (
                <HubCard hub={hub} key={hub.pk} />
              ))}
            </>
          ) : (
            <StyledHubsSkeletonWrapper>
              {Array.from({ length: 9 }).map((_, i) => (
                <HubLoader className="swiper-slide" key={i} />
              ))}
            </StyledHubsSkeletonWrapper>
          )}
        </StyledWrapperHubs>
      </div>
    </StyledWrapperHubsRoot>
  );
};

export default HubsSection;
