import { type TOrientation, type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import { type CSSProperties } from "styled-components";
import tw, { css, styled } from "twin.macro";

interface ResponsiveStyles {
  $size?: TSize;
  $orientation: TOrientation;
}

interface RootContainerProps {
  $size?: TSize;
  $hScreen: boolean;
  $withHeader?: boolean;
}

interface WrapperMessagesProps {
  $textAlign?: CSSProperties["textAlign"];
}
const styles = {
  styledRootContainer: {
    base: tw`gap-ml grid grid-rows-[max-content] grid-cols-[100%]`,
  },
  wrapperError: {
    base: tw`flex flex-wrap  gap-ml items-center h-max self-center justify-self-center justify-center`,
    orientation: {
      horizontal: {
        base: tw`flex-row`,
        size: {
          small: tw`flex-col max-w-[90%]  `,
          medium: tw`flex-row max-w-max`,
          large: tw`flex-row max-w-max`,
        },
      },
      vertical: {
        base: tw`flex-col`,
        size: {
          small: tw`max-w-[90%]`,
          medium: tw`flex-col`,
          large: tw`flex-col`,
        },
      },
    },
  },
  wrapperMessages: {
    base: tw`max-w-[27.625rem] grid gap-m `,
  },
};

export const StyledRootContainer = styled.div<RootContainerProps>`
  ${({ $withHeader }) => ($withHeader ? styles.styledRootContainer.base : tw`grid grid-rows-1`)}

  ${({ $hScreen }) => $hScreen && tw`h-screen`}


  ${({ $withHeader }) =>
    !$withHeader &&
    `
  button {
    max-height: 2.25rem;
    border: none;
  }

  h3, p {
    margin: 0;
  }
`}
`;
export const WrapperError = styled.div<ResponsiveStyles>`
  ${styles.wrapperError.base}

  ${({ $orientation }) => $orientation && styles.wrapperError.orientation[$orientation].base};

  ${({ $size, $orientation }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.wrapperError.orientation[$orientation].size,
    })};
`;

export const WrapperMessages = styled.section<WrapperMessagesProps>`
  ${styles.wrapperMessages.base}

  ${({ $textAlign }) => {
    return css`
      text-align: ${$textAlign};
    `;
  }}
`;
