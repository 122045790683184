import "twin.macro";
import {
  type FC,
  forwardRef,
  type PropsWithRef,
  type PropsWithChildren,
  type ReactNode,
  type CSSProperties,
} from "react";
import { type TwStyle } from "twin.macro";
import { type RuleSet } from "styled-components";
import Paragraph from "@atoms/Paragraph";
import type { THeading, TSizeVariant } from "@/types/common";
import Heading from "../Heading";
import { StyledWrapperDetailAmount } from "./styles";
import Label from "@components/Atoms/Label";

export type IDetailAmount = PropsWithChildren<{
  sizeVariant: TSizeVariant;
  title: string | ReactNode;
  description?: string | ReactNode;
  amount: string | number | ReactNode;
  withIVA?: boolean;
  color?: CSSProperties["color"];
  type?: THeading;
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  [key: string]: any;
}>;

const DetailAmount: FC<PropsWithRef<IDetailAmount>> = forwardRef<HTMLDivElement, IDetailAmount>(
  ({ classes, title, description, amount, withIVA, sizeVariant, color, type = "h5", ...props }, ref) => {
    return (
      <StyledWrapperDetailAmount ref={ref} $classes={classes?.root} {...props}>
        <div tw="flex items-center justify-between w-full">
          {sizeVariant === "lg" ? (
            <Heading color={color} type={type} tw="flex items-center gap-s">
              {title}
            </Heading>
          ) : (
            <Label color={color} sizeVariant={sizeVariant} tw="font-semibold items-center flex gap-s">
              {title}
            </Label>
          )}

          {sizeVariant === "lg" ? (
            <Heading color={color} type={type} tw="flex items-center gap-s">
              {amount}
            </Heading>
          ) : (
            <Label color={color} sizeVariant={sizeVariant} tw="flex items-center gap-s">
              {amount}
            </Label>
          )}
        </div>

        <div tw="flex items-center justify-between w-full">
          <Paragraph color={color} sizeVariant="sm">
            {description}
          </Paragraph>
          {withIVA && <Paragraph sizeVariant="sm">IVA incluído</Paragraph>}
        </div>
      </StyledWrapperDetailAmount>
    );
  }
);

export default DetailAmount;
