import tw, { styled } from "twin.macro";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";

interface StyledHearAboutUsCardRootProps {
  $withRadius?: boolean;
}
export const StyledHearAboutUsCardRoot = styled.div<StyledHearAboutUsCardRootProps>`
  ${tw`p-m flex items-center gap-m bg-cta-secondary-default`}
  ${({ $withRadius }) => $withRadius && tw`rounded-lg`}
`;

export const StyledHearAboutUsCardContent = styled.div`
  ${tw`w-full flex flex-col gap-s`}
`;

export const StyledHearAboutUsCardRow = styled.div`
  ${tw`flex items-center gap-m justify-between`}
`;

export const StyledIconClose = styled(IconClose)`
  ${tw`invert cursor-pointer w-[1.5rem] h-[1.5rem]`}
`;

export const StyledUnderlinedText = styled.span`
  ${tw`text-inherit font-bold underline underline-offset-1 cursor-pointer`}
`;
