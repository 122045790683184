import axios, { type AxiosRequestConfig, type AxiosResponse } from "axios";

/**
 * Requests a URL, returning a promise.
 */
export const request = async <T>(
  url: string,
  config: {
    isUpload?: boolean;
  } & Pick<AxiosRequestConfig, "method" | "data" | "headers" | "params" | "timeout" | "baseURL"> = {
    method: "GET",
    data: {},
    headers: {},
    isUpload: false,
    // jwt,
    params: {},
    timeout: 15000,
    baseURL: import.meta.env.REACT_APP_BASE_API_URL!,
  }
): Promise<AxiosResponse<T>> => {
  const JWToken = localStorage.getItem("userToken");

  const request = {
    url,
    baseURL: config.baseURL,
    method: config.method,
    params: config.params,
    timeout: config.timeout,
    headers: {
      Accept: "application/json",
      Authorization: JWToken ? `Bearer ${JSON.parse("" + JWToken)}` : null,
      "Content-Type": "application/json",
      "Accept-Language": "es",
      ...config.headers,
    },
    data: !config.isUpload ? JSON.stringify(config.data) : config.data,
  };

  if (config.isUpload) {
    delete request.headers["Content-Type"];
  }

  if (config.method === "GET") {
    delete request.data;
  }

  if (config.method === "POST") {
    delete request.params;
  }

  return await (axios(request) as Promise<AxiosResponse<T>>);
};
