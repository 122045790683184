import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import tw, { styled } from "twin.macro";

const styles = {
  size: {
    small: tw`text-base`,
    medium: tw`text-xl`,
    large: tw`text-2xl`,
  },
};

interface StyledFlagEmojiProps {
  $size?: TSize;
}
export const StyledFlagEmoji = styled.div<StyledFlagEmojiProps>`
  ${tw`flex items-center justify-center`}

  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.size,
    })}
`;
