import { genericsAction } from "@/store/generics";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import { type ModalHearAboutUsProps } from "@/components/Organisms/ModalHearAboutUs";

const useModalHearAboutUs = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector((state) => state.generics.modalHearAboutUs.openModalHearAboutUs);
  const type = useAppSelector((state) => state.generics.modalHearAboutUs.type);

  const handleCloseModalHearAboutUs = () => {
    dispatch(genericsAction.setOpenModalHearAboutUs({ open: false }));
  };

  const handleOpenModalHearAboutUs = (type: ModalHearAboutUsProps["type"]) => {
    dispatch(genericsAction.setOpenModalHearAboutUs({ open: true, type }));
  };

  return {
    openModalHearAboutUs: isOpen,
    handleCloseModalHearAboutUs,
    handleOpenModalHearAboutUs,
    type,
  };
};

export default useModalHearAboutUs;
