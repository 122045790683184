import ProductCardGalllerySearch from "@/components/Molecules/ProductCardGalllerySearch";
import { StyledContent, StyledContentProductsColumn } from "./styles";
import { graphqlToProductGallery, recombeeToOldCart, recombeeToProductGallery } from "@/utils/product";
import Paragraph from "@/components/Atoms/Paragraph";
import ProductCardGallerySearchSkeleton from "@/components/Atoms/ProductCardGallerySearchSkeleton";
import { useQuery } from "react-query";
import posthog from "posthog-js";
import { RecommendItemsToItem } from "recombee-js-api-client";
import { recombeeClient } from "@/recourses/recombee";
import { type IProductGalleryData } from "@/types/product";
import { type RecombeeValuesItemUser } from "@/types/recombee";
import Heading from "@/components/Atoms/Heading";

interface QueryReturnType {
  items: IProductGalleryData[];
  oldItems: Cart[];
  id: string;
}

const RecomendedOneProduct = ({
  product,
  storeId,
  items: itemsCheckout,
}: {
  product: Cart;
  storeId: number;
  items: Cart[];
}) => {
  const userID = posthog.get_distinct_id();

  const { data, error, isFetching } = useQuery<QueryReturnType>({
    queryKey: ["itemOutOfStock", userID, storeId, product.pk],
    queryFn: async () => {
      const skus = itemsCheckout.map((item) => item.details.sku);
      let filter = `'store_id' == "${storeId}" AND 'product_availability_available_online'`;
      if (skus.length > 0) {
        const skuFilter = skus.map((sku) => `'product_sku' != "${sku}"`).join(" OR ");
        filter += ` AND (${skuFilter})`;
      }

      const response = await recombeeClient.send(
        new RecommendItemsToItem(product.pk, userID, 3, {
          scenario: "item-out-of-stock",
          filter,
          returnProperties: true,
          cascadeCreate: true,
        })
      );

      const items: IProductGalleryData[] = [];
      const oldItems: Cart[] = [];

      response.recomms.forEach(({ id, values }) => {
        items.push(recombeeToProductGallery(Number(id), values as unknown as RecombeeValuesItemUser));
        oldItems.push(recombeeToOldCart(Number(id), values as unknown as RecombeeValuesItemUser));
      });

      return {
        items,
        oldItems,
        id: response.recommId,
      };
    },
    enabled: !!userID && !!storeId && !!product.pk,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  return (
    <StyledContent tw=" bg-secondary-100">
      <ProductCardGalllerySearch
        product={graphqlToProductGallery(product)}
        oldDataProduct={product}
        disabled
        showUnits
        showButtonCart={false}
      />

      {data && data?.items.length >= 1 && (
        <Paragraph sizeVariant="md" fontWeight={400}>
          Pero no te preocupes, tenemos estas recomendaciones para ti
        </Paragraph>
      )}

      <StyledContentProductsColumn>
        {(isFetching || !storeId || !userID || !product.pk) &&
          Array.from({ length: 3 }).map((_, index) => <ProductCardGallerySearchSkeleton key={index} />)}

        {error !== null && (
          <Paragraph sizeVariant="md">Ocurrió un error al cargar las recomendaciones</Paragraph>
        )}

        {data && data?.items?.length <= 0 && <Heading type="h6">No hay recomendaciones disponibles</Heading>}

        {data?.items.map((item, index) => (
          <ProductCardGalllerySearch
            key={index}
            product={item}
            oldDataProduct={data.oldItems[index]}
            recommId={data.id}
          />
        ))}
      </StyledContentProductsColumn>
    </StyledContent>
  );
};

export default RecomendedOneProduct;
