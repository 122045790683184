import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { createSelector } from "reselect";
import { AddCartProduct } from "@/recourses/recombee";

import { existProductCart } from "@utils/cartV2";
import debounce from "lodash/debounce";
import { cartStoresActions } from "@/store/cartStore/index.js";
import { type AppState } from "@/store";
import useAppSelector from "./useAppSelector";
import { checkProductHasStock } from "@/utils/product";
import { addToCart, removeFromCart } from "@/utils/analyticsV2";
import Analytics from "@/utils/Analytics";

const selectItems = (state: AppState) => state.cart.items;
const selectItemsWithoutDiscount = (state: AppState) =>
  state.cart.items.filter((item) => !item.availability.onSale);

const originalPrice = (item: Cart) => item?.availability?.priceUndiscounted?.gross ?? 0;
const discountedPrice = (item: Cart) => item?.availability?.price?.gross ?? 0;
const maxQtyToOrder = (item: Cart) => item?.availability?.maxQtyToOrder ?? 0;
const quantityOrMaxOrder = (item: Cart) =>
  item?.quantity > maxQtyToOrder(item) ? maxQtyToOrder(item) : item?.quantity;

const selectTotalCountCart = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => acc + item?.quantity, 0)
);

const selectSubTotal = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return acc + (quantityOrMaxOrder(item) ?? 0) * originalPrice(item);
  }, 0)
);

const selectDiscount = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return (
      acc +
      (item?.availability?.onSale ? originalPrice(item) - discountedPrice(item) : 0) *
        (quantityOrMaxOrder(item) ?? 1)
    );
  }, 0)
);

const selectTotal = createSelector(
  [selectSubTotal, selectDiscount],
  (subTotal, discount) => subTotal - discount
);

const selectItemsStock = createSelector([selectItems], (items) =>
  items.reduce((acc, item) => {
    return {
      ...acc,
      [item.details.sku]: checkProductHasStock(item),
    };
  }, {})
);

const selectTotalItemsWithoutDiscount = createSelector([selectItemsWithoutDiscount], (items) => {
  return items.reduce((acc, item) => {
    return acc + (quantityOrMaxOrder(item) ?? 0) * originalPrice(item);
  }, 0);
});

interface IUseCart {
  cupon?: {
    type: string;
    discountValue: number;
  };
}

const useCart = (props?: IUseCart) => {
  const { cupon } = props ?? {};

  const dispatch = useDispatch();
  const items = useAppSelector(selectItems);
  const totalCountCart = useAppSelector(selectTotalCountCart);
  const subTotal = useAppSelector(selectSubTotal);
  const discount = useAppSelector(selectDiscount);
  const total = useAppSelector(selectTotal);
  const itemsStock: Record<string, boolean> = useAppSelector(selectItemsStock);
  const storeId = useAppSelector((state) => state.cart?.storeId);
  const totalItemsWithoutDiscount = useAppSelector(selectTotalItemsWithoutDiscount);

  const itemWithDiscount = useMemo(() => {
    let discountValue = cupon?.discountValue ?? 0;
    if (cupon?.type === "percentage") {
      discountValue = totalItemsWithoutDiscount * (discountValue / 100);
    }

    return items.reduce<Cart[]>((acc, item) => {
      if (item.availability.onSale) {
        return [
          ...acc,
          {
            ...item,
            availability: {
              ...item.availability,
              cuponPrice: undefined,
              discount: {
                ...item.availability.discount,
                type: "promofit",
              },
            },
          },
        ];
      }

      const itemTotalPrice = discountedPrice(item) * quantityOrMaxOrder(item);

      const proportion = itemTotalPrice / totalItemsWithoutDiscount;

      // Calculate the discount amount for the item based on its proportion
      let itemDiscountAmount = discountValue * proportion;

      // Check if the discount exceeds the maximum discount per item (which is the item's total price)
      const maxDiscountPerItem = itemTotalPrice;

      itemDiscountAmount = Math.min(itemDiscountAmount, maxDiscountPerItem);

      // Apply the discount to the item's unit price
      const discountPerUnit = itemDiscountAmount / item.quantity;

      return [
        ...acc,
        {
          ...item,
          availability: {
            ...item.availability,
            cuponPrice: {
              gross: discountedPrice(item) - discountPerUnit,
              type: cupon?.type ?? "",
              discountValue,
              discountPerUnit,
            },
            discount: {
              ...item.availability.discount,
              type: "cupon",
            },
          },
        },
      ];
    }, []);
  }, [cupon, total, JSON.stringify(items), totalItemsWithoutDiscount]);

  const findItem = useCallback(
    (productId: number) => existProductCart(items, productId),
    [JSON.stringify(items)]
  );

  const handleAddMultipleCart = useCallback(
    (oldProducts: Cart[]) => {
      // const productsToAddCart: Cart[] = [];

      oldProducts.forEach((oldProduct) => {
        // void AddCartProduct(String(oldProduct.pk), {
        //   amount: 1,
        //   price: oldProduct.availability.price.gross,
        //   recommId: oldProduct?.recommId,
        // });

        // if (!findItem(Number(oldProduct.pk))) {
        //   if (storeId !== undefined) {
        //     Analytics.AddToCart({
        //       productsInfo: {
        //         availability: {
        //           availability: oldProduct.availability,
        //         },
        //         item: oldProduct,
        //         quantity: oldProduct.quantity,
        //         storeId: String(storeId),
        //       },
        //       cartProducts: items,
        //       recommId: oldProduct?.recommId,
        //     });
        //   }

        //   addToCart.eventProduct(oldProduct);
        //   productsToAddCart.push(oldProduct);
        //   return;
        // }

        // dispatch(
        //   cartStoresActions.changeQuantity({
        //     productId: Number(oldProduct.pk),
        //     quantity: 1,
        //   })
        // );

        handleAddCart(oldProduct);
      });

      // dispatch(cartStoresActions.addProducts(productsToAddCart));
    },
    [JSON.stringify(items), storeId]
  );

  const handleAddCart = useCallback(
    (oldProduct: Cart) => {
      void AddCartProduct(String(oldProduct.pk), {
        amount: 1,
        price: oldProduct.availability.price.gross,
        recommId: oldProduct?.recommId,
      });

      if (!findItem(Number(oldProduct.pk))) {
        if (storeId !== undefined) {
          Analytics.AddToCart({
            productsInfo: {
              availability: {
                availability: oldProduct.availability,
              },
              item: oldProduct,
              quantity: oldProduct.quantity,
              storeId: String(storeId),
            },
            cartProducts: items,
            recommId: oldProduct?.recommId,
          });
        }

        addToCart.eventProduct(oldProduct);
        dispatch(cartStoresActions.addProduct(oldProduct));
        return;
      }

      dispatch(
        cartStoresActions.changeQuantity({
          productId: Number(oldProduct.pk),
          quantity: 1,
        })
      );
    },
    [JSON.stringify(items), storeId]
  );

  const handleIncrement = useCallback(
    (productId: number) => {
      const item = findItem(productId);
      const quantity = item?.quantity ?? 0;

      if (item) {
        dispatch(cartStoresActions.changeQuantity({ productId, quantity: quantity + 1 }));
        void AddCartProduct(String(item.pk), {
          amount: quantity + 1,
          price: item?.availability?.price?.gross,
          recommId: item?.recommId,
        });
      }
    },
    [JSON.stringify(items)]
  );

  const handleDecrement = useCallback(
    (productId: number, callBack?: () => void) => {
      const item = existProductCart(items, productId);
      const quantity = item?.quantity ?? 0;

      if (item) {
        if (quantity > 1) {
          dispatch(cartStoresActions.changeQuantity({ productId, quantity: quantity - 1 }));
          void AddCartProduct(String(item.pk), {
            amount: quantity - 1,
            price: item?.availability?.price?.gross,
            recommId: item?.recommId,
          });
          return;
        }
        if (quantity === 1) {
          handleRemove(productId);
          callBack?.();
        }
      }
    },
    [JSON.stringify(items)]
  );

  const productsOutOfStock = useMemo(() => {
    return items.filter((item) => !item.availability || !item.availability.available);
  }, [items]);

  const handleRemove = useCallback(
    (productId: number, callBack?: () => void) => {
      dispatch(cartStoresActions.removeProduct(productId));
      const product = items.find((item) => String(item.pk) === String(productId));
      if (product) {
        removeFromCart.eventProduct(product);
      }
      callBack?.();
    },
    [JSON.stringify(items)]
  );

  const handleRemoves = useCallback((productIds?: string[], callBack?: () => void) => {
    if (!productIds || productIds.length === 0) {
      productIds = productsOutOfStock.map((product) => String(product.pk));
    }
    dispatch(cartStoresActions.removeProducts(productIds));
    callBack?.();
  }, []);

  const handleChangeInput = useCallback(
    debounce((productId: number, quantity: string) => {
      const numQuantity = Number(quantity);
      if (!isNaN(numQuantity) && numQuantity >= 0) {
        const item = existProductCart(items, productId);
        dispatch(cartStoresActions.changeQuantity({ productId, quantity: numQuantity }));
        void AddCartProduct(String(item?.pk), {
          amount: numQuantity,
          price: item?.availability?.price?.gross,
          recommId: item?.recommId,
        });
      }
    }, 50),
    []
  );

  const handleCleanCart = useCallback(() => {
    dispatch(cartStoresActions.emptyCart({}));
  }, [dispatch]);

  return {
    // states:
    items,
    total,
    subTotal,
    discount,
    totalCountCart,
    itemsStock,
    totalItemsWithoutDiscount,
    itemWithDiscount,

    // functions:
    handleAddCart,
    handleAddMultipleCart,
    handleRemove,
    handleIncrement,
    handleDecrement,
    handleChangeInput,
    handleCleanCart,
    handleRemoves,
  };
};

export default useCart;
