import { type Libraries } from "@react-google-maps/api";

export const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;

export const API_URL = `${BASE_API_URL}api`;

export const API_URL_V1 = `${API_URL}/v1`;
export const API_URL_V2 = `${API_URL}/v2`;

export const VITAMIN_URL = process.env.REACT_APP_VITAMIN_URL;

export const IS_MAINTENANCE = process.env.REACT_APP_MAINTENANCE === "true";

export const IS_ACTIVE_PLUS = process.env.REACT_APP_IS_PLUS_ACTIVE === "true";

export const ENVIRONMENT: "local" | "staging" | "production" =
  process.env.REACT_APP_ENVIRONMENT ?? ("local" as any);

export const IS_PROD = ENVIRONMENT === "production";

export const COUNTRYCODE = `${process.env.REACT_APP_COUNTRY}`;

export const SITEKEYCAPTCHA = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

// organization
export const ORGANIZATION_TITLE = "Fithub | Supermercado Saludable";
export const ORGANIZATION_NAME = "Fithub";
export const ORGANIZATION_URL = "https://fithub.com.co";
export const ORGANIZATION_LOGO = "https://fithub-media.s3.amazonaws.com/logo-1080.png";
export const ORGANIZATION_PHONE = "+57-300-912-2487";
export const ORGANIZATION_EMAIL = "soporte@fithub.com.co";
export const ORGANIZATION_DESCRIPTION =
  "Evoluciona tus hábitos saludables con fithub, aquí encontraras una gran variedad de productos que te ayudaran a llevar una alimentación más saludable.";

export const KEYWORDS = [
  "supermercado saludable",
  "alimentación saludable",
  "productos orgánicos",
  "comida fitness",
  "alimentos naturales",
  "dieta balanceada",
  "nutrición deportiva",
  "suplementos alimenticios",
  "snacks saludables",
  "proteínas vegetales",
  "alimentos sin gluten",
  "productos veganos",
  "superalimentos",
  "comida baja en calorías",
  "alimentos integrales",
  "productos sin azúcar añadido",
  "vitaminas y minerales",
  "alimentos funcionales",
  "compras saludables online",
  "estilo de vida saludable",
  "bajo en grasa saturada",
  "alto en proteína",
  "naturalmente libre de gluten",
  "bajo en sodio",
  "sin lactosa",
  "keto",
  "vegano",
  "sin azúcar añadida",
  "dieta cetogénica",
  "productos lácteos sin lactosa",
  "alimentos bajos en sodio",
  "alternativas veganas",
  "alimentos ricos en proteínas",
  "opciones sin gluten",
  "productos bajos en grasa",
];

// PostHog
export const POSTHOG_API_KEY: string | undefined = process.env.REACT_APP_POSTHOG_API_KEY;

// Sentry
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
export const SENTRY_ORG = process.env.REACT_APP_SENTRY_ORG;
export const SENTRY_PROJ_ID = process.env.REACT_APP_SENTRY_PROJ_ID;

// MeiliSearch
export const MEILISEARCH_HOST_URL: string = process.env.REACT_APP_MEILISEARCH_HOST_URL ?? "";
export const MEILISEARCH_SEARCH_API_KEY: string = process.env.REACT_APP_MEILISEARCH_SEARCH_API_KEY ?? "";
export const MEILISEARCH_STORE_PRODUCT_INDEX: string =
  process.env.REACT_APP_MEILISEARCH_STORE_PRODUCT_INDEX ?? "";

// Recombee
export const APP_RECOMBEE_DB: string = process.env.REACT_APP_RECOMBEE_DB ?? "";
export const APP_RECOMBEE_PUBLIC_KEY: string = process.env.REACT_APP_RECOMBEE_PUBLIC_KEY ?? "";

export const MIN_AVAILABLE_QUANTITY = 0;
export const MAX_UNIT_QUANTITY = 10;

export const COUNTRY_CODE = process.env.REACT_APP_COUNTRY;

export const REGISTER_PROMOTION = process.env.REACT_APP_REGISTER_PROMOTION;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY ?? "";
export const GOOGLE_MAP_LIBRARIES: Libraries = ["places"];

export const LYRA_PUBLIC_KEY = process.env.REACT_APP_LYRA_KEY ?? "";

export const CHRISTMAS_THEME = process.env.REACT_APP_CHRISTMAS_THEME === "true";
