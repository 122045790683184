import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import {
  StyledActionsWrapper,
  StyledBodyInfoModal,
  StyledNotificationBox,
  StyledPrimaryActionsWrapper,
  StyledWrapperContent,
  StyledWrapperFooterModal,
  StyledWrapperRight,
} from "../AuthStepsModal/styles";
import tw from "twin.macro";
import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";
import Input from "@/components/Molecules/Input";
import Button from "@/components/Atoms/Button";
import { type UseFormMethods } from "react-hook-form";
import { type AuthStep } from "@/components/Navbar/AuthModal/types";
import IconAuth from "@/components/Atoms/IconAuth";
import TogglePasswordInput from "@/components/Atoms/TogglePasswordInput";
import Notification from "@/components/Atoms/Notification";
import StepOrBackHeader from "@/components/Atoms/StepOrBackHeader/inde";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface LoginProps {
  onClose: () => void;
  controller: Pick<UseFormMethods, "register" | "errors" | "formState">;
  setView: React.Dispatch<React.SetStateAction<AuthStep>>;
  title?: string;
  loading: boolean;
  fetchingError: string[] | string;
  cleanErrors: () => void;
  isMobile?: boolean;
}
const Login = ({
  onClose,
  controller: { register, errors },
  setView,
  loading,
  fetchingError,
  cleanErrors,
  isMobile,
}: LoginProps) => {
  const handleChange = () => {
    cleanErrors();
  };
  const isLarge = useMediaQuery("(min-width: {large})");

  return (
    <>
      <StyledWrapperRight>
        <StyledWrapperContent>
          {!isMobile && <IconClose tw="h-ml w-ml justify-self-end cursor-pointer" onClick={onClose} />}
          {isMobile && <StepOrBackHeader onClose={onClose} />}
          <IconAuth type="user" />

          <StyledBodyInfoModal>
            <Heading type="h2">Hola</Heading>
            <Paragraph sizeVariant="md">
              Ingresa a tu cuenta y compra de manera rápida, fácil y segura los productos que evolucionan tus
              hábitos.
            </Paragraph>
          </StyledBodyInfoModal>
          <Input
            label="Correo electrónico"
            placeholder="Ingresa tu correo electrónico"
            name="email"
            type="email"
            ref={register({ required: "Necesitamos tu correo" })}
            errors={errors?.email?.message}
            onChange={handleChange}
            size={isLarge ? "large" : "medium"}
          />
          <TogglePasswordInput
            label="Contraseña"
            ref={register({ required: "Necesitamos tu contraseña" })}
            errors={errors?.password?.message}
            placeHolder="Ingresa tu contraseña"
            onChange={handleChange}
            size={isLarge ? "large" : "medium"}
          />
        </StyledWrapperContent>

        <StyledActionsWrapper>
          <StyledPrimaryActionsWrapper>
            <Button sizeVariant="lg" color="secondary" type="submit" disabled={loading} fullWidth>
              Iniciar sesión
            </Button>
            <Paragraph
              sizeVariant="sm"
              classes={tw`underline cursor-pointer self-center`}
              onClick={() => setView("phoneNumberPassword")}
            >
              ¿Olvidaste tu contraseña?
            </Paragraph>
          </StyledPrimaryActionsWrapper>
          <StyledNotificationBox visible={!!fetchingError && fetchingError?.length > 0}>
            <Notification type="error" messages={fetchingError} hFull />
          </StyledNotificationBox>

          <StyledWrapperFooterModal>
            <Paragraph sizeVariant="sm">¿No tienes cuenta con nosotros?</Paragraph>
            <Button
              sizeVariant="sm"
              variant="text"
              color="secondary"
              type="button"
              onClick={() => setView("phoneNumber")}
            >
              Crear cuenta
            </Button>
          </StyledWrapperFooterModal>
        </StyledActionsWrapper>
      </StyledWrapperRight>
    </>
  );
};
export default Login;
