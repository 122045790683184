import { type Scope } from "@sentry/react";

export default function errorBoundaryBeforeCapture(
  scope: Scope,
  error: unknown,
  componentStack: string | undefined
) {
  const retryErrorBoundary = window.localStorage.getItem("retryErrorBoundary");

  scope.setExtra("componentStack", componentStack);
  scope.setExtra("retryErrorBoundary", retryErrorBoundary);
  scope.setExtra("errorBoundaryBeforeCapture", true);
  scope.setExtra("error", error);

  if (Number(retryErrorBoundary) < 1) {
    window.localStorage.setItem("retryErrorBoundary", String(Number(retryErrorBoundary) + 1));
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  if (Number(retryErrorBoundary) === 1) {
    window.localStorage.setItem("retryErrorBoundary", "2");
    window.location.href = "/";
  }

  if (Number(retryErrorBoundary) === 2) {
    window.localStorage.removeItem("retryErrorBoundary");
  }

  return scope;
}
