import { gql } from "@apollo/client";

export const GET_HUBS = gql`
  query getHubs($storeId: String!) {
    hubs(storeId: $storeId) {
      edges {
        node {
          id
          pk
          slug
          name
          description
          image(size: "120x120", method: "thumbnail_webp") {
            url
            alt
          }
        }
      }
    }
  }
`;

export const GET_HUB = gql`
  query getHub($storeId: String!, $slug: String) {
    hub(storeId: $storeId, slug: $slug) {
      id
      pk
      slug
      name
      description
      image(size: "120x120", method: "thumbnail_webp") {
        url
        alt
      }
    }
  }
`;

export const GET_CATEGORIES_HUB_PRODUCTS = gql`
  query getCategoriesHubProducts(
    $storeId: String!
    $hubSlugs: [String]!
    $hubSlug: String!
    $first: Int
    $after: String
  ) {
    hub(storeId: $storeId, slug: $hubSlug) {
      id
      pk
      slug
      name
      description
      image(size: "120x120", method: "thumbnail_webp") {
        url
        alt
      }
    }
    categories(storeId: $storeId, level: 0, first: $first, after: $after) {
      edges {
        node {
          id
          name
          slug
          products(storeId: $storeId, first: 10, hubSlugs: $hubSlugs) {
            edges {
              node {
                isFeatured
                id
                pk
                customLabel
                customLabelBackgroundColor
                allowOrderOutOfStock
                availability {
                  pricePerMeasureUnitDisplay
                  isNew
                  onSale
                  available
                  maxQtyToOrder
                  priceUndiscounted {
                    currency
                    gross
                    grossLocalized
                    net
                    netLocalized
                  }
                  discount {
                    currency
                    gross
                    grossLocalized
                    net
                    netLocalized
                  }
                  price {
                    currency
                    gross
                    grossLocalized
                    net
                    netLocalized
                  }
                }
                details {
                  showNutritionalFacts
                  id
                  name
                  slug
                  avgRating
                  publicId
                  sku
                  containsAlcohol
                  brand {
                    id
                    name
                    slug
                  }
                  thumbnail(size: "255x255", method: "thumbnail_webp") {
                    alt
                    url
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`;
