import { type CSSProperties } from "react";
import tw, { css, styled, theme } from "twin.macro";
import type { ITypographyStyledCommon } from "@/types/common";
import ButtonText, { type IButtonText } from "../ButtonText";

export type TVariant = "filled" | "outlined" | "text";

interface IButtonStyled extends ITypographyStyledCommon {
  $variant?: TVariant;
  disabled?: boolean;
  $fullWidth?: boolean;
  justify?: CSSProperties["justifyContent"];
  $fontWeight?: CSSProperties["fontWeight"];
  [key: string]: any;
}

const buttonStyles = {
  base: tw`rounded-[6.25rem] inline-flex justify-center items-center cursor-pointer
           transition-all ease-in-out duration-300 gap-2`,
  sizes: {
    button: {
      small: {
        sm: tw`(p-xs gap-xxxs)`,
        md: tw`(p-s gap-xxs)`,
        lg: tw`(p-sm gap-xs)`,
      },
      medium: {
        sm: tw`(p-xs gap-xxxs)`,
        md: tw`(p-s gap-xxs)`,
        lg: tw`(p-sm gap-xs)`,
      },
      large: {
        sm: tw`(p-xs gap-xxs)`,
        md: tw`(p-s gap-xs)`,
        lg: tw`(p-sm gap-s)`,
      },
    },
    icon: {
      small: {
        sm: tw`(h-[0.625rem] w-[0.625rem])`,
        md: tw`(h-[0.75rem] w-[0.75rem])`,
        lg: tw`(h-[1.125rem] w-[1.125rem])`,
      },
      medium: {
        sm: tw`(h-[0.625rem] w-[0.625rem])`,
        md: tw`(h-[0.75rem] w-[0.75rem])`,
        lg: tw`(h-[1.25rem] w-[1.25rem])`,
      },
      large: {
        sm: tw`(h-[0.75rem] w-[0.75rem])`,
        md: tw`(h-[1rem] w-[1rem])`,
        lg: tw`(h-[1.5rem] w-[1.5rem])`,
      },
    },
  },

  colors: {
    primary: {
      filled: {
        default: tw`bg-cta-shopping-default text-tertiary-100 hover:(bg-cta-shopping-dark) active:(bg-cta-shopping-light)`,
        disabled: tw`bg-neutral-85 text-neutral-70 fill-neutral-70`,
      },
      outlined: {
        default: tw`bg-neutral-100 border border-primaryV2-40 text-primaryV2-40 hover:(bg-primaryV2-99 border-primaryV2-30 text-primaryV2-30) active:(border-primaryV2-50 bg-primaryV2-99 text-primaryV2-50)`,
        disabled: tw`border border-neutral-85 bg-neutral-99 text-neutral-85`,
      },
      text: {
        default: tw`text-primaryV2-40 hover:(bg-primaryV2-99 text-primaryV2-30) active:(bg-primaryV2-99 text-primaryV2-50)`,
        disabled: tw`bg-neutral-99 text-neutral-85`,
      },
    },
    secondary: {
      filled: {
        default: tw`bg-cta-secondary-default text-secondary-100 hover:(bg-cta-secondary-dark) active:(bg-cta-secondary-light)`,
        disabled: tw`bg-neutral-85 text-neutral-70 fill-neutral-70`,
      },
      outlined: {
        default: tw`bg-neutral-100 border border-secondary-50 text-secondary-50 hover:(bg-secondary-99 border-secondary-40 text-secondary-40) active:(border-secondary-60 bg-secondary-99 text-secondary-60)`,
        disabled: tw`border border-neutral-85 bg-neutral-99 text-neutral-85`,
      },
      text: {
        default: tw`text-secondary-50 hover:(bg-secondary-99 text-secondary-40) active:(bg-secondary-99 text-secondary-60)`,
        disabled: tw`bg-neutral-99 text-neutral-85`,
      },
    },
    neutral: {
      filled: {
        default: tw`bg-neutral-10 text-white hover:(bg-neutral-10) active:(bg-neutral-10)`,
        disabled: tw`bg-neutral-85 text-neutral-70 fill-neutral-70`,
      },
      outlined: {
        default: tw`border border-neutral-10 text-neutral-10 hover:(bg-neutral-99) active:(bg-neutral-99)`,
        disabled: tw`border border-neutral-85 bg-neutral-99 text-neutral-85`,
      },
      text: {
        default: tw`text-neutral-10 hover:(bg-neutral-99) active:(bg-neutral-99)`,
        disabled: tw`bg-neutral-99 text-neutral-85`,
      },
    },
  },
  fullWidth: tw`w-full`,
  disabled: tw`cursor-not-allowed`,
  aligBetween: tw`justify-between`,
};

const iconStyles = {
  base: tw`fill-current`,
  path: tw`fill-current`,
};

export const StyledWrapperChildren = styled(ButtonText)<IButtonText>`
  ${tw`text-current transition-none leading-[normal]`}
`;

export const StyledStartIcon = styled.span<Pick<IButtonStyled, "$size" | "$sizeVariant">>`
  & > svg {
    ${iconStyles.base}
    & > path {
      ${iconStyles.path}
    }

    ${({ $sizeVariant, $size }) => {
      if ($size) {
        return buttonStyles.sizes.icon[$size][$sizeVariant];
      }

      return css`
        ${buttonStyles.sizes.icon.small[$sizeVariant]}

        @media (min-width: ${theme`screens.medium`}) {
          ${buttonStyles.sizes.icon.medium[$sizeVariant]}
        }

        @media (min-width: ${theme`screens.large`}) {
          ${buttonStyles.sizes.icon.large[$sizeVariant]}
        }
      `;
    }}
  }
`;

export const StyledEndIcon = styled.span<Pick<IButtonStyled, "$size" | "$sizeVariant">>`
  & > svg {
    ${iconStyles.base}
    & > path {
      ${iconStyles.path}
    }

    ${({ $sizeVariant, $size }) => {
      if ($size) {
        return buttonStyles.sizes.icon[$size][$sizeVariant];
      }

      return css`
        ${buttonStyles.sizes.icon.small[$sizeVariant]}

        @media (min-width: ${theme`screens.medium`}) {
          ${buttonStyles.sizes.icon.medium[$sizeVariant]}
        }

        @media (min-width: ${theme`screens.large`}) {
          ${buttonStyles.sizes.icon.large[$sizeVariant]}
        }
      `;
    }}
  }
`;

export const StyledWrapperButton = styled.button<IButtonStyled>`
  ${buttonStyles.base}
  ${({ $justify }) => {
    return css`
      justify-content: ${$justify};
    `;
  }}

  ${({ $fullWidth }) => $fullWidth && buttonStyles.fullWidth}

  ${({ disabled }) => disabled && buttonStyles.disabled}

  ${({ $color, $variant, disabled }) =>
    buttonStyles.colors[$color ?? "primary"][$variant ?? "filled"][disabled ? "disabled" : "default"]}

   ${({ $sizeVariant, $size }) => {
    if ($size) {
      return buttonStyles.sizes.button[$size][$sizeVariant];
    }

    return css`
      ${buttonStyles.sizes.button.small[$sizeVariant]}

      @media (min-width: ${theme`screens.medium`}) {
        ${buttonStyles.sizes.button.medium[$sizeVariant]}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${buttonStyles.sizes.button.large[$sizeVariant]}
      }
    `;
  }}
`;
