import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import tw, { styled } from "twin.macro";

interface ResponsiveStyles {
  $size?: TSize;
}

const styles = {
  imgWrapper: {
    size: {
      small: tw`w-full object-contain object-top`,
      medium: tw`w-1/2`,
      large: tw`w-1/2`,
    },
  },
};

export const StyledImgWrapper = styled.figure<ResponsiveStyles>`
  ${tw`w-1/2 min-w-[24.063rem] transition-[width] duration-300 ease-in-out`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.imgWrapper.size,
    })}
`;
