/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import { type CSSProperties, type HTMLAttributes } from "react";
import { StyledWrapperParagraph } from "./styles";
import type { ITypographyCommon } from "@/types/common";

export interface IParagraph extends HTMLAttributes<HTMLParagraphElement>, ITypographyCommon {
  fontWeight?: CSSProperties["fontWeight"];
  wordBreak?: CSSProperties["wordBreak"];
  withHover?: boolean;
  [key: string]: any;
}

const Paragraph = ({
  size,
  color,
  withHover,
  sizeVariant,
  fontWeight,
  wordBreak,
  disabled = false,
  ...props
}: IParagraph) => {
  if (!sizeVariant) {
    throw new TypeError("sizeVariant is required");
  }

  return (
    <StyledWrapperParagraph
      {...props}
      $size={size}
      $color={color}
      $sizeVariant={sizeVariant}
      $fontWeight={fontWeight}
      $wordBreak={wordBreak}
      $disabled={disabled}
      $withHover={withHover}
    />
  );
};

export default Paragraph;
