import { css, theme, type TwStyle } from "twin.macro";
import type { TSize, TSizeVariant } from "@/types/common";
import type { RuleSet } from "styled-components";

interface IApplyResponsiveStylesNoSizeVariant {
  size?: TSize;
  sizeVariant?: never;
  stylesBreakpoints: {
    small?: TwStyle | RuleSet<object>;
    medium?: TwStyle | RuleSet<object>;
    large?: TwStyle | RuleSet<object>;
  };
}

interface IApplyResponsiveStylesWithSizeVariant {
  size?: TSize;
  sizeVariant: TSizeVariant;
  stylesBreakpoints: {
    small: Record<TSizeVariant, TwStyle | RuleSet<object>>;
    medium: Record<TSizeVariant, TwStyle | RuleSet<object>>;
    large: Record<TSizeVariant, TwStyle | RuleSet<object>>;
  };
}

type IApplyResponsiveStyles = IApplyResponsiveStylesNoSizeVariant | IApplyResponsiveStylesWithSizeVariant;

const applyResponsiveStyles = ({ stylesBreakpoints, size, sizeVariant }: IApplyResponsiveStyles) => {
  if (sizeVariant !== undefined) {
    if (size) {
      return stylesBreakpoints[size][sizeVariant];
    }

    return css`
      ${stylesBreakpoints.small[sizeVariant]}

      @media (min-width: ${theme`screens.medium`}) {
        ${stylesBreakpoints.medium[sizeVariant]}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${stylesBreakpoints.large[sizeVariant]}
      }
    `;
  } else {
    const styles = stylesBreakpoints;
    if (size) {
      return styles[size];
    }

    return css`
      ${stylesBreakpoints.small && stylesBreakpoints.small}

      ${stylesBreakpoints.medium &&
      css`
        @media (min-width: ${theme`screens.medium`}) {
          ${stylesBreakpoints.medium}
        }
      `}

      ${stylesBreakpoints.large &&
      css`
        @media (min-width: ${theme`screens.large`}) {
          ${stylesBreakpoints.large}
        }
      `}
    `;
  }
};

export default applyResponsiveStyles;
