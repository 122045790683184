import { useState, useEffect } from "react";

/**
 * @example
 * const endTime = 300; // seconds
 *
 * // Opción 1: autoStart (comienza automáticamente como antes)
 * const [count, reset] = useCountDown(endTime, () => {
 *   sideEffect();
 * });
 *
 * // Opción 2: control manual (requiere start)
 * const [count, start, reset] = useCountDown(endTime, () => {
 *   sideEffect();
 * }, false);
 */
export const useCountDown = (endTime: number, onEnd?: () => void, autoStart: boolean = true) => {
  const [count, setCount] = useState(endTime);
  const [isActive, setIsActive] = useState(autoStart);

  useEffect(() => {
    setCount(endTime);
    if (endTime === 0) {
      setIsActive(false);
    } else {
      setIsActive(autoStart);
    }
  }, [endTime, autoStart]);

  useEffect(() => {
    if (!isActive) return;

    const id =
      count === 0
        ? onEnd === undefined
          ? undefined
          : onEnd()
        : window.setTimeout(() => setCount(count - 1), 1000);

    return (): void => (id === undefined ? undefined : clearTimeout(id));
  }, [count, isActive, onEnd]);

  const start = (): void => setIsActive(true);

  const reset = (): void => {
    setCount(endTime);
    setIsActive(autoStart);
  };

  return autoStart ? ([count, reset] as const) : ([count, start, reset] as const);
};
