import tw, { styled } from "twin.macro";
import IconTuner from "@iconsV2/Illustration/tunerIcon.svg?react";

export const StyledWrapperModalInformativeChangeLocation = styled.div`
  ${tw`flex flex-col relative gap-l
    medium:(items-center justify-center text-center)
  `}

  & > h3 {
    ${tw`bg-cta-shopping-default text-transparent bg-clip-text font-bold text-left
      medium:(text-center)
    `}
  }
`;

export const StyledIconTuner = styled(IconTuner)`
  ${tw`w-[8.37506rem] h-[7.60319rem] absolute transition-all ease-in-out duration-300 -right-8 -top-20
    min-[420px]:(w-[9.37506rem] h-[8.60319rem] -right-4 -top-24)
    medium:(w-[10.31244rem] h-[9.46344rem] -left-16)
  `}
`;
