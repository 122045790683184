import { userSlice } from "@/store/user";
import { useDispatch } from "react-redux";

const useUpdateUser = () => {
  const dispatch = useDispatch();

  const updateCurrentUser = (data: Omit<UserAttr, "token">): any => {
    dispatch(
      userSlice.actions.setUser({
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
        phoneNumber: data.telephone,
        creditAvaliable: {
          gross: data.credit_available.gross,
          grossLocalized: data.credit_available.gross_localized,
          net: data.credit_available.net,
          netLocalized: data.credit_available.net_localized,
          currency: data.credit_available.currency,
        },
        referralCreditAvailable: {
          gross: data.referral_credit_available.gross,
          grossLocalized: data.referral_credit_available.gross_localized,
          net: data.referral_credit_available.net,
          netLocalized: data.referral_credit_available.net_localized,
          currency: data.referral_credit_available.currency,
        },
        birthday: data.dob,
        gender: data.gender,
        hearAboutUs: data.hear_about_us_source,
        publicId: data.public_id,
        identification: data.identification,
        identificationType: data.identification_type,
        membership_savings: data.membership_savings,
        membership: data.membership,
      })
    );
  };

  return { updateCurrentUser };
};

export default useUpdateUser;
