import IconCarEmpty from "@/components/Icons/V2/Illustration/cartEmpty.svg?react";
import Error from "@/components/Molecules/Error";
import IconArrowRelod from "@iconsV2/Arrow/reload-01.svg?react";

interface Error503Props {
  withHeader?: boolean;
  hScreen?: boolean;
}
const Error503 = ({ withHeader = false, hScreen = false }: Error503Props) => {
  return (
    <Error
      options={{
        title: "¿Algo salió mal?",
        message: "No te preocupes, dale atrás o recarga la página y tus problemas desaparecerán",
        withHeader,
        hScreen,
        adornment: <IconCarEmpty width={250} height={233} aria-label="carrito vacio" />,
      }}
      buttonsProps={{
        color: "secondary",
        sizeVariant: "lg",
        endIcon: <IconArrowRelod />,
        onClick: () => {
          window.location.reload();
          // // @ts-expect-error Property '$crisp' does not exist on type 'Window & typeof globalThis'.
          // window.$crisp.push(["do", "chat:open"]);
          // Analytics.CustomerCareMessage({ channel: "Crisp" });
        },
      }}
    />
  );
};
export default Error503;
