import tw, { styled } from "twin.macro";
import Paragraph from "@atoms/Paragraph";
import type { TSize } from "@/types/common";

const styles = {
  label: {
    base: tw`font-semibold leading-[normal] text-[0.875rem] tracking-[0.00875rem] text-neutral-10
      medium:(text-[1rem] tracking-[0.01rem])
      large:(text-[1.125rem] tracking-[0.01125rem])
    `,
    state: {
      disabled: tw`text-neutral-80`,
      default: tw`text-neutral-10`,
    },
    size: {
      small: tw`!(text-[0.875rem] tracking-[0.00875rem])`,
      medium: tw`!(text-[1rem] tracking-[0.01rem])`,
      large: tw`!(text-[1.125rem] tracking-[0.01125rem])`,
    },
  },
  text: {
    state: {
      error: tw`text-error-30`,
      success: tw`text-tertiary-50`,
      disabled: tw`text-neutral-80`,
      default: tw`text-[#4F4F4F]`,
    },
  },
  wrapperInput: {
    base: tw`flex flex-col gap-2`,
    fullWidth: tw`w-full`,
    maxWidth: tw`w-max`,
  },
};

export const StyledLabel = styled.label<{ $disabled?: boolean; size?: TSize }>`
  ${styles.label.base}
  ${({ $disabled }) => ($disabled ? styles.label.state.disabled : styles.label.state.default)}
  ${({ size }) => size && styles.label.size[size]}
`;

export const StyledText = styled(Paragraph)<{
  $disabled?: boolean;
  $errors?: boolean;
  $success?: boolean;
}>`
  ${({ $disabled, $errors, $success }) => {
    if ($disabled) return styles.text.state.disabled;
    if ($errors && !$disabled) return styles.text.state.error;
    if ($success && !$disabled) return styles.text.state.success;
    return styles.text.state.default;
  }}
`;

export const StyledWrapperInput = styled.div<{ $fullWidth?: boolean }>`
  ${styles.wrapperInput.base}
  ${({ $fullWidth }) => ($fullWidth ? styles.wrapperInput.fullWidth : styles.wrapperInput.maxWidth)}
`;
