import React, { useState } from "react";
import Image from "@/components/Atoms/Image";
import { StyledImgWrapper } from "@/components/Molecules/AuthStepsImg/styles";

interface AuthStepsImgProps {
  src: string;
  alt: string;
  [key: string]: any;
}

/**
 * AuthStepsImg component.
 *
 * @component
 * @param {AuthStepsImgProps} props - The component props.
 * @param {string} props.src - The source URL of the image.
 * @param {string} props.alt - The alternative text for the image.
 * @returns {JSX.Element} The rendered AuthStepsImg component.
 */
const AuthStepsImg: React.FC<AuthStepsImgProps> = ({ src, alt, ...props }) => {
  // state for handling the image loading state
  const [isLoading, setIsLoading] = useState(true);

  // Handle the onLoad event to know when the image has finished loading

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <StyledImgWrapper
      style={{
        // Apply blur while the image is loading
        filter: isLoading ? "blur(20px)" : "none",
        transition: "filter 1s ease-out",
      }}
    >
      <Image
        {...props}
        src={src}
        alt={alt}
        onLoad={handleLoad}
        style={{
          objectFit: "cover",
          width: "100%",
          height: "100%",
        }}
      />
    </StyledImgWrapper>
  );
};

export default AuthStepsImg;
