import React from "react";
import "twin.macro";

export const FithubPin = (): React.ReactElement => (
  <svg tw="fill-current" viewBox="0 0 22 28">
    <path
      d="M11 16.39c-3.027 0-5.481-2.497-5.481-5.576 0-3.079 2.454-5.575 5.481-5.575 3.027 0 5.481 2.496 5.481 5.575 0 3.08-2.454 5.576-5.481 5.576M15.087.795A10.995 10.995 0 0011 0a11.015 11.015 0 00-4.087.795C1.925 2.798-.929 8.316.274 13.63c.416 1.838 1.285 3.5 1.996 4.742 1.489 2.603 3.433 5.007 5.942 7.35.595.555 2.53 2.058 2.788 2.278.259-.22 2.193-1.723 2.788-2.278 2.51-2.343 4.453-4.747 5.942-7.35.71-1.242 1.58-2.904 1.996-4.742C22.929 8.316 20.075 2.798 15.087.795"
      fillRule="evenodd"
    />
  </svg>
);
