import { useMemo, useState } from "react";
import { type OptionSelect } from "@/components/Molecules/Select";
import { GET_HEARABOUTUS_SOURCE } from "@/graphql/user";
import { useQuery } from "@apollo/client";
import ModalHearAboutUsRegistration from "@/components/Molecules/ModalHearAboutUsRegistration";
import ModalHearAboutUsProfile from "@/components/Molecules/ModalHearAboutUsProfile";
import { setUserSettings } from "@/services/auth";
import { useMutation } from "@tanstack/react-query";

import useAppDispatch from "@/hooks/useAppDispatch";
import { userAction } from "@/store/user";
import useAppSelector from "@/hooks/useAppSelector";

export interface ModalHearAboutUsProps {
  type: "registration" | "profile";
  isOpen: boolean;
  handleClose: () => void;
}

const ModalHearAboutUs = ({ type = "profile", isOpen, handleClose }: ModalHearAboutUsProps) => {
  const user = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [optionSelect, setOptionSelect] = useState<OptionSelect>();
  const { data } = useQuery(GET_HEARABOUTUS_SOURCE);
  const options = useMemo(() => {
    return data?.hearAboutUsSources.map(({ value, label }) => ({ value, label })) || [];
  }, [data?.hearAboutUsSources]);

  // update user in redux
  const updateUserInRedux = (hearAboutUs: string): void => {
    if (user) {
      dispatch(
        userAction.setUser({
          ...user,
          hearAboutUs, // update only hear about us
        })
      );
    }
  };

  // mutation hear about us
  const mutationHearAboutUs = useMutation({
    mutationFn: setUserSettings,
    onSuccess: () => {
      handleClose();
      setIsSubmitting(false);
    },
  });

  // select hear about us
  const handleSelect = (select: OptionSelect | null) => {
    if (select) {
      setOptionSelect(select);
    }
  };

  // submit hear about us
  const handleSubmit = async () => {
    if (!optionSelect) return;
    setIsSubmitting(true);
    if (typeof optionSelect !== "string" && "value" in optionSelect) {
      const newValue = optionSelect.value;
      await mutationHearAboutUs.mutateAsync({ hearAboutUs: newValue });
      updateUserInRedux(newValue);
    }
  };

  const isSubmitDisabled = !optionSelect || isSubmitting;

  const commonProps = {
    isOpen,
    handleClose,
    handleSubmit,
    handleSelect,
    isSubmitDisabled,
    optionSelect,
    options,
  };

  return (
    <>
      {type === "registration" && <ModalHearAboutUsRegistration {...commonProps} />}
      {type === "profile" && <ModalHearAboutUsProfile {...commonProps} />}
    </>
  );
};

export default ModalHearAboutUs;
