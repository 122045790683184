import LogoFithubPrimary from "@iconsV2/Brand/primary.svg?react";
import LogoFithubChristmas from "@iconsV2/Brand/christmas.svg?react";

import useFeatureFlags from "@/hooks/useFeatureFlags";

const BrandLogoFithub = (props) => {
  const { isChristmasTheme } = useFeatureFlags();

  if (isChristmasTheme) {
    return <LogoFithubChristmas {...props} />;
  }

  return <LogoFithubPrimary {...props} />;
};

export default BrandLogoFithub;
