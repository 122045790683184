import React from "react";
import "twin.macro";

export const Mail = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg tw="fill-current" width={24} height={24} viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="mail"
        fill={props.fill ? props.fill : "#F96800"}
        d="M20 3H4C2.3 3 1 4.3 1 6v12c0 1.7 1.3 3 3 3h16c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3zM4 5h16c.4 0 .7.2.9.6L12 11.8 3.1 5.6c.2-.4.5-.6.9-.6zm16 14H4c-.6 0-1-.4-1-1V7.9l8.4 5.9c.2.1.4.2.6.2.2 0 .4-.1.6-.2L21 7.9V18c0 .6-.4 1-1 1z"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="mail2">
        <use xlinkHref="#mail" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#mail" />
      <g mask="url(#mail2)">
        <path fill={props.fill ? props.fill : "#F96800"} d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
