import tw from "twin.macro";
import posthog from "posthog-js";
import { RecommendItemsToItem } from "recombee-js-api-client";
import { useQuery } from "react-query";

import ProductCardGalllerySearch from "@/components/Molecules/ProductCardGalllerySearch";
import ProductCardGallerySkeleton from "@/components/Atoms/ProductCardGallerySkeleton";
import Card from "@/components/Atoms/Card";
import Heading from "@/components/Atoms/Heading";
import ProductCardGallery from "@/components/Molecules/ProductCardGallery";

import { recombeeClient } from "@/recourses/recombee";
import { graphqlToProductGallery, recombeeToOldCart, recombeeToProductGallery } from "@/utils/product";

import { type IProductGalleryData } from "@/types/product";
import { type RecombeeValuesItemUser } from "@/types/recombee";

import { StyledContentProductsRow } from "./styles";

interface QueryReturnType {
  items: IProductGalleryData[];
  oldItems: Cart[];
  id: string;
}

const RecomendedVariusProducts = ({
  product,
  storeId,
  items: itemsCheckout,
}: {
  product: Cart;
  storeId: number;
  items: Cart[];
  [key: string]: any;
}) => {
  const userID = posthog.get_distinct_id();

  const { data, isFetching, error } = useQuery<QueryReturnType>({
    queryKey: ["itemOutOfStock", userID, storeId, product.pk],
    queryFn: async () => {
      const skus = itemsCheckout.map((item) => item.details.sku);
      let filter = `'store_id' == "${storeId}" AND 'product_availability_available_online'`;
      if (skus.length > 0) {
        const skuFilter = skus.map((sku) => `'product_sku' != "${sku}"`).join(" OR ");
        filter += ` AND (${skuFilter})`;
      }
      const response = await recombeeClient.send(
        new RecommendItemsToItem(product.pk, userID, 2, {
          scenario: "item-out-of-stock",
          filter,
          returnProperties: true,
          cascadeCreate: true,
        })
      );

      const items: IProductGalleryData[] = [];
      const oldItems: Cart[] = [];

      response.recomms.forEach(({ id, values }) => {
        items.push(recombeeToProductGallery(Number(id), values as unknown as RecombeeValuesItemUser));
        oldItems.push(recombeeToOldCart(Number(id), values as unknown as RecombeeValuesItemUser));
      });

      return {
        items,
        oldItems,
        id: response.recommId,
      };
    },
    enabled: !!userID && !!storeId && !!product.pk,
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: false,
  });

  return (
    <Card
      classes={{
        root: tw`w-full flex flex-col gap-s shadow-elevation-0 border border-neutral-90`,
      }}
    >
      <ProductCardGalllerySearch
        product={graphqlToProductGallery(product)}
        oldDataProduct={product}
        disabled
        showButtonCart={false}
      />

      {error !== null || (data && data?.items.length >= 1) ? (
        <Heading type="h6">Mira estas sugerencias</Heading>
      ) : (
        <Heading type="h6">No hay recomendaciones disponibles</Heading>
      )}

      {(isFetching || !storeId || !userID || !product.pk) && (
        <StyledContentProductsRow>
          {Array.from({ length: 2 }).map((_, index) => (
            <ProductCardGallerySkeleton key={index} sizeVariant="sm" orientation="horizontal" />
          ))}
        </StyledContentProductsRow>
      )}

      {data && data.items.length > 1 && (
        <StyledContentProductsRow>
          {data?.items
            .slice(0, 2)
            .map((item, index) => (
              <ProductCardGallery
                key={index}
                oldDataProduct={data.oldItems[index]}
                recommId={data.id}
                sizeVariant="sm"
                orientation="horizontal"
                product={item}
              />
            ))}
        </StyledContentProductsRow>
      )}
    </Card>
  );
};

export default RecomendedVariusProducts;
