export default [
  {
    city: "Barranquilla",
    address: "Cra 51 # 82-223",
    to: "https://maps.app.goo.gl/rqZ911Gd6M9LBRLYA",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
  },
  {
    city: "Bogotá",
    address: "Calle 93a #11-46",
    to: "https://maps.app.goo.gl/dbjBn4ZmpyAx2tv77",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
  },
  {
    city: "Medellín",
    // address: "Cra. 30a #9-71 a 9-1",
    // to: "https://maps.app.goo.gl/QsZfWufTSZ5vPWAg7",
    address: "",
    to: "",
    hour: "Lun - Sab 8 am - 8 pm | Dom  10 am - 7 pm",
  },
];
