import { type RouteObject } from "react-router-dom";

const routesNewProfile: RouteObject[] = [
  {
    path: "personal-data",
    lazy: async () => {
      const { default: PersonalData } = await import("@/Pages/PersonalData");
      return {
        element: <PersonalData />,
      };
    },
  },
  {
    path: "shipping-address",
    lazy: async () => {
      const { default: ShippingAddress } = await import("@/Pages/ShippingAddress");
      return {
        element: <ShippingAddress />,
      };
    },
  },
  {
    path: "security/password-change",
    lazy: async () => {
      const { default: PasswordChange } = await import("@/Pages/PasswordChange");
      return {
        element: <PasswordChange />,
      };
    },
  },
  {
    path: "shipping-address/new",
    lazy: async () => {
      const { default: ShippingAddressNew } = await import("@/Pages/ShippingAddressNew");
      return {
        element: <ShippingAddressNew />,
      };
    },
  },
  {
    path: "payment-methods",
    lazy: async () => {
      const { default: PaymentMethods } = await import("@/Pages/PaymentMethods");
      return {
        element: <PaymentMethods />,
      };
    },
  },
  {
    path: "payment-methods/add-card",
    lazy: async () => {
      const { default: CardAdd } = await import("@/Pages/CardAdd");
      return {
        element: <CardAdd />,
      };
    },
  },
  {
    path: "payment-methods/add-nequi",
    lazy: async () => {
      const { default: NequiAdd } = await import("@/Pages/NequiAdd");
      return {
        element: <NequiAdd />,
      };
    },
  },
  {
    path: "update-phone",
    lazy: async () => {
      const { default: UpdatePhone } = await import("@/Pages/UpdatePhone");
      return {
        element: <UpdatePhone />,
      };
    },
  },
  {
    path: "shipping-address/new",
    lazy: async () => {
      const { default: ShippingAddressNew } = await import("@/Pages/ShippingAddressNew");
      return {
        element: <ShippingAddressNew />,
      };
    },
  },
  {
    path: "security",
    lazy: async () => {
      const { default: Security } = await import("@/Pages/Security");
      return {
        element: <Security />,
      };
    },
  },
];

export default routesNewProfile;
