import React from "react";
import "twin.macro";

export const Money = (): React.ReactElement => (
  <svg tw="fill-current" width={59} height={62} viewBox="0 0 59 62">
    <g transform="translate(-1020 -1297)" fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M1077.633 1336.82a6.31 6.31 0 00-7.453-2.758l-7.96 2.653a5.973 5.973 0 00-2.539-4.2 7.77 7.77 0 002.046-5.26v-6.437h-6.438a7.748 7.748 0 00-4.199 1.232v-4.016l8.981-8.981a7.07 7.07 0 000-9.988 7.072 7.072 0 00-9.988 0l-.793.793-.792-.793c-2.754-2.753-7.234-2.754-9.988 0a7.07 7.07 0 000 9.988l8.98 8.98v4.03a7.745 7.745 0 00-4.22-1.245h-6.437v6.437a7.76 7.76 0 001.322 4.34c-2.058.31-4.03 1.14-5.686 2.414l-1.983 1.526h-10.465v19.108h7.952v3.793h3.6v-3.793h30.644l13.567-9.322a6.362 6.362 0 001.85-8.502zm-3.888 5.534l-12.645 8.69h-29.527v-11.91l3.091-2.272a8.754 8.754 0 015.31-1.807h16.316a2.378 2.378 0 012.375 2.375 2.377 2.377 0 01-2.375 2.375h-11.33v3.6h11.33c2.02 0 3.809-1.01 4.89-2.549l10.138-3.379a2.733 2.733 0 013.228 1.194 2.755 2.755 0 01-.8 3.683zm-50.124-3.22h4.352v11.91h-4.353v-11.91zm16.812-14.716h2.837c2.316 0 4.2 1.884 4.2 4.2v2.837h-2.837a4.205 4.205 0 01-4.2-4.2v-2.837zm17.694 0v2.837c0 2.316-1.884 4.2-4.2 4.2h-2.837v-2.879a4.205 4.205 0 014.2-4.158h2.837zm-17.072-22.808a3.453 3.453 0 012.448-1.013c.887 0 1.774.338 2.449 1.013l3.338 3.338 3.339-3.338c1.35-1.35 3.547-1.35 4.897 0s1.35 3.546 0 4.896l-8.236 8.236-8.235-8.236a3.466 3.466 0 010-4.896z"
      />
    </g>
  </svg>
);
