import tw, { styled } from "twin.macro";

interface IStyledWrapperPGCard {
  $isPromoted?: boolean;
  $active?: boolean;
  $disabled?: boolean;
}

export const StyledWrapperFigure = styled.figure<{ $disabled?: boolean }>`
  ${tw`w-[3.4375rem] h-[3.4375rem] relative rounded-s border border-neutral-90 overflow-hidden
    medium:(w-[4.375rem] h-[4.375rem])
    large:(w-[5rem] h-[5rem])`}

  & img {
    ${tw`w-full h-full object-cover`}

    ${({ $disabled }) => {
      if ($disabled) {
        return tw`opacity-40`;
      }
    }};
  }
`;

export const StyledWrapperHead = styled.div`
  ${tw`flex items-center justify-between gap-s`}
`;

export const StyledWrapperBody = styled.div`
  ${tw`flex items-center justify-between`}
`;

export const StyledWrapperContent = styled.div`
  ${tw`flex flex-col justify-evenly gap-s`}
`;

export const StyledWrapperPGCardSearch = styled.div<IStyledWrapperPGCard>`
  ${tw`p-s gap-s grid grid-cols-[min-content_1fr] rounded-[0.5rem] bg-primaryV2-100 border`}

  ${({ $isPromoted, $active }) =>
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    $isPromoted || $active ? tw`border-primaryV2-70 shadow-orange` : tw`border-neutral-90`}
`;

export const StyledWrapperHelpMessage = styled.div`
  ${tw`flex items-center w-full gap-xxs`}

  & > svg {
    ${tw`w-[0.625rem] h-[0.625rem]
      medium:(w-[0.75rem] h-[0.75rem])
      large:(w-[1rem] h-[1rem])
    `}
  }
`;
