import axios from "axios";

const http = axios.create({
  useFormData: false,
  isAuth: true,
  isAuthExternal: false,
});

http.interceptors.request.use(
  (config) => {
    if (config.isAuth && !config.isAuthExternal) {
      const JWToken = localStorage.getItem("userToken");

      if (JWToken) {
        config.headers.Authorization = `Bearer ${JSON.parse("" + JWToken)}`;
      }
    }

    if (config.useFormData) {
      config.headers["Content-Type"] = "multipart/form-data";
    }

    return config;
  },
  async (error: any) => {
    return await Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: any) => {
    const { response = {} } = error;

    const url = String(response?.config?.url ?? "");

    const _error = {
      message: "",
      url,
      status: response.status,
      statusText: response.statusText,
      data: response.data,
      errors: response?.data?.errors,
    };

    if (response?.status >= 400 && response?.status < 500) {
      _error.message = `Bad response? from server at ${url} => ${String(response.status)}, ${String(
        response.statusText
      )}`;
    }

    if (response?.status >= 500) {
      _error.message = "Hubo un problema al procesar tu petición. Por favor intenta nuevamente más tarde.";
    }

    return await Promise.reject(_error);
  }
);

export default http;
