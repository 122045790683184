import tw, { styled } from "twin.macro";
import { Link } from "react-router-dom";
import Paragraph from "@/components/Atoms/Paragraph";
import Heading from "@/components/Atoms/Heading";
import { styledWrapperBase } from "@/styles/common";

export const StyledTitle = styled(Heading)`
  ${tw`text-neutral-80 font-semibold`}
`;

export const StyledLabel = styled(Paragraph)`
  ${tw`text-neutral-100 font-medium`}
`;

export const StyledText = styled(Paragraph)`
  ${tw`text-neutral-80`}
`;

export const StyledLink = styled(Link)`
  ${tw`text-neutral-80 underline`}

  & > p {
    ${tw`text-current`}
  }
`;

export const StyledWrapperFooter = styled.footer`
  ${tw`bg-neutral-10 h-full w-full pt-20 pb-12 grid gap-10
    large:(gap-24)
  `}

  & > div {
    ${styledWrapperBase}
    ${tw`flex flex-col gap-8
      large:(gap-12)
    `}
  }
`;

export const StyledWrapperCity = styled.div`
  ${tw`grid gap-4`}
  & > div {
    ${tw`flex flex-col gap-2
      large:(gap-4)
    `}

    & > a p {
      ${tw`underline`}
    }

    & > div,
    a {
      ${tw`flex gap-2 items-center`}
      & > svg {
        ${tw`w-4 h-4`}
        & > path {
          ${tw`fill-neutral-80`}
        }
      }
    }
  }
`;

export const StyledWrapperWhatsApp = styled.div`
  ${tw`grid gap-4`}
  & > div {
    ${tw`flex flex-col gap-2 items-start`}

    & > button {
      ${tw`border-primaryV2-50! text-primaryV2-50! bg-transparent!`}
    }

    & > div {
      ${tw`flex gap-2 items-center`}
      & > svg:not(.no-color) {
        ${tw`w-4 h-4`}
        & > path {
          ${tw`fill-neutral-80`}
        }
      }
      & > svg.no-color {
        ${tw`w-8 h-8`}
      }
    }
  }
`;

export const StyledWrapperInfoAddress = styled.div`
  ${tw`grid gap-6`}
`;

export const StyledWrapperInfoNewLetter = styled.div`
  ${tw`grid gap-8
      large:(gap-ml)
  `}

  & > svg {
    ${tw`hidden w-[6.625rem] h-[3.8125rem] transform -rotate-12 large:(block) mb-6
      large:(w-[8.625rem] h-[3.8125rem])
    `}
  }

  & div.link {
    ${tw`flex flex-col items-start w-fit`}

    & > a {
      ${tw`text-neutral-80! bg-transparent! underline px-null`}
    }
  }

  & div.form {
    ${tw`flex flex-col gap-4
      large:(gap-6)
    `}
  }
  & div.backed {
    ${tw`grid items-center medium:(grid-cols-2) gap-4`}
    & > svg:first-child {
      ${tw`w-[9.0625rem] h-[1.875rem]`}
    }
    & > svg:last-child {
      ${tw`w-[10.375rem] h-[2.5625rem]`}
    }
  }
`;

export const StyledWrapperInfo = styled.div`
  ${tw`grid gap-8
    min-[950px]:(grid-cols-2)
  `}
`;

export const StyledWrapperLinks = styled.div`
  ${tw`flex flex-col items-center gap-6
    min-[950px]:(grid grid-cols-[1fr_max-content_max-content] gap-10)
  `}
  & div.flag-logo {
    ${tw`flex gap-4 items-center`}
    & > svg:first-child {
      ${tw`w-[4.0625rem] h-[1.25rem]
          large:(w-[8.33444rem] h-[2.4865rem])
      `}
    }
    & > svg:last-child {
      ${tw`w-4 h-4
        large:(w-6 h-6)
      `}
    }
  }
  & div.links {
    ${tw`flex gap-4 items-center justify-around
        large:(justify-start gap-6)
    `}
  }
  & div.social-network {
    ${tw`flex gap-6 items-center justify-around`}
    & > a > svg {
      ${tw`w-4 h-4
        large:(w-6 h-6)
      `}
    }
  }
`;
