import { useLocation } from "react-router-dom";
import { pageView, viewCart } from "@/utils/analyticsV2";
import useAppSelector from "./useAppSelector";
import useSessionContext from "./useSesionContext";

const useAnalytics = () => {
  const { pathname } = useLocation();
  const user = useAppSelector((state) => state.user);
  const { isAthenticated } = useSessionContext();

  const userType = isAthenticated() ? "logged_in" : "guest";

  return {
    pageView: {
      eventPageCategory: () => pageView.eventPageCategory(pathname, userType),
    },
    viewCart: {
      eventUserInfo: (product: Cart) =>
        viewCart.eventUserInfo({
          product,
          userId: String(user?.id),
          userType,
        }),
    },
  };
};

export default useAnalytics;
