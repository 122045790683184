import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import tw, { styled } from "twin.macro";
import Paragraph from "../Paragraph";
import IconInfo from "@/components/Icons/V2/Warnings/information-circle-fill-none.svg?react";
import IconError from "@/components/Icons/V2/Warnings/attention-stop-fill-none.svg?react";
import IconWarning from "@/components/Icons/V2/Warnings/attention-triangle-fill-none.svg?react";

type type = "info" | "warning" | "error";
interface ResponsiveStylesProps {
  type: "info" | "warning" | "error";
  $size?: TSize;
  hFull?: boolean;
}

interface StyledTextProps extends ResponsiveStylesProps {
  type: type;
}
interface StyledNotificationWrapperProps {
  type: type;
  $size?: TSize;
}
interface StyledIconProps {
  $size?: TSize;
}

const styles = {
  StyledNotificationWrapper: {
    types: {
      info: tw` border-neutral-70 `,
      warning: tw` border-primaryV2-70`,
      error: tw` border-error-50`,
    },
    size: {
      small: tw`p-[0.333rem] `,
      medium: tw`p-[0.34rem] `,
      large: tw`p-[0.354rem] `,
    },
  },
  StyledNotificationItem: {
    size: {
      small: tw` gap-s`,
      medium: tw` gap-sm`,
      large: tw` gap-m`,
    },
  },
  StyledText: {
    types: {
      info: tw`text-neutral-10`,
      warning: tw`text-primaryV2-20`,
      error: tw`text-error-10`,
    },
  },
  icon: {
    size: {
      small: tw`w-[0.8757rem ] h-[0.875rem]`,
      medium: tw`h-[0.938rem] w-[0.938rem]`,
      large: tw`h-[1.042rem] w-[1.042rem]`,
    },
  },
};

export const StyledNotificationWrapper = styled.div<StyledNotificationWrapperProps>`
  ${tw`border rounded-s grid gap-xs items-center`}
  ${({ type }) => styles.StyledNotificationWrapper.types[type]}

   ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.StyledNotificationWrapper.size,
    })}
`;

export const StyledNotificationItem = styled.div<ResponsiveStylesProps>`
  ${tw`grid grid-cols-[max-content_1fr] gap-m items-center`}

  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.StyledNotificationItem.size,
    })}
      ${({ hFull }) => hFull && tw`h-full`}
`;

export const StyledText = styled(Paragraph)<StyledTextProps>`
  ${({ type }) => styles.StyledText.types[type]}
`;

export const StyledIconInfo = styled(IconInfo)<StyledIconProps>`
  ${tw`h-[1.042rem] w-[1.042rem] fill-secondary-50`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.icon.size,
    })}
`;

export const StyledIconError = styled(IconError)<StyledIconProps>`
  ${tw`h-[1.042rem] w-[1.042rem] fill-error-50`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.icon.size,
    })}
`;

export const StyledIconWarning = styled(IconWarning)<StyledIconProps>`
  ${tw`h-[1.042rem] w-[1.042rem] fill-primaryV2-50`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.icon.size,
    })}
`;
