import React from "react";
import { StyledDigitCodeWrapper } from "./styles";
import TextField from "@/components/Atoms/TextField";
import { useDigitInput } from "@/hooks/useDigitInput";
import { type TSize } from "@/types/common";

interface DigitCodeProps {
  value: string;
  onChange: (value: string) => void;
  size?: TSize;
  length?: number;
}

const DigitCode = ({ value, onChange, size, length = 4 }: DigitCodeProps): React.ReactElement => {
  const digits = useDigitInput(length, value, onChange);

  return (
    <StyledDigitCodeWrapper>
      {digits.map((digitProps, index) => (
        <TextField key={index} inputMode="decimal" {...digitProps} tw="text-center" size={size} />
      ))}
    </StyledDigitCodeWrapper>
  );
};

export default DigitCode;
