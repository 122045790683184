import type { CSSProperties } from "react";
import { theme } from "twin.macro";
import type { TSize } from "@/types/common";
import { StyledWrapperAvatar } from "./styles";
import ButtonText from "../ButtonText";

interface IAvatarProps {
  size?: TSize;
  label?: string;
  backgroundColor?: CSSProperties["backgroundColor"];
  color?: CSSProperties["color"];
  [key: string]: any;
}

const getInitials = (label: string) => {
  let result = "";

  const parts = label.trimEnd().split(" ");
  const lastPart = parts.at(-1);
  const firstPart = parts.at(0);

  if (lastPart && firstPart && firstPart !== lastPart) {
    result = firstPart.charAt(0) + lastPart.charAt(0);
  } else if (firstPart) {
    result = firstPart.charAt(0) + (firstPart.length > 1 ? firstPart.charAt(1) : "");
  }

  return result.toUpperCase();
};

const Avatar = ({ size, label, backgroundColor, color, ...props }: IAvatarProps) => {
  const DISPLAY_INITIALS = getInitials(label || "Anónimo");

  return (
    <StyledWrapperAvatar $size={size} $backgroundColor={backgroundColor} {...props}>
      <ButtonText sizeVariant="md" size={size} color={color ?? theme`colors.neutral.100`} tw="text-center">
        {DISPLAY_INITIALS}
      </ButtonText>
    </StyledWrapperAvatar>
  );
};

export default Avatar;
