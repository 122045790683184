import Heading from "@/components/Atoms/Heading";
import { PhoneInput } from "../PhoneInput";
import Button from "@/components/Atoms/Button";
import { useState } from "react";
import Notification, { type NotificationProps } from "@/components/Atoms/Notification";
import IconLoader from "@/components/Icons/V2/Loader/loader-light.svg?react";
import {
  StyledFormSetNewPhoneHeader,
  StyledFormSetNewPhoneRoot,
  StyledFormSetNewPhoneRootFooter,
  StyledFormSetNewPhoneRootValues,
} from "./styles";

interface FormSetNewPhoneProps {
  onSubmit?: (phone: string, channel: changePhoneV2Props["channel"]) => void;
  textHelp?: {
    messages: NotificationProps["messages"];
    type: NotificationProps["type"];
  };
  isLoading?: {
    whatsapp: boolean;
    sms: boolean;
  };
}

export const FormSetNewPhone = ({ onSubmit, textHelp, isLoading }: FormSetNewPhoneProps) => {
  const [value, setValue] = useState("");

  const handleSubmit = (channel: changePhoneV2Props["channel"]) => {
    onSubmit?.(value, channel);
  };

  return (
    <StyledFormSetNewPhoneRoot>
      <StyledFormSetNewPhoneRootValues>
        <StyledFormSetNewPhoneHeader>
          <Heading type="h4">Actualización de número de teléfono</Heading>
        </StyledFormSetNewPhoneHeader>

        <PhoneInput
          sizeVariant="lg"
          label="Ingresa tu nuevo número de teléfono"
          placeholder="Numero de telefono"
          withReferenceWidth
          onChange={(phone) => setValue(phone)}
        />
      </StyledFormSetNewPhoneRootValues>

      <StyledFormSetNewPhoneRootFooter>
        {textHelp && <Notification messages={textHelp.messages} type={textHelp.type} />}

        <Button
          title="Recibirás un código de verificación por Whatsapp"
          sizeVariant="lg"
          color="secondary"
          disabled={value.length < 9 || isLoading?.whatsapp}
          onClick={() => {
            handleSubmit("whatsapp");
          }}
        >
          {!isLoading?.whatsapp ? "Enviar a Whatsapp" : <IconLoader tw="w-8 h-8 animate-spin" />}
        </Button>
        <Button
          title="Recibirás un código de verificación por SMS"
          variant="outlined"
          sizeVariant="md"
          color="secondary"
          disabled={value.length < 9 || isLoading?.sms}
          onClick={() => {
            handleSubmit("sms");
          }}
        >
          {!isLoading?.sms ? "Enviar por SMS" : <IconLoader tw="w-8 h-8 animate-spin" />}
        </Button>
      </StyledFormSetNewPhoneRootFooter>
    </StyledFormSetNewPhoneRoot>
  );
};
export default FormSetNewPhone;
