import { useContext } from "react";

import { sessionContext, type SessionContextType } from "@context/session";

export default function useSessionContext() {
  const context = useContext<SessionContextType>(sessionContext);

  if (!context) {
    throw new Error("useSessionContext must be used within a SessionProvider");
  }

  return context;
}
