import { gql } from "@apollo/client";

export const GET_HEARABOUTUS_SOURCE = gql`
  query {
    hearAboutUsSources {
      value
      label
    }
  }
`;

export const GET_DOCUMENT_TYPE = gql`
  query {
    identificationTypes {
      value
      label
    }
  }
`;
