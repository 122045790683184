import FormSetNewPhone from "@/components/Molecules/FormSetNewPhone";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { changePhoneV2, getUserSettings, verifyPhoneChange, verifyPlusToken } from "@/services/auth";
import FormSecurity from "@/components/Molecules/FormSecurity";
import useAppDispatch from "@/hooks/useAppDispatch";
import { genericsAction } from "@/store/generics";
import { type renderMessagesProps } from "@/components/Atoms/Notification";
import { useNavigate } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import useSessionContext from "@/hooks/useSesionContext";
import useUpdateUser from "@/hooks/useUpdateUser";
import useAppSelector from "@/hooks/useAppSelector";

export enum CALLBACK_MODAL_TOKEN_PLUS {
  CONTINUE = "CONTINUE_UPDATE_PHONE",
}
const UpdatePhone = () => {
  const { userToken } = useSessionContext();
  const { updateCurrentUser } = useUpdateUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [phone, setPhone] = useState("");
  const [channel, setChannel] = useState<changePhoneV2Props["channel"]>("whatsapp");
  const [textHelps, setTextHelps] = useState<renderMessagesProps | undefined>();
  const [isLoading, setIsLoading] = useState({ whatsapp: false, sms: false });
  const callbackIdentifier = useAppSelector((state) => state.generics.modalTokenPlus.callbackIdentifier);

  //  token verification mutation
  const verifyTokenMutation = useMutation({
    mutationFn: async () => {
      const token: string = Cookies.get("authTokenPlus");
      if (!token) throw new Error("Token no encontrado");
      await verifyPlusToken(token);
      return token;
    },

    onError: () => {
      dispatch(
        genericsAction.setOpenModalTokenPlus({
          open: true,
        })
      );
    },
  });

  // change phone mutation
  const requestPhoneVerificationMutation = useMutation({
    mutationFn: changePhoneV2,
    onSuccess: () => {
      setStep(2);
    },
    onError: (error: ErrorV2) => {
      if (error.status === 429) {
        const formattedTime = formatResponseTime(error.data?.detail);
        setTextHelps({
          messages: `Demasiados intentos, intenta de nuevo en ${formattedTime}`,
          type: "error",
        });
      }
    },
  });

  // update user mutation
  const updateUserMutation = useMutation({
    mutationFn: getUserSettings,
    onSuccess: ({ user }) => {
      updateCurrentUser(user);
    },
  });

  // function to handle the phone update
  const handleUpdatePhone = async (phone: string, channel: changePhoneV2Props["channel"]) => {
    if (callbackIdentifier === CALLBACK_MODAL_TOKEN_PLUS.CONTINUE) {
      dispatch(genericsAction.setCallbackTokenPlusIdentifier(undefined));
    }

    setIsLoading((prev) => ({ ...prev, [channel]: true }));
    try {
      const token = await verifyTokenMutation.mutateAsync();
      await requestPhoneVerificationMutation.mutateAsync({
        newPhone: `+${phone}`,
        channel,
        plusToken: token,
      });
    } catch (error) {
      console.error("Error al actualizar el teléfono:", error);
    } finally {
      setIsLoading({ whatsapp: false, sms: false });
    }
  };

  // Function to request the verified phone code
  const requestVerifiedPhoneCode = async (code: string) => {
    const token = Cookies.get("authTokenPlus");
    try {
      await verifyPhoneChange({ phone: `+${phone}`, code, plusToken: token });
      updateUserMutation.mutate({ token: userToken });
      navigate("/profile/personal-data");
    } catch (error) {
      setTextHelps({ messages: "Código incorrecto", type: "error" });
    }
  };

  const formatResponseTime = (str: string): string => {
    const secondsMatch = str.match(/(\d+) seconds/);
    const seconds = secondsMatch?.[1] ? parseInt(secondsMatch[1], 10) : 0;

    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const finalSeconds = remainingSeconds % 60;

    return `${hours} horas, ${minutes} minutos, ${finalSeconds} segundos`;
  };

  useEffect(() => {
    if (step === 2) {
      setTextHelps(undefined);
    }
  }, [step]);

  useEffect(() => {
    if (callbackIdentifier === CALLBACK_MODAL_TOKEN_PLUS.CONTINUE) {
      void handleUpdatePhone(phone, channel);
    }
  }, [callbackIdentifier]);

  return (
    <>
      {step === 1 && (
        <FormSetNewPhone
          onSubmit={async (phone, channel) => {
            setPhone(phone);
            setChannel(channel);
            if (textHelps) setTextHelps(undefined);
            await handleUpdatePhone(phone, channel);
          }}
          textHelp={textHelps}
          isLoading={isLoading}
        />
      )}
      {step === 2 && (
        <FormSecurity
          type="code"
          sendTo={phone}
          onResend={async () => {
            const token = await verifyTokenMutation.mutateAsync();
            await requestPhoneVerificationMutation.mutateAsync({
              newPhone: `+${phone}`,
              channel,
              plusToken: token,
            });
          }}
          onSubmit={async (code) => {
            if (code) {
              await requestVerifiedPhoneCode(code?.code);
            }
          }}
          textHelp={textHelps}
        />
      )}
    </>
  );
};

export default UpdatePhone;
