import tw, { styled } from "twin.macro";

export const StyledFormSetNewPhoneRoot = styled.div`
  ${tw`h-full flex flex-col gap-ml p-m `}
`;

export const StyledFormSetNewPhoneRootValues = styled.div`
  ${tw`w-full flex flex-col  gap-m`}
`;

export const StyledFormSetNewPhoneHeader = styled.div`
  ${tw`flex items-center justify-between gap-m mb-m`}
`;
export const StyledFormSetNewPhoneRootFooter = styled.div`
  ${tw`flex flex-col   gap-ml grow  justify-end`}
`;
