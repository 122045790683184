import React from "react";
import "twin.macro";

export const Package = (): React.ReactElement => (
  <svg tw="fill-current" width={62} height={62} viewBox="0 0 62 62">
    <g transform="translate(-579 -1446)" fillRule="evenodd">
      <g fillRule="nonzero">
        <path d="M632.619 1471.636l-22.9-7.591-22.899 7.59-7.819 12.42 7.199 2.387v13.2l23.52 7.797 23.52-7.797v-13.2l7.198-2.387-7.818-12.42zm-6.9 1.505l-16 5.304-15.999-5.305 16-5.303 15.999 5.303zm-41.223 8.943l4.275-6.79 18.2 6.032-4.274 6.791-18.2-6.033zm5.303 5.551l14.442 4.787 3.678-5.843v16.47l-18.119-6.005v-9.408zm21.72 15.414v-16.469l3.679 5.843 14.441-4.787v9.408l-18.12 6.006zm5.223-14.932l-4.275-6.79 18.2-6.034 4.276 6.791-18.201 6.033zM607.919 1446h3.6v12.021h-3.6zM590.99 1451.5l3.117-1.8 6.01 10.41-3.117 1.8zM619.32 1460.112l6.01-10.41 3.118 1.8-6.01 10.41z" />
      </g>
    </g>
  </svg>
);
