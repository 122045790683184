import tw, { styled } from "twin.macro";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import IconCartPromo from "@/components/Icons/V2/Cart/cart-promo.svg?react";

export const StyledRootPromoContent = styled.div`
  ${tw`grid`}
`;
export const StyledIconClose = styled(IconClose)`
  ${tw`h-ml w-ml  cursor-pointer self-end`}
`;

export const StyledColumn = styled.section`
  ${tw`p-m flex flex-col gap-m `}
`;
export const StyledRow = styled.div`
  ${tw`grid grid-cols-[35%_1fr]  md:grid-cols-[22%_1fr] gap-m items-center`}
`;
export const StyledPromoCode = styled.div`
  ${tw`relative p-m bg-cta-shopping-default box-border h-[7.625rem] `}

  .zigZag {
    position: absolute;
    width: 100%;
    left: 0;
    --a: 90deg;
    --s: 10px;
    -webkit-mask: var(--mask);
    mask: var(--mask);
    width: 100%;
    height: 10px;
    --mask: conic-gradient(
        from calc(180deg - var(--a) / 2) at top,
        #0000,
        #000 1deg var(--a),
        #0000 calc(var(--a) + 1deg)
      )
      50% / var(--s);
    bottom: -5px;
    background: white;
  }
`;
export const StyledPromoCodeTextContainer = styled.div`
  ${tw`h-full border border-dashed  flex items-center justify-center rounded-l`}
`;
export const StyledHeadPromoCode = styled.section`
  ${tw`flex flex-col gap-ml p-m`}

  .gradient-title {
    ${tw`self-center [grid-column-start: 2]  `}
    gird-column: 3;

    background: linear-gradient(0deg, #ec6e12 0%, #e24b55 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
  }
`;

export const StyleIconCartPromo = styled(IconCartPromo)`
  ${tw`w-[8.5rem] h-[11rem] fill-transparent absolute -top-[2.65rem] -left-[1.55rem]`}
`;
