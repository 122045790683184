import { useState, type ReactNode } from "react";
import { LazyLoadImage, type LazyLoadImageProps } from "react-lazy-load-image-component";

interface IImage extends LazyLoadImageProps {
  errorSrc?: string;
  error?: ReactNode;
  [key: string]: any;
}

const Image = ({ error, errorSrc, ...props }: IImage) => {
  const [imgError, setImgError] = useState(false);

  if (imgError) return error;

  return (
    <LazyLoadImage
      {...props}
      onError={(e) => {
        if (errorSrc) {
          props.src = errorSrc;
          return null;
        }
        setImgError(true);
      }}
    />
  );
};

export default Image;
