import { type ButtonHTMLAttributes, type FC, type ReactNode, type PropsWithRef, forwardRef } from "react";
import {
  StyledParagraph,
  StyledEndIcon,
  StyledStartIcon,
  StyledWrapperBtnCategory,
  StyledWrapperContent,
} from "./styles";
import type { ITypographyCommon, TColor } from "@/types/common";
import type { CSSProperties } from "styled-components";

export interface IPopoverButton
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    Omit<ITypographyCommon, "sizeVariant"> {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  fullWidth?: boolean;
  color?: TColor;
  rounded?: boolean;
  label?: string;
  justify?: CSSProperties["justifyContent"];
  [key: string]: any;
}

const PopoverButton: FC<PropsWithRef<IPopoverButton>> = forwardRef(
  (
    {
      children,
      size,
      startIcon,
      endIcon,
      label,
      color = "neutral",
      fullWidth = false,
      rounded = true,
      justify = "start",
      ...props
    },
    ref
  ) => {
    return (
      <StyledWrapperBtnCategory
        ref={ref}
        {...props}
        $size={size}
        $color={color}
        $fullWidth={fullWidth}
        $rounded={rounded}
      >
        {startIcon && <StyledStartIcon $size={size}>{startIcon}</StyledStartIcon>}

        <StyledWrapperContent $justify={justify}>
          {label ? (
            <StyledParagraph size={size} sizeVariant={"md"}>
              {label}
            </StyledParagraph>
          ) : (
            <>{children}</>
          )}
        </StyledWrapperContent>

        {endIcon && <StyledEndIcon $size={size}>{endIcon}</StyledEndIcon>}
      </StyledWrapperBtnCategory>
    );
  }
);

export default PopoverButton;
