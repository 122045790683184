import tw, { styled } from "twin.macro";

import BrandLogoFithub from "@/components/Atoms/BrandLogoFithub";
import { styledWrapperBase } from "@/styles/common";

export const StyledLogoFithubPrimary = styled(BrandLogoFithub)`
  ${tw`w-[3.75rem] h-[1.11744rem] transition-all duration-300 ease-in-out
    medium:(w-[5rem] h-[1.48988rem])
    large:(w-[6.71181rem] h-8)`}
`;

export const StyledWrapperHeader = styled.header`
  ${styledWrapperBase}
  ${tw`bg-primaryV2-100 flex justify-between items-center py-m px-m transition-all duration-300 ease-in-out
    medium:(py-sm px-l)
    large:(py-m px-null)
  `}
`;
