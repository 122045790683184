import { API_URL_V1 } from "../constants";

export const verifyTokenJWT = `${API_URL_V1}/auth/verify/`;

export const TOKEN_PLUS = "/auth/plus/generate/";

export const AUTH_CHANGE_PASSWORD_ENDPOINT_V2 = "/profile/change-password/";

export const AUTH_PHONE_CHANGE = "/profile/request-phone-change/";

export const AUTH_VERIFY_PLUS_TOKEN = "/auth/plus/verify/";

export const AUTH_VERIFY_CODE = "/profile/verify-phone-change/";
