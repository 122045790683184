import { useEffect, useMemo, useState, type MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import useCart from "@/hooks/useCartV2";
import type { IProductGalleryData } from "@/types/product";
import { existProductCart } from "@/utils/cartV2";
import { calculatePercentageDiscount } from "@/utils/money";

interface IUseProductCardGallery {
  oldDataProduct?: Cart;
  product?: IProductGalleryData;
}

export default function useProductCardGallery({ oldDataProduct, product }: IUseProductCardGallery) {
  const navigate = useNavigate();
  const { items, handleAddCart, handleRemove, handleDecrement, handleChangeInput, ...props } = useCart();
  const [showInput, setShowInput] = useState(false);
  const [userIsTyping, setUserIsTyping] = useState(false);

  const cartItem = existProductCart(items, product?.id);

  const productQuantity = useMemo(() => {
    return cartItem?.quantity ?? 0;
  }, [cartItem?.quantity]);

  const isProductAvailable = useMemo(() => {
    return product?.isAvailable;
  }, [product?.quatityAvailable, product?.isAvailable]);

  const percentageDiscount = useMemo(
    () =>
      calculatePercentageDiscount({
        priceDiscount: product?.price ?? 0,
        priceOriginal: product?.originalPrice ?? 0,
      }),
    [product?.originalPrice, product?.price]
  );

  const handleAddToCart = () => {
    if (!isProductAvailable || !oldDataProduct) {
      return;
    }
    setUserIsTyping(false);
    handleAddCart(oldDataProduct);
  };

  const handledToCleanCart = () => {
    if (!product) {
      return;
    }
    handleRemove(product?.id, () => {
      setShowInput(false);
      setUserIsTyping(false);
    });
  };

  const handleToDecrement = () => {
    if (!product) {
      return;
    }
    handleDecrement(product?.id, () => {
      setShowInput(false);
      setUserIsTyping(false);
    });
  };

  const handleToChangeInput = (quantity: number) => {
    if (!product) {
      return;
    }
    setUserIsTyping(true);
    if (!quantity) {
      handleChangeInput(product?.id, "");
      setShowInput(true);
      return;
    }
    handleChangeInput(product?.id, quantity.toString());
  };

  const handleGoToBrand = (e: MouseEvent<HTMLHeadingElement>) => {
    e.preventDefault();

    // Go to brand page
    navigate(`/b/${product?.brandSlug}`);
  };

  useEffect(() => {
    if (!userIsTyping) {
      setShowInput(productQuantity >= 1);
    }
  }, [productQuantity, userIsTyping]);

  useEffect(() => {
    const $counterElement = document.getElementById("counter-input");

    const handleMouseDown = (e: MouseEvent) => {
      if ($counterElement && !$counterElement.contains(e.target as Node)) {
        if (productQuantity === 0) {
          handledToCleanCart();
        }
      }
    };

    if (showInput) {
      document.addEventListener("mousedown", handleMouseDown);
      return () => {
        document.removeEventListener("mousedown", handleMouseDown);
      };
    }
  }, [showInput, productQuantity]);

  return {
    // states:
    showInput,
    productQuantity,
    isProductAvailable,
    percentageDiscount,

    // functions:
    handleAddToCart,
    handledToCleanCart,
    handleToDecrement,
    handleToChangeInput,
    handleGoToBrand,

    // rest:
    ...props,
  };
}
