import tw, { styled } from "twin.macro";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import type { TOrientation, TSize } from "@/types/common";

const stylesSvgBreakpoints = {
  small: tw`w-[1.25rem] h-[1.5rem]`,
  medium: tw`w-[1.5rem] h-[1.8rem]`,
  large: tw`w-[1.5rem] h-[1.8rem]`,
};

export const StyledWrapperText = styled.div<{ $size?: TSize; $textDirection?: TOrientation }>`
  ${tw`flex gap-xxs items-baseline medium:(flex-col items-start w-full)`}

  ${({ $textDirection }) => {
    if ($textDirection === "horizontal") {
      return tw`!(flex-row items-baseline )`;
    }
    if ($textDirection === "vertical") {
      return tw`!(flex-col items-start)`;
    }
  }}
`;

const StyledWrapperCiityBreakpoints = {
  small: tw`gap-xs p-[2px] rounded-s`,
  medium: tw`gap-4 p-xs rounded-m`,
  large: tw`gap-4 p-s rounded-lg`,
};

export const StyledWrapperCiity = styled.button<{ $size?: TSize; $fullWidth?: boolean }>`
  ${tw`flex items-center transition-all duration-300 ease-in-out cursor-pointer rounded-s relative w-max
    hover:(bg-neutral-100 shadow-md shadow-neutral-80)
  `}

  ${({ $fullWidth }) => {
    if ($fullWidth) {
      return tw`w-full`;
    }
  }}


  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: StyledWrapperCiityBreakpoints, size: $size })}

    & > svg {
    ${tw`flex flex-col justify-center items-center`}
    ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: stylesSvgBreakpoints, size: $size })}
  }
`;
