import type { CSSProperties } from "react";
import tw, { styled, css, theme, type TwStyle } from "twin.macro";
import { type ITypographyStyledCommon } from "@/types/common";

const paragraphStyles = {
  small: {
    sm: tw`text-[0.75rem] leading-[1.035rem]`,
    md: tw`text-[1rem] leading-[1.38rem]`,
    lg: tw`text-[1.125rem] leading-[1.898rem]`,
  },
  medium: {
    sm: tw`text-[0.8125rem] leading-[1.12125rem]`,
    md: tw`text-[1.0625rem] leading-[1.46625rem]`,
    lg: tw`text-[1.25rem] leading-[1.725rem]`,
  },
  large: {
    sm: tw`text-[0.875rem] leading-[1.2075rem]`,
    md: tw`text-[1.125rem] leading-[1.5525rem]`,
    lg: tw`text-[1.375rem] leading-[1.553rem]`,
  },
};

export const StyledWrapperParagraph = styled.p<
  ITypographyStyledCommon & {
    $fontWeight?: CSSProperties["fontWeight"];
    $wordBreak: CSSProperties["wordBreak"];
    classes?: TwStyle;
    $disabled?: boolean;
    $withHover?: boolean;
  }
>`
  ${tw`font-primaryV2 text-neutral-10 transition-all duration-300 ease-in-out
  `}
  ${({ classes }) => classes}
  & > * {
    ${tw`[font-family: inherit] font-[inherit] [color: inherit]`};
  }

  ${({ $sizeVariant, $size }) => {
    if ($size) {
      return paragraphStyles[$size][$sizeVariant];
    }

    return css`
      ${paragraphStyles.small[$sizeVariant]}

      @media (min-width: ${theme`screens.medium`}) {
        ${paragraphStyles.medium[$sizeVariant]}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${paragraphStyles.large[$sizeVariant]}
      }
    `;
  }}

  ${({ $sizeVariant }) => ($sizeVariant === "lg" ? tw`font-medium` : tw`font-normal`)};

  ${({ $color }) => {
    if ($color) {
      return css`
        color: ${$color};
      `;
    }
  }};

  ${({ $fontWeight }) => {
    if ($fontWeight) {
      return css`
        font-weight: ${$fontWeight};
      `;
    }
  }};

  ${({ $wordBreak }) => {
    if ($wordBreak) {
      return css`
        word-break: ${$wordBreak};
      `;
    }
  }};

  ${({ $disabled }) => {
    if ($disabled) {
      return tw`text-neutral-60`;
    }
  }};

  ${({ $withHover }) => {
    if ($withHover) {
      return tw`hover:text-secondary-50`;
    }
  }};
`;
