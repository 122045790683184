import React, { type RefObject } from "react";
import { type CSSProp } from "styled-components";
import "twin.macro";
import { Button } from "../../../Button";

interface ModalFooterProps {
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
  PrimaryButtonConfig?: {
    buttonRef?: RefObject<HTMLButtonElement>;
    onClick?: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    type?: "reset" | "submit" | "button";
    css?: CSSProp<any>;
    content: React.ReactNode;
    id?: string;
  };
  SecondaryButtonConfig?: {
    buttonRef?: RefObject<HTMLButtonElement>;
    onClick?: () => void;
    isLoading?: boolean;
    isDisabled?: boolean;
    type?: "reset" | "submit" | "button";
    css?: CSSProp<any>;
    content: React.ReactNode;
    id?: string;
  };
  children?: React.ReactNode;
}

/**
 * @example
 * <ModalFooter
        showPrimaryButton={ true }
        showSecondaryButton={ true }
        PrimaryButtonConfig={ {
            isLoading: loading,
            isDisabled: !canAddAddress(),
            css: [ tw`w-full md:(w-6/12) ` ],
            type: "submit",
            id: "SelectCity",
            content: (
                <p tw="">
                    { storeType ? "Confirmar ciudad" : "Confirmar tienda" }
                </p>
            )
        } }
        SecondaryButtonConfig={ {
            css: [ tw`w-full md:(w-5/12)` ],
            onClick: userWithOutSession,
            type: "button",
            content: (
                <p tw="">
                        Iniciar sesión
                </p>
            )
        } }
    />
*/

export const ModalFooter = ({
  showPrimaryButton = false,
  showSecondaryButton = false,
  PrimaryButtonConfig,
  SecondaryButtonConfig,
  children,
}: ModalFooterProps): React.ReactElement => {
  return (
    <div tw="flex flex-col px-5 py-4 w-full border-t rounded-b-lg md:(py-4 px-5) bg-white">
      <div tw="flex flex-col space-y-2 w-full md:(flex-row space-x-2 space-y-0)">
        {showPrimaryButton && (
          <Button
            buttonRef={PrimaryButtonConfig?.buttonRef}
            onClick={PrimaryButtonConfig?.onClick}
            isLoading={PrimaryButtonConfig?.isLoading}
            isDisabled={PrimaryButtonConfig?.isDisabled}
            type={PrimaryButtonConfig?.type}
            id={PrimaryButtonConfig?.id}
            css={[PrimaryButtonConfig?.css]}
          >
            {PrimaryButtonConfig?.content}
          </Button>
        )}
        {showSecondaryButton && (
          <Button
            buttonRef={SecondaryButtonConfig?.buttonRef}
            onClick={SecondaryButtonConfig?.onClick}
            isLoading={SecondaryButtonConfig?.isLoading}
            isDisabled={SecondaryButtonConfig?.isDisabled}
            type={SecondaryButtonConfig?.type}
            id={SecondaryButtonConfig?.id}
            isSecondary={true}
            isPrimary={false}
            css={[SecondaryButtonConfig?.css]}
          >
            {SecondaryButtonConfig?.content}
          </Button>
        )}
      </div>
      {children}
    </div>
  );
};
