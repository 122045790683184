import tw, { styled } from "twin.macro";
import type { TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

interface ITextFieldStyled {
  $fullWidth: boolean;
  $hasValue: boolean;
  $isLoading: boolean;
  disabled?: boolean;
  $success?: boolean;
  $errors?: boolean;
  $size?: TSize;
}

const styles = {
  successErrorIcon: {
    base: tw`transition-all ease-in-out duration-300`,
    svg: tw`transition-all ease-in-out duration-300`,
    size: {
      small: tw`!(w-4 h-4)`,
      medium: tw`!(w-6 h-6)`,
      large: tw`!(w-6 h-6)`,
    },
    state: {
      error: tw`[&>svg>path]:fill-error-60`,
      success: tw`[&>svg>path]:fill-tertiary-60`,
    },
  },
  wrapperTextField: {
    base: tw`flex items-stretch border transition-all duration-300 ease-in-out cursor-text gap-m`,
    size: {
      small: tw`p-s rounded-s`,
      medium: tw`p-sm rounded-m`,
      large: tw`p-4 rounded-lg`,
    },
    fullWidth: tw`w-full`,
    maxWidth: tw`w-max`,
    state: {
      error: tw`border-error-50! bg-error-99!`,
      success: tw`border-tertiary-60! bg-tertiary-99!`,
      hasValue: tw`border-neutral-40 bg-neutral-99`,
      loading: tw`border-neutral-40 bg-neutral-90 pointer-events-none cursor-not-allowed`,
      disabled: tw`border-neutral-80! bg-neutral-99!`,
      default: tw`border-neutral-85 bg-neutral-100`,
    },
    input: {
      base: tw`w-full bg-transparent outline-none text-neutral-10 leading-normal
        placeholder:(text-neutral-50)
      `,
      size: {
        small: tw`text-[0.875rem]`,
        medium: tw`text-[1rem]`,
        large: tw`text-[1.125rem]`,
      },
    },
    hover: tw`border-neutral-30 bg-neutral-99`,
  },

  icon: {
    base: tw`transition-all ease-in-out duration-300`,
    size: {
      small: tw`w-5 h-5`,
      medium: tw`w-6 h-6`,
      large: tw`w-8 h-8`,
    },
  },
};

export const StyledIcon = styled.span<{ $size?: TSize }>`
  & > svg {
    ${styles.icon.base}

    ${({ $size }) => applyResponsiveStyles({ size: $size, stylesBreakpoints: styles.icon.size })}
  }
`;

export const StyledSuccessErrorIcon = styled.span<{ $success?: boolean; $errors?: boolean; $size?: TSize }>`
  ${styles.successErrorIcon.base}

  & > svg {
    ${styles.successErrorIcon.svg}

    ${({ $size }) => applyResponsiveStyles({ size: $size, stylesBreakpoints: styles.successErrorIcon.size })}
  }

  ${({ $success, $errors }) => {
    if ($errors) return styles.successErrorIcon.state.error;
    if ($success) return styles.successErrorIcon.state.success;
  }};
`;

export const StyledWrapperTextField = styled.div<ITextFieldStyled>`
  ${styles.wrapperTextField.base}

  ${({ $size }) => applyResponsiveStyles({ size: $size, stylesBreakpoints: styles.wrapperTextField.size })}

  ${({ $fullWidth }) => ($fullWidth ? styles.wrapperTextField.fullWidth : styles.wrapperTextField.maxWidth)};

  ${({ $hasValue, $isLoading, $success, $errors, disabled }) => {
    if ($errors && !disabled) return styles.wrapperTextField.state.error;
    if ($success && !disabled) return styles.wrapperTextField.state.success;
    if ($hasValue && !disabled) return styles.wrapperTextField.state.hasValue;
    if ($isLoading && !disabled) return styles.wrapperTextField.state.loading;
    if (disabled) return styles.wrapperTextField.state.disabled;
    return styles.wrapperTextField.state.default;
  }}

  & > input {
    ${styles.wrapperTextField.input.base}

    ${({ $size }) =>
      applyResponsiveStyles({ size: $size, stylesBreakpoints: styles.wrapperTextField.input.size })}
  }

  &:focus,
  &:hover {
    ${styles.wrapperTextField.hover};
  }
`;
