import React from "react";
import "twin.macro";

export const X = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      id="112dsaq"
      fill={props.fill || "#F96800"}
      d="M18.7 17.3c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L12 13.4l-5.3 5.3c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4l5.3-5.3-5.3-5.3c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4L13.4 12l5.3 5.3z"
    />
  </svg>
);
