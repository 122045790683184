import React from "react";
import "twin.macro";

export const HighFive = (): React.ReactElement => (
  <svg tw="fill-current" width={53} height={62} viewBox="0 0 53 62">
    <g fillRule="nonzero">
      <path d="M52.258 44.714l-9.182-6.824-6.389-19.935a6.306 6.306 0 00-6.024-4.395 6.308 6.308 0 00-4.526 1.912 6.308 6.308 0 00-4.526-1.912 6.307 6.307 0 00-6.024 4.395L9.198 37.89.016 44.714l12.745 16.68 13.376-10.089 13.376 10.09 12.745-16.68zm-27.92 3.44L13.45 56.366 5.092 45.427l7.182-5.337 6.742-21.036a2.717 2.717 0 012.595-1.894 2.73 2.73 0 012.726 2.726v9.65a5.431 5.431 0 00-1.837-.32 5.444 5.444 0 00-5.438 5.437v9.91h3.6v-9.91c0-1.012.824-1.836 1.838-1.836 1.013 0 1.837.824 1.837 1.836v13.5zm3.6 0v-13.5a1.838 1.838 0 013.674-.001v9.91h3.6v-9.91a5.442 5.442 0 00-7.275-5.116v-9.651a2.73 2.73 0 012.726-2.726c1.19 0 2.233.761 2.596 1.894L40 40.089l7.182 5.338-8.358 10.939-10.888-8.212zM24.337 0h3.6v6.4h-3.6zM14.448 6.044L16.85 3.36l4.768 4.27-2.402 2.682zM30.65 7.604l4.77-4.269 2.4 2.682-4.768 4.27z" />
    </g>
  </svg>
);
