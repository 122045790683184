import http from "@/utils/http";
import { BASE_API_URL } from "@/recourses/constants";
import type { IGetAvailableProductsResponse } from "./type";

export const getCartAvailableProducts = async (
  storeId: number,
  productsId: string
): Promise<IGetAvailableProductsResponse | undefined> => {
  try {
    const response = await http.get(
      `${BASE_API_URL}api/v1/stores/${storeId}/available-products/?products=${productsId}
`,
      { isAuth: false }
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
