import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

import tw, { styled, type TwStyle } from "twin.macro";

interface ResponsiveStyles {
  $size?: TSize;
  $hMax?: boolean;
}
interface StyledNotificationBoxProps {
  visible: boolean;
  $size?: TSize;
}

const styles = {
  wrapperStepsModal: {
    size: {
      small: tw`grid grid-rows-[40%_60%] relative  `,
      medium: tw`flex  max-w-4xl`,
      large: tw`flex  max-w-4xl`,
    },
  },
  wrapperRight: {
    size: {
      small: tw`px-m py-s  h-full`,
      large: tw`grid h-full gap-m py-ml pl-m pr-ml`,
    },
  },

  actionsWrapper: {
    size: {
      small: tw` self-end gap-s`,
      large: tw` self-auto gap-m`,
    },
  },

  primaryActionsWrapper: {
    size: {
      small: tw` flex-col justify-between items-center gap-m row-start-2`,
      medium: tw`flex flex-col justify-between items-center row-start-1`,
      large: tw` flex flex-col justify-between items-center row-start-1 `,
    },
  },
  wrapperFooterModal: {
    size: {
      small: tw` justify-between items-center  p-m  bg-neutral-99 mt-6 border-t-0 -ml-m -mr-m -mb-s`,
      large: tw` justify-between items-center p-0  pt-s  border-t bg-inherit m-0 `,
    },
  },
  notificationBox: {
    size: {
      small: tw`[min-height:2.686rem] `,
      large: tw`[min-height:3.246rem] `,
      medium: tw`[min-height:1.839rem]`,
    },
  },
  wrapperForm: {
    size: {
      small: tw`px-m py-s  h-full`,
      large: tw`grid h-full gap-m py-ml pl-m pr-ml`,
    },
  },
};
export const StyledWrapperStepsModal = styled.div<ResponsiveStyles>`
  ${tw`flex max-w-4xl`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.wrapperStepsModal.size,
    })}
`;

export const StyledWrapperRight = styled.div<ResponsiveStyles>`
  ${tw`grid h-full gap-m py-ml pl-m pr-ml`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.wrapperRight.size,
    })}
      ${({ $hMax }) => $hMax && tw`h-max `}
`;

export const StyledWrapperContent = styled.div<{ classes?: TwStyle }>`
  ${tw`grid gap-m h-max`}
  ${({ classes }) => classes}
`;

export const StyledWrapperFooterModal = styled.div<ResponsiveStyles>`
  ${tw`flex justify-between items-center  pt-s border-t 
    `}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.wrapperFooterModal.size,
    })}
`;
export const StyledBodyInfoModal = styled.div`
  ${tw`flex flex-col gap-s`}
`;

export const StyledNotificationBox = styled.div<StyledNotificationBoxProps>`
  ${tw` [min-height:2.686rem] opacity-0`}
  ${({ visible }) => visible && tw`opacity-100`}

    ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.notificationBox.size,
    })}
`;

export const StyledActionsWrapper = styled.div<ResponsiveStyles>`
  ${tw`self-auto grid`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.actionsWrapper.size,
    })}
`;

export const StyledPrimaryActionsWrapper = styled.div<ResponsiveStyles>`
  ${tw`flex flex-row justify-between items-center`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.primaryActionsWrapper.size,
    })}
`;

export const StyledWrapperForm = styled.form<ResponsiveStyles>`
  ${tw`grid h-full gap-m py-ml pl-m pr-ml`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.wrapperForm.size,
    })}
`;
