import Modal from "@/components/Atoms/Modal";
import { StyledIconTuner, StyledWrapperModalInformativeChangeLocation } from "./styles";
import Button from "@/components/Atoms/Button";
import tw from "twin.macro";
import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";

interface ModalInformativeChangeLocationProps {
  onClose: () => void;
  open: boolean;
}

const ModalInformativeChangeLocation = ({ open, onClose }: ModalInformativeChangeLocationProps) => {
  return (
    <Modal
      handleClose={onClose}
      isOpen={open}
      classes={{
        backdrop: tw`px-2`,
        card: tw`flex flex-col gap-ml overflow-visible w-full medium:(max-w-[29.5rem]) lg:(max-w-[34.5rem])`,
      }}
    >
      <StyledWrapperModalInformativeChangeLocation>
        <StyledIconTuner />
        <Heading type="h3">Ten en cuenta</Heading>

        <Paragraph sizeVariant="md">
          Al cambiar de ciudad, los valores de los productos y promociones pueden variar.
        </Paragraph>
      </StyledWrapperModalInformativeChangeLocation>

      <Button sizeVariant="lg" color="secondary" fullWidth onClick={onClose}>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalInformativeChangeLocation;
