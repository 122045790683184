import IconSmileFaces from "@components/Icons/V2/Illustration/smileyFaces.svg?react";
import Paragraph from "@/components/Atoms/Paragraph";
import Heading from "@/components/Atoms/Heading";
import { theme } from "twin.macro";
import {
  StyledHearAboutUsCardContent,
  StyledHearAboutUsCardRoot,
  StyledHearAboutUsCardRow,
  StyledIconClose,
  StyledUnderlinedText,
} from "./styles";

interface HearAboutUsCardProps {
  type: "informative" | "interactive";
  onClick?: () => void;
  onRemove?: () => void;
}
const HearAboutUsCard = ({ type = "interactive", onClick, onRemove }: HearAboutUsCardProps) => {
  return (
    <StyledHearAboutUsCardRoot $withRadius={type === "interactive"}>
      <IconSmileFaces width={60} height={58.11} />

      <StyledHearAboutUsCardContent>
        {type === "informative" && (
          <Heading type="h4" tw="font-bold" color={theme`colors.neutral.100`}>
            Evolucionemos los hábitos de más personas.
          </Heading>
        )}
        {type === "interactive" && (
          <StyledHearAboutUsCardRow>
            <Paragraph sizeVariant="md" color={theme`colors.neutral.100`}>
              Evolucionemos los hábitos de más personas.
            </Paragraph>
            <StyledIconClose onClick={onRemove} />
          </StyledHearAboutUsCardRow>
        )}
        {type === "interactive" && (
          <Heading type="h6" color={theme`colors.neutral.100`} tw="font-bold">
            Cuéntanos <StyledUnderlinedText onClick={onClick}>¿Cómo nos conociste?</StyledUnderlinedText>
          </Heading>
        )}
      </StyledHearAboutUsCardContent>
    </StyledHearAboutUsCardRoot>
  );
};

export default HearAboutUsCard;
