import styled, { css, type CSSProperties } from "styled-components";
import TextField, { type ITextFieldProps } from "@/components/Atoms/TextField";

interface StyledSelectInputProps extends ITextFieldProps {
  $cursor?: CSSProperties["cursor"];
}

export const StyledSelectInput = styled(TextField)<StyledSelectInputProps>`
  ${({ $cursor }) => css`
    cursor: ${$cursor ?? "text"};
  `}
`;
