import useSessionContext from "@/hooks/useSesionContext";
import { userAction } from "@/store/user";
import { cartStoresActions } from "@/store/cartStore";
import useAppDispatch from "@/hooks/useAppDispatch";
import Analytics from "@/utils/Analytics";
import { useQueryCache } from "react-query";
import { popupsRemunderAction } from "@/store/PopUpsReminderSlice";
import Cookies from "js-cookie";

/**
 * This hook logs out the user.
 * @returns a function that logs out the user.
 */
export const useLogout = () => {
  const { setUserToken } = useSessionContext();
  const dispatch = useAppDispatch();
  const queryCache = useQueryCache();

  const logout = () => {
    setUserToken(null);
    dispatch(userAction.emptyUser());
    dispatch(cartStoresActions.setInitialLocation());
    dispatch(cartStoresActions.emptyCart({ emptyStore: true }));
    dispatch(cartStoresActions.emptyAvailability());
    void queryCache.invalidateQueries("addresses");
    dispatch(popupsRemunderAction.emptyPopUpsReminder());
    dispatch(cartStoresActions.emptyLastUsedAddresses());
    Analytics.Reset();
    Cookies.remove("authTokenPlus");
  };
  return logout;
};
