import tw, { styled } from "twin.macro";

const baseStyles = tw`flex flex-col gap-m pt-m bg-neutral-99 p-m shadow-[inset_0px_4px_4px_0px_rgba(0,0,0,0.10)]`;

export const StyledCityFeedbackForm = styled.form`
  ${baseStyles}
`;

export const StyledCityFeedbackSuccess = styled.div`
  ${baseStyles}
  ${tw`items-center gap-m`}

  & > svg {
    ${tw`w-[9.375rem] h-[6.938rem]`}
  }
`;
