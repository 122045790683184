import { useState, useEffect } from "react";
import { useTypedSelector } from "../store";

/**
 * Will be fix when react-scripts updates its typescript-eslint deps
 * @see https://github.com/typescript-eslint/typescript-eslint/issues/2260#issuecomment-680197439
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useMembership = () => {
  const userInfo = useTypedSelector((state) => state.user);
  const ActiveMemebershipStates = ["active"];
  const [hasMembership, setHasMembership] = useState<boolean>(
    userInfo?.membership ? ActiveMemebershipStates.includes(userInfo?.membership.status) : false
  );
  const [membershipInfo, setMembershipInfo] = useState<Membership | null>(
    userInfo?.membership ? userInfo?.membership : null
  );

  useEffect(() => {
    setHasMembership(
      userInfo?.membership ? ActiveMemebershipStates.includes(userInfo?.membership.status) : false
    );
    setMembershipInfo(userInfo?.membership ? userInfo?.membership : null);
    // eslint-disable-next-line
  }, [userInfo]);

  return [hasMembership, membershipInfo] as const;
};
