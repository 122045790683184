import "twin.macro";
import { StyledCard, StyledLabel, StyledLoaderRoot, StyledLogo } from "./styles";

const HubLoader = (props: Record<string, any>) => {
  return (
    <StyledLoaderRoot {...props}>
      <StyledCard>
        <StyledLogo />

        <StyledLabel />
      </StyledCard>
    </StyledLoaderRoot>
  );
};

export default HubLoader;
