import { useState, useEffect } from "react";
import { useTypedSelector } from "../store";

/**
 * Will be fix when react-scripts updates its typescript-eslint deps
 * @see https://github.com/typescript-eslint/typescript-eslint/issues/2260#issuecomment-680197439
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useFlash = () => {
  const availableStores = useTypedSelector((state) => state.cart).availableStores;
  const [isFlash, setIsFlash] = useState<boolean>(
    availableStores?.stores[0] ? availableStores?.stores[0].has_turbo_service : false
  );

  useEffect(() => {
    setIsFlash(availableStores?.stores[0] ? availableStores?.stores[0].has_turbo_service : false);
  }, [availableStores]);

  const updateIsFlash = () =>
    setIsFlash(availableStores?.stores[0] ? availableStores?.stores[0].has_turbo_service : false);
  return [isFlash, updateIsFlash] as const;
};
