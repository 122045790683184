import DigitCode from "../DigitCode";
import Label from "@/components/Atoms/Label";
import { StyledConfirmCodeController } from "./styles";
import { type Control, Controller } from "custom-hook-form";

export interface FormDataConfirmCode {
  code: string;
}

interface ConfirmCodeControllerProps {
  controller: {
    control: Control<FormDataConfirmCode>;
  };
  length?: number;
  label?: string;
}

const ConfirmCodeController = ({ controller, length = 4, label }: ConfirmCodeControllerProps) => {
  const { control } = controller;

  return (
    <StyledConfirmCodeController>
      {label && <Label sizeVariant="md">{label}</Label>}
      <Controller
        name="code"
        defaultValue=""
        control={control}
        rules={{
          validate: (value: string): boolean => value.replace(/\s/g, "").length === length,
          required: true,
          maxLength: length,
          minLength: length,
        }}
        render={({ field: { onChange, value } }) => (
          <DigitCode onChange={onChange} value={value} length={length} />
        )}
      />
    </StyledConfirmCodeController>
  );
};

export default ConfirmCodeController;
