import { useState } from "react";
import type { TSize } from "@/types/common";
import IconUser from "@iconsV2/Users/user-circle.svg?react";

import Avatar from "@components/Atoms/Avatar";
import Button from "@components/Atoms/Button";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import useSessionContext from "@/hooks/useSesionContext";

import ProfileMenu from "../ProfileMenu";
import {
  StyledIconChevronDown,
  StyledText,
  StyledWrapperProfileButton,
  StyledWrapperProfileButtonNoAuth,
} from "./styles";

interface IProfileButtonProps {
  label: string;
  firstName?: string;
  size?: TSize;
  onClickLogin: () => void;
  [key: string]: any;
}

const ProfileButton = ({ label, firstName, size, onClickLogin, ...props }: IProfileButtonProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const showFirstName = useMediaQuery("( min-width: 400px )");
  const { isAthenticated } = useSessionContext();

  const handleOpenMenu = (open: boolean) => {
    setOpenMenu(open);
  };

  if (!isAthenticated()) {
    return (
      <StyledWrapperProfileButtonNoAuth>
        <Button
          sizeVariant="lg"
          color="secondary"
          variant="outlined"
          onClick={onClickLogin}
          endIcon={isMedium && <IconUser tw="fill-secondary-50" />}
          // {...props}
        >
          Ingresar
        </Button>
      </StyledWrapperProfileButtonNoAuth>
    );
  }

  return (
    <ProfileMenu nameUser={label} openMenu={openMenu} handleOpenMenu={handleOpenMenu}>
      <StyledWrapperProfileButton
        $withLabel={Boolean(showFirstName && firstName)}
        $size={size}
        onClick={() => handleOpenMenu(!openMenu)}
        {...props}
      >
        <Avatar label={label} size={size} />
        {showFirstName && firstName && (
          <StyledText sizeVariant={isMedium ? "sm" : "md"} size={size}>
            {firstName}
          </StyledText>
        )}
        {isMedium && firstName && <StyledIconChevronDown $open={openMenu} />}
      </StyledWrapperProfileButton>
    </ProfileMenu>
  );
};

export default ProfileButton;
