import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { emptyStatePopUpsReminder, initialStatePopUpsReminder } from "./states";
import type { CBPlusNotificationtype, PopUpsReminder } from "./types";
export type { CBPlusNotificationtype, PopUpsReminder };

export const PopUpsReminderSlice = createSlice({
  name: "PopUpsReminder",
  initialState: initialStatePopUpsReminder,
  reducers: {
    setPopUpsReminder: (
      state: PopUpsReminder,
      action: PayloadAction<Partial<PopUpsReminder>>
    ): PopUpsReminder => {
      return Object.assign(state, action.payload);
    },
    emptyPopUpsReminder: (): PopUpsReminder => {
      return {
        ...emptyStatePopUpsReminder,
      };
    },
  },
});

export const popupsRemunderAction = PopUpsReminderSlice.actions;
