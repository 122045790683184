import Input from "@/components/Molecules/Input";
import IconEye from "@/components/Icons/V2/Interrface/eye.svg?react";
import IconEyeOff from "@/components/Icons/V2/Interrface/eye-off.svg?react";
import { useState, forwardRef, type ChangeEvent } from "react";
import { type TSize } from "@/types/common";

export interface TogglePasswordInputProps {
  placeHolder: string;
  errors?: boolean;
  label: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: TSize;
  value?: string;
  textHelp?: string;
  name?: string;
}
const TogglePasswordInput = forwardRef<HTMLInputElement, TogglePasswordInputProps>(
  ({ placeHolder, errors, label, onChange, textHelp, value, size, name, ...rest }, ref) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    function handlePasswordVisibility() {
      setIsPasswordVisible(!isPasswordVisible);
    }

    return (
      <Input
        textHelp={textHelp}
        value={value}
        size={size}
        onChange={onChange}
        label={label}
        placeholder={placeHolder}
        type={isPasswordVisible ? "text" : "password"}
        name={name ?? "password"}
        ref={ref}
        errors={errors}
        endIcon={
          <span onClick={handlePasswordVisibility} style={{ cursor: "pointer" }}>
            {!isPasswordVisible && <IconEye fontSize={24} />}
            {isPasswordVisible && <IconEyeOff fontSize={24} />}
          </span>
        }
        {...rest}
      />
    );
  }
);

export default TogglePasswordInput;
