import IconUserCircle from "@/components/Icons/V2/Users/user-circle.svg?react";
import IconExit from "@/components/Icons/V2/Interrface/log-out-primary.svg?react";
import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import tw, { styled } from "twin.macro";

interface StyledIconProps {
  $size?: TSize;
}

const styles = {
  iconUser: {
    size: {
      small: tw`w-[3.125rem ] h-[3.125rem]`,
      medium: tw`h-10 w-10`,
      large: tw`h-[3.375rem] w-[3.375rem]`,
    },
  },
};

export const StyledIconUser = styled(IconUserCircle)<StyledIconProps>`
  ${tw`h-[54px] w-[54px]`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.iconUser.size,
    })}
`;

export const StyledExit = styled(IconExit)<StyledIconProps>`
  ${tw`h-[54px] w-[54px]`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.iconUser.size,
    })}
`;
