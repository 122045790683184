import tw, { styled } from "twin.macro";

export const StyledWrapperButtonOrder = styled.div<{ $withShadow: boolean; $isScrolled: boolean }>`
  ${tw`relative mr-3`}

  & > button {
    ${tw`h-[2rem] w-[2rem] medium:(h-[2.75rem] w-[2.75rem]) large:(h-[3rem] w-[3rem])`}

    ${({ $isScrolled }) => {
      if ($isScrolled) {
        return tw`medium:(h-[2.25rem] w-[2.25rem]) large:(h-[2.5rem] w-[2.5rem])`;
      }
    }}

    ${({ $withShadow }) => {
      if ($withShadow) return tw`shadow-elevation-2`;
    }}

    & svg {
      ${tw`w-[1.125rem] h-[1.125rem] medium:(w-[1.5rem] h-[1.5rem])`}
    }
  }
`;

export const StyledCounter = styled.span`
  ${tw`absolute  bg-error-50 border-2 border-error-100 rounded-round p-xxxs h-[1.125rem] w-[1.125rem] flex items-center justify-center -top-0 -right-3
    medium:(w-5 h-5)
    large:(p-xs w-6 h-6)
  `}
`;
