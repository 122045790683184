import type { CSSProperties } from "react";
import tw, { styled, css, theme } from "twin.macro";
import type { ITypographyStyledCommon } from "@/types/common";

const ButtonTextStyles = {
  small: {
    sm: tw`text-[0.6875rem]`,
    md: tw`text-[0.875rem]`,
    lg: tw`text-[1rem]`,
  },
  medium: {
    sm: tw`text-[0.875rem]`,
    md: tw`text-[1rem]`,
    lg: tw`text-[1.125rem]`,
  },
  large: {
    sm: tw`text-[1rem]`,
    md: tw`text-[1.125rem]`,
    lg: tw`text-[1.25rem]`,
  },
};

export const StyledWrapperButtonText = styled.p<
  ITypographyStyledCommon & { $fontWeight?: CSSProperties["fontWeight"] }
>`
  ${tw`font-primaryV2 transition-all duration-300 ease-in-out leading-[normal] font-medium`}

  & > * {
    ${tw`[font-family: inherit] font-[inherit] [color: inherit]`};
  }

  ${({ $sizeVariant, $size }) => {
    if ($size) {
      return ButtonTextStyles[$size][$sizeVariant];
    }

    return css`
      ${ButtonTextStyles.small[$sizeVariant]}

      @media (min-width: ${theme`screens.medium`}) {
        ${ButtonTextStyles.medium[$sizeVariant]}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${ButtonTextStyles.large[$sizeVariant]}
      }
    `;
  }}

  ${({ $color }) => {
    if ($color) {
      return css`
        color: ${$color};
      `;
    }
    return tw`text-neutral-10`;
  }};

  ${({ $fontWeight }) => {
    if ($fontWeight) {
      return css`
        font-weight: ${$fontWeight};
      `;
    }
  }};
`;
