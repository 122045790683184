import Button, { type IButton } from "@components/Atoms/Button";
import IconCart from "@iconsV2/Interrface/cart-01.svg?react";
import Caption from "@/components/Atoms/Caption";
import { StyledCounter, StyledWrapperButtonOrder } from "./styles";
import { theme } from "twin.macro";

const OrderButton = ({
  counter = 0,
  sizeVariant,
  isScrolled = false,
  ...props
}: IButton & { counter: number; isScrolled?: boolean }) => {
  return (
    <StyledWrapperButtonOrder $withShadow={false} $isScrolled={isScrolled}>
      <Button sizeVariant={sizeVariant} startIcon={<IconCart />} {...props} />

      {counter > 0 && (
        <StyledCounter>
          <Caption color={theme`colors.error.100`} fontWeight={700}>
            {counter > 99 ? "99" : counter}
          </Caption>
        </StyledCounter>
      )}
    </StyledWrapperButtonOrder>
  );
};

export default OrderButton;
