import Modal from "@/components/Atoms/Modal";

import Select, { type OptionSelect, type SelectProps } from "@components/Molecules/Select";
import HearAboutUsCard from "@/components/Molecules/HearAboutUsCard";
import Button from "@/components/Atoms/Button";
import tw from "twin.macro";
import { StyledModalHearAboutUsProfileEntry } from "./styles";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface ModalHearAboutUsProfileProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSelect: (select: OptionSelect | null) => void;
  handleSubmit: () => void;
  isSubmitDisabled: boolean;
  options: SelectProps["options"];
}

const ModalHearAboutUsProfile = ({
  isOpen,
  handleClose,
  handleSelect,
  handleSubmit,
  isSubmitDisabled,
  options,
}: ModalHearAboutUsProfileProps) => {
  const isMedium = useMediaQuery("(min-width: {medium})");
  return (
    <Modal
      showCenter={!isMedium}
      closeOutSise
      isOpen={isOpen}
      handleClose={handleClose}
      classes={{
        backdrop: tw`overflow-auto z-[52]`,
        card: tw`w-[92%] max-w-md sm:max-w-max flex flex-col gap-m p-0 pt-m`,
      }}
    >
      <IconClose width={24} height={25} tw="cursor-pointer self-end mr-m" onClick={handleClose} />
      <HearAboutUsCard type="informative" />
      <StyledModalHearAboutUsProfileEntry>
        <Select label="Cuéntanos, ¿Dónde nos conociste?" onChange={handleSelect} options={options || []} />
        <Button sizeVariant="lg" color="secondary" onClick={handleSubmit} disabled={isSubmitDisabled}>
          Enviar
        </Button>
      </StyledModalHearAboutUsProfileEntry>
    </Modal>
  );
};
export default ModalHearAboutUsProfile;
