import tw, { styled } from "twin.macro";

export const StyledFormSecurity = styled.form`
  ${tw`flex flex-col gap-m h-full `}
`;

export const StyledFormSecurityDetail = styled.div`
  ${tw`flex flex-col gap-m `}
`;
export const StyledAction = styled.div`
  ${tw`flex flex-col   gap-l grow  justify-end`}
`;
export const StyledTypeCodeActions = styled.div`
  ${tw`flex flex-col gap-m`}
`;
