import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import tw, { styled } from "twin.macro";

interface ResponsiveStyles {
  $size?: TSize;
}
const styles = {
  resendCodeContainer: {
    size: {
      small: tw` -mx-m bg-neutral-99 mt-6  p-m`,
      large: tw`flex flex-col items-start gap-m bg-inherit m-0 p-0 border-t pt-m`,
    },
  },
};

export const SyledResendCodeContainer = styled.div<ResponsiveStyles>`
  ${tw`flex flex-col items-start gap-m`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.resendCodeContainer.size,
    })}
`;
