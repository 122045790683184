import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import Input from "@/components/Molecules/Input";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import IconAuth from "@/components/Atoms/IconAuth";
import TogglePasswordInput from "@/components/Atoms/TogglePasswordInput";
import Button from "@/components/Atoms/Button";
import Notification from "@/components/Atoms/Notification";
import {
  StyledActionsWrapper,
  StyledBodyInfoModal,
  StyledNotificationBox,
  StyledPrimaryActionsWrapper,
  StyledWrapperContent,
  StyledWrapperForm,
} from "@/components/Organisms/AuthStepsModal/styles";

import { useForm } from "custom-hook-form";
import StepOrBackHeader from "@/components/Atoms/StepOrBackHeader/inde";
import IconChevronLeft from "@/components/Icons/V2/Arrows/chevron-left-normal.svg?react";

export interface SignUpUserInfoData {
  email: string;
  password: string;
}

interface SignUpUserInfoProps {
  onClose: () => void;
  onSubmit: (data: SignUpUserInfoData) => void;
  backStep: () => void;
}

const reg =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const regOficial =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const SignUpUserInfo = ({ onClose, onSubmit, backStep }: SignUpUserInfoProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpUserInfoData>({
    mode: "onSubmit",
  });

  const isMobile = useMediaQuery("(max-width:{md})");
  const isLarge = useMediaQuery("(min-width: {large})");

  const fetchingError = [];

  return (
    <StyledWrapperForm onSubmit={handleSubmit(onSubmit)}>
      <StyledWrapperContent>
        {!isMobile && (
          <div tw="flex items-center justify-between">
            <Button
              sizeVariant="sm"
              color="secondary"
              variant="text"
              startIcon={<IconChevronLeft />}
              tw="p-0"
              type="button"
              onClick={backStep}
            >
              Regresar
            </Button>
            <IconClose tw="h-ml w-ml justify-self-end cursor-pointer" onClick={onClose} />
          </div>
        )}
        {isMobile && <StepOrBackHeader onClose={onClose} onBack={backStep} />}
        <IconAuth type="user" />
        <StyledBodyInfoModal>
          <Heading type="h2">Bienvenido</Heading>
          <Paragraph sizeVariant="md">
            Estás a un paso de formar parte de la comunidad que evoluciona tus hábitos.
          </Paragraph>
        </StyledBodyInfoModal>

        <Input
          label="Correo electrónico"
          placeholder="Ingresa tu correo"
          type="email"
          size={isLarge ? "large" : "medium"}
          {...register("email", {
            required: "Tu correo es requerido",
            validate: (value: string): any =>
              (reg.test(value) && regOficial.test(value)) || "Aún no es un email valido",
          })}
          errors={Boolean(errors.email)}
          textHelp={errors.email?.message?.toString()}
        />

        <TogglePasswordInput
          label="Contraseña"
          placeHolder="Ingresa tu contraseña"
          size={isLarge ? "large" : "medium"}
          {...register("password", {
            minLength: { value: 8, message: "Al menos 8 caracteres" },
            required: "Tu contraseña es requerida",
          })}
          errors={Boolean(errors.password)}
          textHelp={errors.password?.message?.toString()}
        />
        {!isMobile && (
          <Paragraph sizeVariant="sm">
            Al dar clic en continuar, aceptas nuestros Términos y condiciones
          </Paragraph>
        )}
      </StyledWrapperContent>
      <StyledActionsWrapper>
        <StyledPrimaryActionsWrapper>
          <Button sizeVariant="lg" color="secondary" type="submit" fullWidth>
            Continuar
          </Button>
        </StyledPrimaryActionsWrapper>
        {isMobile && (
          <Paragraph sizeVariant="sm" tw="text-center ">
            Al dar clic en continuar, aceptas nuestros Términos y condiciones
          </Paragraph>
        )}
        <StyledNotificationBox visible={!!fetchingError && fetchingError.length > 0}>
          <Notification type="error" messages={fetchingError} hFull />
        </StyledNotificationBox>
      </StyledActionsWrapper>
    </StyledWrapperForm>
  );
};
export default SignUpUserInfo;
