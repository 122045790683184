import tw, { styled } from "twin.macro";
import type { AddDollarToKeys } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import type { IProductCardGallerySearchSkeleton } from ".";

const styles = {
  card: {
    sm: {
      horizontal: {
        small: tw`min-h-[5rem]`,
        medium: tw`min-h-[6rem] rounded-md`,
        large: tw`min-h-[7rem] rounded-md`,
      },
    },
  },
  figure: {
    sm: {
      horizontal: {
        small: tw`min-h-[3.4375rem]`,
        medium: tw`min-h-[4.375rem] rounded-md`,
        large: tw`min-h-[5rem] rounded-md`,
      },
    },
  },
  content: {
    sm: tw`p-1.5`,
  },
  text: {
    sm: { small: tw`h-3`, medium: tw`h-4`, large: tw`h-5` },
  },
  button: {
    sm: { small: tw`h-5`, medium: tw`h-5`, large: tw`h-5` },
  },
};

export const StyledFigure = styled.figure`
  ${tw`rounded-lg w-full h-full bg-primaryV2-80`}
`;

export const StyledWrapperFigure = styled.div`
  ${tw`h-full aspect-square p-1.5`}
`;

export const StyledWrapperContent = styled.div`
  ${tw`flex flex-col items-start justify-between self-stretch`}
`;

export const StyledWrapperContentText = styled.div`
  ${tw`flex flex-col items-start justify-between self-stretch max-w-[10rem] gap-2`}
`;

export const StyledWrapperTitle = styled.div`
  ${tw`bg-primaryV2-70 w-[55%]`}
`;

export const StyledWrapperDescription = styled.div`
  ${tw`bg-primaryV2-70 w-full`}
`;

export const StyledWrapperButton = styled.div`
  ${tw`bg-cta-shopping-default rounded-round h-5 w-full`}
`;

export const StyledWrapperCard = styled.div<AddDollarToKeys<IProductCardGallerySearchSkeleton>>`
  ${tw`grid border animate-pulse1 rounded-lg overflow-hidden grid-cols-[min-content_1fr]`}

  ${({ $size }) =>
    applyResponsiveStyles({
      stylesBreakpoints: styles.card.sm.horizontal,
      size: $size,
    })}

  & ${StyledWrapperContent} {
    ${styles.content.sm}
  }

  & ${StyledWrapperTitle}, & ${StyledWrapperDescription} {
    ${({ $size }) =>
      applyResponsiveStyles({
        stylesBreakpoints: styles.text.sm,
        size: $size,
      })}
  }

  & ${StyledWrapperButton} {
    ${({ $size }) =>
      applyResponsiveStyles({
        stylesBreakpoints: styles.button.sm,
        size: $size,
      })}
  }
`;
