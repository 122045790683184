import http from "@/utils/http";
import {
  MEILISEARCH_HOST_URL,
  MEILISEARCH_SEARCH_API_KEY,
  MEILISEARCH_STORE_PRODUCT_INDEX,
} from "../constants";

export interface ProductHit {
  product_sku: string;
  product_name: string;
  product_slug: string;
  product_brand: string;
  product_description: string;
  product_hubs: string[];
  store_categories: string[];
  product_tags: any[];
  product_barcode: null | string;
  id: number;
  store_id: number;
  store_name: string;
  store_type: string;
  store_type_display: string;
  price_physical_gross: number;
  price_online_gross: number;
  price_online_undiscounted_gross: number;
  price_rappi_gross: number;
  product_brand_slug: string;
  product_avg_rating: number;
  product_thumbnail_url: string;
  product_availability_max_qty_to_order: number;
  product_availability_currency: string;
  product_contains_alcohol: boolean;
  tax: number;
  price_online_membership_gross: number;
  price_app_gross: number;
  price_app_membership_gross: number;
  price_app_undiscounted_gross: number;
  is_available_online: boolean;
  is_available_app: boolean;
  is_private_label: number;
  product_availability_available_online: boolean;
  product_availability_available_app: boolean;
  product_availability_on_sale_online: boolean;
  product_availability_on_sale_app: boolean;
  product_availability_discount_online: number;
  product_availability_discount_app: number;
  custom_label: null | string;
  custom_label_background_color: string;
  price_per_measure_unit_display: string;
  is_new: boolean;
  is_promoted: number;
}

interface ISearchResponse {
  hits: ProductHit[];
  estimatedTotalHits: number;
  exhaustiveNbHits: boolean;
  query: string;
  limit: number;
  offset: number;
  processingTimeMs: number;
}

class MeiliSearch {
  private queryParams: string = "";
  private filterParams: string = "";
  private sortParams: string[] = [];
  private pageParams: number = 1;
  private limitParams: number = 15;
  private attributesParams: string[] = [];

  query(params: string): this {
    this.queryParams = params;
    return this;
  }

  filter(params: string): this {
    this.filterParams = params;
    return this;
  }

  sort(params: string[]): this {
    this.sortParams = params;
    return this;
  }

  page(numberPage: number): this {
    this.pageParams = numberPage > 0 ? numberPage : 1;
    return this;
  }

  limit(numberPerPage: number): this {
    this.limitParams = numberPerPage > 0 ? numberPerPage : 15;
    return this;
  }

  onlyAttributes(attributes: string[]): this {
    this.attributesParams = attributes;
    return this;
  }

  async search(): Promise<ISearchResponse> {
    const offset = (this.pageParams - 1) * this.limitParams;

    const obj: Record<string, any> = {
      q: this.queryParams || "",
      sort: this.sortParams,
      limit: this.limitParams,
      offset,
    };

    if (this.filterParams) {
      obj.filter = this.filterParams;
    }
    if (this.attributesParams.length > 0) {
      obj.attributesToRetrieve = this.attributesParams;
    }

    const response = await http.post(
      `${MEILISEARCH_HOST_URL}/indexes/${MEILISEARCH_STORE_PRODUCT_INDEX}/search`,
      obj,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${MEILISEARCH_SEARCH_API_KEY}`,
        },
        isAuth: true,
        isAuthExternal: true,
      }
    );

    return response.data;
  }
}

export default MeiliSearch;
