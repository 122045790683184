import tw, { styled } from "twin.macro";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import { ETypeNotification, type TVariantNotification } from ".";

const iconSize = {
  small: tw`w-m h-m`,
  medium: tw`h-[20px] w-[20px]`,
  large: tw`h-ml w-ml`,
};

const rootTypeStyles = {
  primary: tw`bg-secondary-50 shadow-purple`,
  info: tw`bg-neutral-99 border border-neutral-70 shadow-purple`,
  success: tw`bg-neutral-99 border border-tertiary-50 shadow-green`,
  error: tw`bg-neutral-99 border border-error-50 shadow-red`,
  warning: tw`bg-neutral-99 border border-primaryV2-50 shadow-orange`,
};

export const styles = {
  root: {
    base: tw`w-full py-s px-m flex flex-col gap-xxs medium:(px-ml)`,
  },
  header: {
    base: tw`flex justify-between gap-sm`,
  },
  startIcon: {
    size: iconSize,
  },
  iconClose: {
    size: iconSize,
  },
  body: {
    base: tw`w-full`,
  },
};

export const StyledWrapperNotification = styled.div<{
  $type: ETypeNotification;
  $variant: TVariantNotification;
}>`
  ${styles.root.base}

  ${({ $type = ETypeNotification.PRIMARY }) => rootTypeStyles[$type]}

  ${({ $variant }) => {
    if ($variant === "default") {
      return tw`rounded-s !p-xxs shadow-none`;
    }
  }}
`;

export const StyledHeaderNotification = styled.div<{
  $variant: TVariantNotification;
}>`
  ${styles.header.base}

  ${({ $variant }) => {
    if ($variant === "default") {
      return tw`items-center`;
    }
  }}
`;

export const StyledStartIcon = styled.div`
  & > svg {
    ${applyResponsiveStyles({
      stylesBreakpoints: styles.startIcon.size,
    })}
  }
`;

export const StyledIconClose = styled(IconClose)<{ $type: ETypeNotification }>`
  ${({ $type }) => ($type === ETypeNotification.PRIMARY ? tw`fill-secondary-100` : tw`fill-neutral-10`)}

  ${applyResponsiveStyles({
    stylesBreakpoints: styles.iconClose.size,
  })}
`;

export const StyledBodyNotification = styled.div`
  ${styles.body.base}
`;
