import tw, { css, styled } from "twin.macro";
import type { TSize } from "@/types/common";

import applyResponsiveStyles from "@/utils/applyResponsiveStyles";
import ButtonText from "@/components/Atoms/ButtonText";
import IconChevromDown from "@iconsV2/Arrow/chevron-bottom-normal.svg?react";

const styles = {
  wrapper: {
    base: tw`flex items-center justify-center bg-neutral-100 transition-all duration-300 ease-in-out text-neutral-50 whitespace-nowrap rounded-full px-[2px] border border-neutral-90`,
    size: {
      small: tw`h-[2rem]`,
      medium: tw`px-xxs h-[2.75rem]`,
      large: tw`px-[0.1875rem] h-[3rem]`,
    },
  },

  text: {
    base: tw`text-current transition-none`,
    size: {
      small: tw`ml-xxs mr-0`,
      medium: tw`ml-sm mr-xs`,
      large: tw`ml-sm mr-s`,
    },
  },

  iconChevronDown: {
    base: tw`transition-transform duration-300 ease-in-out transform`,
    size: {
      small: tw`w-[1.125rem] h-[1.125rem]`,
      medium: tw`w-5 h-5`,
      large: tw`w-6 h-6`,
    },
  },
};

export const StyledText = styled(ButtonText)`
  ${styles.text.base}

  ${({ size }) => applyResponsiveStyles({ stylesBreakpoints: styles.text.size, size })}
`;

export const StyledIconChevronDown = styled(IconChevromDown)<{ $size?: TSize; $open: boolean }>`
  ${styles.iconChevronDown.base}

  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.iconChevronDown.size, size: $size })}
  ${({ $open }) => $open && tw`rotate-180`}
  & path {
    ${tw`fill-current`}
  }
`;

export const StyledWrapperProfileButton = styled.button<{ $size?: TSize; $withLabel: boolean }>`
  ${styles.wrapper.base}

  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.wrapper.size, size: $size })}

  & > div:first-child {
    ${tw`w-[1.5rem] h-[1.5rem] medium:(w-[2rem] h-[2rem]) large:(w-[2.375rem] h-[2.375rem])`}
  }

  &:hover {
    ${tw`bg-neutral-99 border-neutral-40 text-neutral-0`}
  }

  &:active {
    ${tw`bg-neutral-99 border-neutral-85 text-neutral-40`}
  }

  ${({ $withLabel }) => {
    if (!$withLabel) {
      return css`
        ${tw`!p-0 border-none`}

        & > div:first-child {
          ${tw`w-[2rem] h-[2rem] medium:(w-[2.75rem] h-[2.75rem]) large:(w-[3rem] h-[3rem])`}
        }
      `;
    }
  }}
`;

export const StyledWrapperProfileButtonNoAuth = styled.div`
  & > button {
    ${tw`py-0 h-[2rem] medium:(h-[2.75rem]) large:(h-[3rem])`}

    & svg {
      ${tw`w-[1.5rem] h-[1.5rem] large:(w-[1.625rem] h-[1.625rem])`}
    }
  }
`;
