import { useCallback, useEffect, useState } from "react";

interface ScrollPositionProps {
  threshold: number;
  callback: () => void;
  enabled?: boolean;
  reset?: boolean;
}

/**
 * Custom hook to trigger a callback function when the scroll position reaches a specified threshold.
 * @param {ScrollPositionProps} props - The properties for the hook.
 * @param {number} props.threshold - The scroll percentage threshold at which the callback should be triggered.
 * @param {() => void} props.callback - The callback function to be executed when the threshold is reached.
 * @param {boolean} [props.enabled=true] - A boolean that determines if the hook should be active or not.
 * @param {boolean} [props.reset=false] - A boolean to reset the hook and allow it to trigger again.
 */
function useScrollPosition({ threshold, callback, enabled = true, reset = false }: ScrollPositionProps) {
  const [hasTriggered, setHasTriggered] = useState(false);

  const getScrollPercentage = useCallback(() => {
    const scrollTop = window.scrollY || document.documentElement.scrollTop;
    const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
    return (scrollTop / scrollHeight) * 100;
  }, []);

  useEffect(() => {
    setHasTriggered(false);
  }, [reset]);

  useEffect(() => {
    if (!enabled || hasTriggered) return;

    const checkScroll = () => {
      const currentScrollPercentage = getScrollPercentage();

      if (currentScrollPercentage >= threshold) {
        setHasTriggered(true);
        callback();
      }
    };

    window.addEventListener("scroll", checkScroll);
    checkScroll();

    return () => window.removeEventListener("scroll", checkScroll);
  }, [enabled, hasTriggered, threshold, callback, getScrollPercentage]);

  return hasTriggered;
}

export default useScrollPosition;
