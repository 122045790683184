const existProductCart = (items: Cart[], idSearch: string): boolean => {
  for (const { pk } of items) {
    if (String(pk) === String(idSearch)) return true;
  }
  return false;
};

const getQuantity = (items: Cart[], idSearch: string): number => {
  for (const { pk, quantity } of items) {
    if (pk === idSearch) return quantity;
  }
  return 0;
};

const getProductsCartTotal = (cart: Cart[]): number => {
  let sum = 0;
  for (const { availability, quantity } of cart) {
    sum += availability.priceUndiscounted.gross * quantity;
  }
  return sum;
};

const getProductsCartDiscount = (cart: Cart[], hasMembership: boolean = false): number => {
  let sum = 0;
  for (const { availability, quantity } of cart) {
    if (hasMembership && availability.priceMembership?.gross) {
      sum += (availability.priceUndiscounted.gross - availability.priceMembership?.gross) * quantity;
    } else if (availability.discount) {
      sum += availability.discount.gross * quantity;
    }
  }
  return sum;
};

const getProductsCartMembershipDiscount = (cart: Cart[]): number => {
  let sum = 0;
  for (const { availability, quantity } of cart) {
    if (availability.priceMembership?.gross) {
      sum += (availability.priceUndiscounted.gross - availability.priceMembership?.gross) * quantity;
    }
  }
  return sum;
};

const setItemValue = (items: Cart[], idSearch: string, quantity: number): Cart[] => {
  return items.map((item: Cart) => {
    if (item.pk === idSearch) item.quantity = quantity;
    return item;
  });
};
const setItemComment = (products: Cart[], idSearch: string, comment: string): Cart[] => {
  return products.map((product: Cart) => {
    if (product.pk === idSearch) product.comment = comment;
    return product;
  });
};
const changeProductStore = (products: Cart[], idSearch: string, newProductInfo: CartInput): Cart[] => {
  return products.map((product: Cart) => {
    if (product.pk === idSearch) {
      product.pk = newProductInfo.item.pk;
      product.availability = newProductInfo.availability;
    }
    return product;
  });
};
const filterItemsCart = (items: Cart[]): Cart[] => {
  return items.filter((item) => item.quantity !== 0);
};

const getCouponDiscount = (total: number, coupon: any): number => {
  if (coupon) {
    // Check that min_amount has passed
    if (coupon.min_amount && total < coupon.min_amount.gross) return 0;

    if (coupon.discount_value_type === "percentage") {
      const value = total * (coupon.discount_value / 100);
      // Check that discount_maximum_value is present, in that case
      // returns the min value between total and it
      if (coupon.discount_maximum_value && coupon.discount_maximum_value.gross > 0)
        return Math.min(coupon.discount_maximum_value.gross, value);
      return value;
    } else if (coupon.discount_value_type === "fixed") {
      return Math.min(coupon.discount_value, total);
    }
  }
  return 0;
};

export {
  changeProductStore,
  existProductCart,
  setItemValue,
  getQuantity,
  filterItemsCart,
  getProductsCartTotal,
  getProductsCartDiscount,
  getProductsCartMembershipDiscount,
  getCouponDiscount,
  setItemComment,
};
