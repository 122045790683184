import React from "react";
import { type CSSProp } from "styled-components";
import "twin.macro";

type ChevronDownProps = {
  className?: string;
  css?: CSSProp;
} & React.SVGProps<SVGSVGElement>;

export const ChevronDown = ({ className, ...props }: ChevronDownProps): React.ReactElement => (
  <svg className={className} tw="fill-current" height={24} width={24} viewBox={`0 0 24 24`} {...props}>
    <path
      fill={props.fill || "#FFF"}
      d="M18.7 9.7l-6 6c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3l-6-6c-.4-.4-.4-1 0-1.4.4-.4 1-.4 1.4 0l5.3 5.3 5.3-5.3c.4-.4 1-.4 1.4 0 .4.4.4 1 0 1.4z"
    />
  </svg>
);
