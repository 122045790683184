import { type InputHTMLAttributes } from "react";
import type { IFormCommon } from "@/types/common";

import { StyledWrapperRadioButton, StyledWrapperRoot, StylesRoundedInput } from "./styles";
import Paragraph from "../Paragraph";
import { type RuleSet } from "styled-components";
import { type TwStyle } from "twin.macro";

export interface IRadiobuttonProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "size" | "color" | "sizeVariant">,
    Omit<IFormCommon, "sizeVariant"> {
  label?: string;
  addDefaultPadding?: boolean;
  classes?: {
    root?: RuleSet<object> | TwStyle;
  };
  [key: string]: any;
}

const RadioButton = ({
  size,
  label,
  addDefaultPadding = false,
  classes,
  color = "primary",
  ...props
}: IRadiobuttonProps) => {
  return (
    <StyledWrapperRoot
      $disabled={props.disabled}
      $hasLabel={!!label}
      $addDefaultPadding={addDefaultPadding}
      $color={color}
      $classes={classes?.root}
    >
      <input type="radio" {...props} />
      <StyledWrapperRadioButton $size={size}>
        <StylesRoundedInput $color={color} $size={size}>
          <span />
        </StylesRoundedInput>
      </StyledWrapperRadioButton>
      {label && (
        <Paragraph sizeVariant="md" size={size} as="label" htmlFor={props.id}>
          {label}
        </Paragraph>
      )}
    </StyledWrapperRoot>
  );
};

export default RadioButton;
