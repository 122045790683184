import { StyledExit, StyledIconUser } from "./styles";

interface IconAuthProps {
  type: "user" | "exit";
}
const IconAuth = ({ type }: IconAuthProps) => {
  return (
    <div>
      {type === "user" && <StyledIconUser />}
      {type === "exit" && <StyledExit />}
    </div>
  );
};

export default IconAuth;
