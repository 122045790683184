import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ModalProps } from "../../components/Modal/ConfimationModal";

const emptyState: ModalProps = {
  isOpen: false,
  title: "",
  body: "",
  dismissable: false,
  buttonCancelTitle: "",
  buttonAcceptTitle: "",
};

export const confirmationModalSlice = createSlice({
  name: "confirmationStore",
  initialState: emptyState,
  reducers: {
    setConfirmationModalParams: (_state: ModalProps, action: PayloadAction<ModalProps>): ModalProps =>
      action.payload,
    emptyConfirmation: (_state: ModalProps): ModalProps => ({ ...emptyState }),
  },
});

export const confirmationModalSliceActions = confirmationModalSlice.actions;
