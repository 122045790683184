import { type CSSProperties, type LabelHTMLAttributes } from "react";
import type { ITypographyCommon } from "@/types/common";

import { StyledWrapperLabel } from "./styles";

export interface ILabel extends LabelHTMLAttributes<HTMLLabelElement>, ITypographyCommon {
  fontWeigth?: CSSProperties["fontWeight"];
}

const Label = ({ size, color, sizeVariant, disabled = false, fontWeigth = 600, ...props }: ILabel) => {
  if (!sizeVariant) {
    throw new TypeError("sizeVariant is required");
  }

  return (
    <StyledWrapperLabel
      {...props}
      $size={size}
      $color={color}
      $sizeVariant={sizeVariant}
      $disabled={disabled}
      $fontWeigth={fontWeigth}
    />
  );
};

export default Label;
