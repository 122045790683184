/**
 * Normalizes the given text by removing diacritical marks and converting it to lowercase.
 *
 * @param {string} text - The text to be normalized.
 * @returns {string} The normalized text.
 */
export const textNormalize = (text) => {
  const textNormalize = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return textNormalize.toLowerCase();
};

/**
 * Eliminates leading and trailing whitespace and replaces multiple spaces with a single space.
 *
 * @param {string} text - The text to be normalized.
 * @returns {string} The normalized text.
 */
export const normalizeWhitespace = (text: string) => {
  if (!text) return "";

  const sanitizedText = text.trim().replace(/\s+/g, " ");
  return sanitizedText;
};
