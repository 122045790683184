import tw, { styled } from "twin.macro";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";

export const StyledHeadModalGetTokenPlus = styled.div`
  ${tw`flex  justify-end`}
`;

export const StyledIconClose = styled(IconClose)`
  ${tw`cursor-pointer w-7 h-7 hover:fill-error-50 `}
`;
