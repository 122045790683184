import Popover, { PopoverContent, PopoverTrigger } from "@/components/Atoms/Popover";
import tw, { css } from "twin.macro";
import { StyledTooltipHead, StyledTooltipWrapper } from "./styles";
import Heading from "@/components/Atoms/Heading";
import Paragraph from "@/components/Atoms/Paragraph";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";

interface InfoPopupProps {
  children: React.ReactNode;
  onClick?: () => void;
  popoverProps?: React.ComponentProps<typeof Popover>;
  title: string;
  description?: string;
  minWidth?: string; // Nueva propiedad opcional
  maxWidth?: string; // Nueva propiedad opcional
}

const InfoPopup = ({
  children,
  onClick,
  popoverProps,
  title,
  description,
  minWidth = "auto",
  maxWidth = "auto",
}: InfoPopupProps) => {
  return (
    <Popover {...popoverProps}>
      <PopoverTrigger>{children}</PopoverTrigger>
      <PopoverContent
        classes={{
          root: css`
            ${tw`z-[10] p-0 box-border`}
            ${minWidth && `min-width: ${minWidth};`}
            ${maxWidth && `max-width: ${maxWidth};`}
          `,
        }}
      >
        <StyledTooltipWrapper>
          <StyledTooltipHead>
            <Heading type="h6">{title}</Heading>
            <IconClose tw="w-6 h-6 cursor-pointer fill-secondary-40" onClick={onClick} />
          </StyledTooltipHead>

          {description && <Paragraph sizeVariant="sm">{description}</Paragraph>}
        </StyledTooltipWrapper>
      </PopoverContent>
    </Popover>
  );
};

export default InfoPopup;
