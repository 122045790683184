import tw from "twin.macro";
import { type FormEvent, forwardRef, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "@atoms/Button";
import Popover, { PopoverContent, PopoverTrigger } from "@atoms/Popover";
import TextField from "@atoms/TextField";

import useAppSelector from "@/hooks/useAppSelector";
import { useMediaQuery } from "@/hooks/useMediaQuery";

import IconEditClose from "@iconsV2/Edit/close.svg?react";
import IconSearch from "@iconsV2/Interrface/magnifier-search-01.svg?react";
import type { IHub } from "@/types/hubs";
import type { TSize } from "@/types/common";

import MeiliSearch from "@/recourses/meilisearch/meiliSearch";

import { hitToProductGallery } from "@/utils/product";
import { normalizeWhitespace, textNormalize } from "@/utils/textNormalize";

import DropdownSearchInput from "../DropdownSearchInput";
import InputForm from "./InputForm";
import { StyledInputMobile, StyledWrapperInputSearch } from "./styles";
import { useQueries } from "@tanstack/react-query";
import { SearchBarEnum } from "@/recourses/queryKeys";

interface IInputSearch {
  size?: TSize;
  itemCategories?: Array<{
    slug: string;
    name: string;
  }>;
  itemHubs?: IHub[];
  openMenuSearchExternal?: boolean;
  handleOpenMenuSearchExternal?: (value: boolean) => void;
  isScrolled?: boolean;
}

const InputSearch = forwardRef<HTMLFormElement, IInputSearch>(
  ({
    itemCategories,
    itemHubs,
    openMenuSearchExternal = false,
    isScrolled = false,
    handleOpenMenuSearchExternal,
  }) => {
    // const locationPath = useLocation().pathname;
    const navigate = useNavigate();
    const storeId = useAppSelector((state) => state.cart?.storeId);

    const inputRef = useRef<HTMLInputElement>(null);
    const lastestSearch = JSON.parse(localStorage.getItem("lastSearches") ?? "[]");

    const isMedium = useMediaQuery("( min-width: {medium} )");

    // const [openMenu, setOpenMenu] = useState(false);
    const [value, setValue] = useState("");
    // const [hits, setHits] = useState<IProductGalleryData[]>([]);
    const [suggestHits, setSuggestHits] = useState<string[]>([]);
    const [openMenuSearch, setOpenMenuSearch] = useState(openMenuSearchExternal);
    const isOpenMenuSearch = openMenuSearch || openMenuSearchExternal;

    const handleOpenMenuSearch = (open: boolean) => {
      setOpenMenuSearch(open);
      handleOpenMenuSearchExternal?.(open);
    };

    // const handleOpenMenu = (open: boolean) => {
    //   setOpenMenu(open);
    // };

    const updateLastSearches = (newValue) => {
      const lastSearchesLS = localStorage.getItem("lastSearches");
      let lastSearches = lastSearchesLS ? JSON.parse(lastSearchesLS) : [];

      // Remove newValue if exist in the last searches
      lastSearches = lastSearches.filter((item) => item !== newValue);

      // Add newValue in array
      lastSearches.push(newValue);

      // Limit array to 3
      if (lastSearches.length > 3) {
        lastSearches = lastSearches.slice(-3);
      }

      localStorage.setItem("lastSearches", JSON.stringify(lastSearches));
    };

    const handleSearch = (query: string) => {
      if (query?.trim()?.length >= 1) {
        updateLastSearches(query);
        const sanitizedPath = normalizeWhitespace(query);
        handleClose();
        navigate(`/search?query=${encodeURIComponent(sanitizedPath)}`);
      }
    };

    const handleOnSubmit = (e: FormEvent) => {
      e.preventDefault();
      handleSearch(value);
    };

    const handleChangeInput = (e: string) => {
      if (e.length === 0) {
        setSuggestHits([]);
      }
      setValue(e);
    };

    const handleClose = () => {
      handleOpenMenuSearch(false);
      setValue("");
      setSuggestHits([]);
    };
    const onClickSuggest = (query: string) => {
      setValue(query);
      handleSearch(query);
    };

    const buildFilters = `store_id = ${storeId} AND is_available_online = true`;

    const querys = useQueries({
      queries: [
        {
          queryKey: [SearchBarEnum.searchResults, value, storeId],
          queryFn: async () => {
            if (value.trim().length === 0) return [];
            const { hits } = await new MeiliSearch().query(value).filter(buildFilters).limit(5).search();
            return hits.map(hitToProductGallery);
          },
          enabled: !!value && storeId !== undefined,
          staleTime: 60 * 1000 * 5,
        },
        {
          queryKey: [SearchBarEnum.searchSuggests, value, storeId],
          queryFn: async () => {
            const { hits } = await new MeiliSearch()
              .query(value)
              .filter(buildFilters)
              .limit(2)
              .onlyAttributes(["product_name"])
              .search();

            const productsName = hits
              .filter((hit) =>
                textNormalize(hit.product_name)
                  .toLowerCase()
                  .replace(/[\u0300-\u036f]/g, "")
                  .startsWith(textNormalize(value.toLowerCase()))
              )
              .map((hit) => hit.product_name)
              .slice(0, 2);

            return productsName;
          },
          enabled: !!value && storeId !== undefined,
          staleTime: 60 * 1000 * 5,
        },
      ],
    });

    const [searchResults, searchSuggests] = querys;

    useEffect(() => {
      if (!searchSuggests.data) return;

      setSuggestHits(searchSuggests.data);
    }, [searchSuggests.data]);

    return (
      <StyledWrapperInputSearch $isScrolled={isScrolled}>
        {/* {isMedium && CategoryButton} */}

        <Popover
          placement="bottom-start"
          open={
            ((value.length >= 1 || lastestSearch.length > 0) && isOpenMenuSearch && isMedium) ||
            (isOpenMenuSearch && !isMedium)
          }
          withReferenceWidth
          hidden={false}
          flip={false}
          lockScroll={!isMedium}
          fullScreen={!isMedium}
          onOpenChange={handleOpenMenuSearch}
        >
          <PopoverTrigger
            tw="flex w-full items-stretch"
            onSubmit={handleOnSubmit}
            onClick={() => handleOpenMenuSearch(true)}
          >
            {isMedium ? (
              <InputForm
                value={value}
                onChange={handleChangeInput}
                onSubmit={handleOnSubmit}
                onClean={handleClose}
                suggestions={suggestHits}
                onClick={() => handleOpenMenuSearch(true)}
              />
            ) : (
              <StyledInputMobile>
                <TextField
                  startIcon={<IconSearch tw="!(h-[18px] large:(h-[24px]))" />}
                  placeholder="Busca tus productos"
                  onClick={() => {
                    handleOpenMenuSearch(true);
                    setTimeout(() => {
                      inputRef.current?.focus();
                    }, 100);
                  }}
                />
              </StyledInputMobile>
            )}
          </PopoverTrigger>

          <PopoverContent
            classes={{
              root: tw`shadow-elevation-3 border border-neutral-90 bg-neutral-99 p-0 flex flex-col`,
            }}
          >
            {!isMedium && (
              <div tw="flex items-stretch gap-s bg-neutral-100 pt-4 px-4 pb-2">
                <InputForm
                  ref={inputRef}
                  value={value}
                  onChange={setValue}
                  onSubmit={handleOnSubmit}
                  onClean={() => {
                    setValue("");
                  }}
                  suggestions={suggestHits}
                />
                <Button
                  sizeVariant="lg"
                  onClick={handleClose}
                  color="secondary"
                  variant="outlined"
                  startIcon={<IconEditClose />}
                />
              </div>
            )}
            <DropdownSearchInput
              itemHubs={itemHubs ?? []}
              itemCategories={itemCategories ?? []}
              suggestions={suggestHits}
              onClickSuggest={onClickSuggest}
              onClean={handleClose}
              value={value}
              querySearch={searchResults}
            />
          </PopoverContent>
        </Popover>
      </StyledWrapperInputSearch>
    );
  }
);

export default InputSearch;
