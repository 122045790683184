import Heading from "@/components/Atoms/Heading";
import {
  StyledColumn,
  StyledHeadPromoCode,
  StyledIconClose,
  StyledPromoCode,
  StyledPromoCodeTextContainer,
  StyledRow,
  StyleIconCartPromo,
} from "./styles";
import { theme } from "twin.macro";
import Button from "@/components/Atoms/Button";
import Paragraph from "@/components/Atoms/Paragraph";

interface PromoContentProps {
  title: string;
  promoCode?: string;
  onClick?: () => void;
  description?: string;
  actionTitle?: string;
  terms?: string | React.ReactNode;
  onClose?: () => void;
}
const PromoContent = ({
  title,
  onClick,
  onClose,
  promoCode,
  description,
  actionTitle,
  terms,
}: PromoContentProps) => {
  return (
    <div tw="relative" id="promo-content-free-shipping">
      <StyledHeadPromoCode>
        <StyledIconClose onClick={onClose} />

        <StyleIconCartPromo />
        <StyledRow>
          <Heading type="h3" className="gradient-title">
            {title}
          </Heading>
        </StyledRow>

        <Paragraph sizeVariant="lg">{description}</Paragraph>
      </StyledHeadPromoCode>
      <StyledPromoCode>
        <StyledPromoCodeTextContainer>
          <Heading type="h2" color={theme`colors.neutral.100`}>
            {promoCode}
          </Heading>
        </StyledPromoCodeTextContainer>
        <div className="zigZag" />
      </StyledPromoCode>
      <StyledColumn tw="items-center">
        {onClick && (
          <Button sizeVariant="lg" color="secondary" fullWidth onClick={onClick}>
            {actionTitle}
          </Button>
        )}
        {typeof terms === "string" ? (
          <Paragraph sizeVariant="sm">{terms}</Paragraph>
        ) : (
          terms // If terms is a ReactNode, render it as is
        )}
      </StyledColumn>
    </div>
  );
};
export default PromoContent;
