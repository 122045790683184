export function getProjectWindow() {
  return typeof window !== "undefined" ? window : null;
}

export function injectScript(id: string, src: string, onLoad?: () => void, async: boolean = true) {
  const window = getProjectWindow();
  if (!window) return;

  if (!window.document.getElementById(id)) {
    const script = window.document.createElement("script");
    script.id = id;
    script.src = src;
    script.async = async;
    script.onload = onLoad ?? (() => {});
    window.document.body.appendChild(script);
  }
}

export function removeScript(id: string) {
  const window = getProjectWindow();
  if (!window) return;

  const script = window.document.getElementById(id);
  if (script) {
    script.parentElement?.removeChild(script);
  }
}
