import tw, { styled } from "twin.macro";
import Card from "../Card";

export const StyledCard = styled(Card)`
  ${tw`bg-secondary-100`}
`;

export const StyledSummary = styled.summary`
  ${tw`w-full flex list-none cursor-pointer`}

  &::-webkit-details-marker {
    display: none;
  }
`;

export const StyledHeading = styled.div`
  ${tw`w-full flex items-center justify-between`}
`;

export const StyledContentClose = styled.div`
  ${tw`w-full flex flex-col`}
`;

export const StyledDivider = styled.hr`
  ${tw`divide-x w-full bg-neutral-90 mt-3 mb-2`}
`;
