import http from "@/utils/http";
import { request } from "../../utils/request";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;
const COUPONS_ENDPOINT = "coupons";
const REFERRAL_COUPON = "referral-coupons/";

const validateCoupon = async (code: string, storeId: number, amount?: number): Promise<ValidateResult> => {
  const response = await http.get(
    `${BASE_API_URL}api/v1/${COUPONS_ENDPOINT}/validate/?code=${code}&store=${storeId}${amount ? `&amount=${amount}` : ""}`
  );
  return response.data.payload;
};

const getCouponReferral = async (): Promise<any> => {
  const response = await request<any>(`${BASE_API_URL}api/v1/${REFERRAL_COUPON}`);
  return response.data;
};

export { validateCoupon, getCouponReferral };
