import Button from "../Button";
import { StyledStepOrBackHeader } from "./styles";
import IconChevronLeft from "@/components/Icons/V2/Arrows/chevron-left-normal.svg?react";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";

interface StepOrBackHeaderProps {
  onClose?: () => void;
  onBack?: () => void;
}
const StepOrBackHeader = ({ onClose, onBack }: StepOrBackHeaderProps) => {
  return (
    <StyledStepOrBackHeader>
      {onBack && (
        <Button
          sizeVariant="lg"
          onClick={onBack}
          color="secondary"
          variant="text"
          startIcon={<IconChevronLeft />}
          type="button"
        >
          Regresar
        </Button>
      )}
      {onClose && (
        <span tw="px-sm px-s">
          <IconClose tw="h-ml w-ml justify-self-end cursor-pointer " onClick={() => onClose?.()} />
        </span>
      )}
    </StyledStepOrBackHeader>
  );
};

export default StepOrBackHeader;
