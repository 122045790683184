import { createBrowserRouter, type RouteObject, type LazyRouteFunction } from "react-router-dom";

/**
 * Creates a retryable lazy loading function for a route.
 * @param lazyFn - The original lazy loading function.
 * @param path - The path of the route.
 * @returns A new lazy loading function with retry logic.
 */
export const lazyRetry =
  (lazyFn: LazyRouteFunction<RouteObject>, path: string): LazyRouteFunction<RouteObject> =>
  async () => {
    const key = `retry-${path}-refreshed`;

    // Check if the window has already been refreshed
    const hasRefreshed = Boolean(JSON.parse(window.localStorage.getItem(key) ?? "false"));

    try {
      const route = await lazyFn();
      window.localStorage.removeItem(key); // reset the refresh on success
      return route;
    } catch (e) {
      if (hasRefreshed) {
        // The page has already been reloaded. Assuming that user is already using the latest version of the
        // application, let the application crash and raise the error.
        throw e;
      }

      // Assuming that the user is not on the latest version of the application. Refresh the page immediately.
      return await new Promise(() => {
        window.localStorage.setItem(key, "true");
        const url = new URL(window.location.pathname, window.location.origin);
        window.location.href = url.toString();
      });
    }
  };

/**
 * Recursively modifies the routes array to add retry logic to lazy loading routes.
 * @param routes - An array of RouteObject representing the application routes.
 * @returns A new array of RouteObject with modified lazy loading functions.
 */
function modifyRoutes(routes: RouteObject[]): RouteObject[] {
  return routes.map((route) => {
    if (route.lazy && route.path) {
      route.lazy = lazyRetry(route.lazy, route.path);
    }

    if (route.children && route.children.length > 0) {
      route.children = modifyRoutes(route.children);
    }

    return route;
  });
}

/**
 * Creates a browser router with retry logic for lazy loading routes.
 * @param routes - An array of RouteObject representing the application routes.
 * @returns A browser router with modified routes.
 */
export function createBrowserRouterWithRetry(routes: RouteObject[], opts?: Record<string, any>) {
  const modifiedRoutes = modifyRoutes(routes);
  return createBrowserRouter(modifiedRoutes, opts);
}
