import { useState } from "react";

/**
 * Will be fix when react-scripts updates its typescript-eslint deps
 * @see https://github.com/typescript-eslint/typescript-eslint/issues/2260#issuecomment-680197439
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type

interface ItemWithExpiry<T> {
  value: T;
  expiry: number;
}

type ttlType = "day" | "hour" | "minute" | "second" | "milliSecond";

const ttlMeasures: { [key in ttlType]: number } = {
  day: 24 * 60 * 60000,
  hour: 60 * 60000,
  minute: 60000,
  second: 1000,
  milliSecond: 1,
};

export const useLocalStorageWithExpiry = <T>(key: string, ttl?: string) => {
  const [storedValue, setStoredValue] = useState<T | null>(() => {
    if (typeof window === "undefined") {
      return null;
    }
    const item = window.localStorage.getItem(key);

    // if key doesn't exist on local storage
    if (!item) return null;

    const itemParse = JSON.parse(item);
    const now = new Date();

    // Item has expiry
    if ("expiry" in itemParse) {
      const itemWithExpiry = itemParse as ItemWithExpiry<T>;

      // compare the expiry time of the item with the current time
      if (now.getTime() > itemWithExpiry.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
      }
      return itemWithExpiry.value;
    } else {
      return itemParse as T;
    }
  });

  const parseTTL = (ttlAmount: string): number => {
    const ttlElements = ttlAmount.split(" ");

    // have just 2 elements
    if (!(ttlElements.length === 2)) return ttlMeasures.day;

    // second element is part of valid types
    if (!["day", "hour", "minute", "second", "milliSecond"].includes(ttlElements[1])) return ttlMeasures.day;

    // first element is a number
    if (!parseInt(ttlElements[0])) return ttlMeasures.day;

    return parseInt(ttlElements[0]) * ttlMeasures[ttlElements[1]];
  };

  const setValue = (value: T): void => {
    const now = new Date();

    const ttlElement = !ttl ? "1 day" : ttl;

    const item = {
      value,
      expiry: now.getTime() + parseTTL(ttlElement),
    };

    if (value === null) {
      setStoredValue(value);
      window.localStorage.removeItem(key);
    } else {
      setStoredValue(value);
      window.localStorage.setItem(key, JSON.stringify(item));
    }
  };

  return [storedValue, setValue] as const;
};
