import { type PopUpsReminder } from "./types";

const initialStatePopUpsReminder: PopUpsReminder = {
  showLocationModal: true,
  showAddressReminder: true,
  showFlashModal: true,
  showHubsReminder: true,
  CashBackPlusNotification: {
    isOpen: true,
    lastCashBack: 0,
  },
};

const emptyStatePopUpsReminder: PopUpsReminder = {
  showLocationModal: true,
  showAddressReminder: true,
  showFlashModal: true,
  showHubsReminder: true,
  CashBackPlusNotification: {
    isOpen: true,
    lastCashBack: 0,
  },
};

export { initialStatePopUpsReminder, emptyStatePopUpsReminder };
