import { useLocation, useSearchParams } from "react-router-dom";
import { setQueryParams as utilSetQueryParams } from "@/utils/queryParams";
import type { TQueryParams } from "@/types/common";

const useQueryParams = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const setQueryParams = (queryParams: TQueryParams) => {
    utilSetQueryParams(queryParams, location, setSearchParams);
  };

  return {
    queryParams: searchParams,
    setQueryParams,
  };
};

export default useQueryParams;
