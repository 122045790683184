import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";
import posthog from "posthog-js";

import { IS_PROD, SENTRY_DSN, SENTRY_ORG, SENTRY_PROJ_ID } from "./constants";

export const initializeSentry = () => {
  if (IS_PROD) {
    Sentry.init({
      dsn: SENTRY_DSN,
      integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        posthog.sentryIntegration({
          organization: SENTRY_ORG,
          projectId: SENTRY_PROJ_ID,
          severityAllowList: ["error", "fatal"],
        }),
        Sentry.replayIntegration(),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for tracing.
      tracesSampleRate: 1.0,

      // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
      // tracePropagationTargets: [/^\//, /^https:\/\/yourserver\.io\/api/],
      denyUrls: [
        /https:\/\/connect\.facebook\.net\//,
        /https:\/\/analytics\.tiktok\.com\/i18n\/pixel\/events\.js/,
        /https:\/\/static-tracking\.klaviyo\.com\/onsite\/js\/fender_analytics/,
        /https:\/\/maps\.googleapis\.com\/maps\/api\/mapsjs\/gen_204/,
        /https:\/\/a\.klaviyo\.com\/onsite\/track-analytics/,
        /https:\/\/app\.posthog\.com\/e\//,
        /https:\/\/s\.pinimg\.com\/ct\/core\.js\//,
        /https:\/\/v\.clarity\.ms\//,
        /https:\/\/n\.clarity\.ms\//,
        /https:\/\/ct\.pinterest\.com\//,
        /https:\/\/e\.frizbit\.com\//,
        /https:\/\/fast\.a\.klaviyo\.com\//,
      ],

      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
};
