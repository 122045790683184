import { useEffect, useState } from "react";

interface IUseElementOnScreen extends IntersectionObserverInit {
  id: string; // Element id
  callback?: (show: boolean) => void;
  waitFor?: boolean;
}

const useElementOnScreen = ({ id, callback, waitFor = true, ...options }: IUseElementOnScreen) => {
  const [isVisible, setIsVisible] = useState(false);

  const callbackFunction: IntersectionObserverCallback = (entries) => {
    const [entry] = entries;

    callback?.(entry.isIntersecting);
    setIsVisible(entry.isIntersecting);
  };

  useEffect(() => {
    if (waitFor) {
      const observer = new IntersectionObserver(callbackFunction, options);
      const element = document.getElementById(id);

      if (element) observer.observe(element);

      return () => {
        if (element) observer.unobserve(element);
      };
    }
  }, [waitFor]);

  return {
    isVisible,
  };
};

export default useElementOnScreen;
