/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import { type CSSProperties, type HTMLAttributes } from "react";
import { StyledWrapperButtonText } from "./styles";
import type { ITypographyCommon } from "@/types/common";

export interface IButtonText extends HTMLAttributes<HTMLParagraphElement>, ITypographyCommon {
  fontWeight?: CSSProperties["fontWeight"];
  [key: string]: any;
}

const ButtonText = ({ size, color, sizeVariant, fontWeight, ...props }: IButtonText) => {
  if (!sizeVariant) {
    throw new TypeError("sizeVariant is required");
  }

  return (
    <StyledWrapperButtonText
      {...props}
      $size={size}
      $color={color}
      $sizeVariant={sizeVariant}
      $fontWeight={fontWeight}
    />
  );
};

export default ButtonText;
