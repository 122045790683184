import { request } from "../../utils/request";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;
const SERVICE = "services";
const PRICE_ENDPOINT = "price";

const getDeliveryPrice = async ({
  id,
  coupon,
  schedule_at,
  total,
  payment_method,
  address,
  lat,
  lng,
  items,
}: getDeliveryPriceParams): Promise<getDeliveryPriceResponse> => {
  const response = await request<getDeliveryPriceResponse>(
    `${BASE_API_URL}api/v1/${SERVICE}/${id}/${PRICE_ENDPOINT}/`,
    {
      method: "POST",
      data: {
        schedule_at,
        total,
        coupon,
        payment_method,
        address,
        lat,
        lng,
        items,
      },
      timeout: 28000,
    }
  );

  return response.data;
};

export { getDeliveryPrice };
