import type { TSize } from "@/types/common";

import {
  StyledFigure,
  StyledWrapperButton,
  StyledWrapperCard,
  StyledWrapperContent,
  StyledWrapperContentText,
  StyledWrapperDescription,
  StyledWrapperFigure,
  StyledWrapperTitle,
} from "./styles";

export interface IProductCardGallerySearchSkeleton {
  size?: TSize;
  key?: any;
}

const ProductCardGallerySkeleton = ({ size, ...rest }: IProductCardGallerySearchSkeleton) => {
  return (
    <StyledWrapperCard $size={size} {...rest}>
      <StyledWrapperFigure>
        <StyledFigure />
      </StyledWrapperFigure>
      <StyledWrapperContent>
        <StyledWrapperContentText>
          <StyledWrapperTitle />
          <StyledWrapperDescription />
        </StyledWrapperContentText>
        <StyledWrapperButton />
      </StyledWrapperContent>
    </StyledWrapperCard>
  );
};

export default ProductCardGallerySkeleton;
