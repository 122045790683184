import { useCallback, useEffect, useState } from "react";
import { theme } from "twin.macro";

interface Breakpoints {
  xxs: string;
  sm: string;
  md: string;
  lg: string;
  xl: string;
  "2xl": string;
  medium: string;
  large: string;
  "3xl": string;
}

const replaceBreakpoints = (input: string): string => {
  return input.replace(/\{(xxs|sm|md|lg|xl|2xl|medium|large|3xl)\}/g, (_, key: keyof Breakpoints) => {
    return theme`screens`[key] || key;
  });
};

export function useMediaQuery(query: string): boolean {
  const getMatches = (query: string): boolean => {
    return window.matchMedia(replaceBreakpoints(query)).matches;
  };

  const [matches, setMatches] = useState<boolean>(getMatches(query));

  const handleChange = useCallback(() => {
    setMatches(getMatches(query));
  }, []);

  useEffect(() => {
    const matchMedia = window.matchMedia(replaceBreakpoints(query));
    handleChange();
    matchMedia.addEventListener("change", handleChange);
    return () => {
      matchMedia.removeEventListener("change", handleChange);
    };
  }, [query]);

  return matches;
}
