import React from "react";
import "twin.macro";
interface SearchProps {
  fill?: string;
}

export const Search = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg tw="fill-current" {...props} viewBox="0 0 24 24">
    <path
      id="21wjd9223wd"
      fill={props.fill ? props.fill : "#fff"}
      d="M21.7 20.3l-4.5-4.5c1.1-1.4 1.8-3.3 1.8-5.3C19 5.8 15.2 2 10.5 2S2 5.8 2 10.5 5.8 19 10.5 19c2 0 3.8-.7 5.3-1.8l4.5 4.5c.2.2.5.3.7.3.2 0 .5-.1.7-.3.4-.4.4-1 0-1.4zM4 10.5C4 6.9 6.9 4 10.5 4S17 6.9 17 10.5c0 1.8-.7 3.4-1.9 4.6a6.42 6.42 0 01-4.6 1.9C6.9 17 4 14.1 4 10.5z"
    />
  </svg>
);
