import React from "react";

import {
  hide,
  autoUpdate,
  offset,
  flip,
  shift,
  arrow as floatingArrow,
  useFloating,
  useHover,
  useFocus,
  useDismiss,
  useRole,
  useInteractions,
  type Placement,
  type UseRoleProps,
  safePolygon,
} from "@floating-ui/react";

export interface IuseFloatingUI {
  initialOpen?: boolean;
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  arrow?: boolean;
  flip?: boolean;
  shift?: boolean;
  dismiss?: boolean;
  role?: UseRoleProps["role"];
  withReferenceWidth?: boolean;
  hidden?: boolean;
  lockScroll?: boolean;
  fullScreen?: boolean;
}

function useFloatingUI({
  initialOpen = false,
  placement = "bottom",
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  flip: useFlip = true,
  shift: useShift = true,
  arrow = true,
  dismiss = true,
  role,
  withReferenceWidth = false,
  hidden = true,
  lockScroll = false,
  fullScreen = false,
}: IuseFloatingUI = {}) {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const open = controlledOpen ?? uncontrolledOpen;
  const setOpen = setControlledOpen ?? setUncontrolledOpen;

  const arrowRef = React.useRef(null);
  const middlewareFlip = useFlip
    ? flip({
        altBoundary: true,
        crossAxis: placement.includes("-"),
        fallbackAxisSideDirection: "start",
      })
    : undefined;
  const middlewareShift = useShift ? shift({ altBoundary: true }) : undefined;
  const middlewareArrow = arrow ? floatingArrow({ element: arrowRef }) : undefined;

  const middlewareHidden = hidden ? hide() : undefined;

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [offset(arrow ? 10 : 5), middlewareHidden, middlewareFlip, middlewareShift, middlewareArrow],
    strategy: "fixed", // Avoid animation when scrolling
  });

  const context = data.context;

  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon(),
    enabled: controlledOpen == null,
  });

  const focus = useFocus(context, {
    enabled: controlledOpen == null,
  });

  const dismissHook = useDismiss(context, {
    enabled: dismiss,
    escapeKey: dismiss,
  });

  const roleHook = useRole(context, {
    role: role ?? undefined,
    enabled: Boolean(role),
  });

  const interactions = useInteractions([hover, focus, dismissHook, roleHook]);

  return React.useMemo(
    () => ({
      arrowRef,
      arrow,
      open,
      setOpen,
      withReferenceWidth,
      lockScroll,
      fullScreen,
      ...interactions,
      ...data,
    }),
    [open, setOpen, interactions, data]
  );
}

export default useFloatingUI;
