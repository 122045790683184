import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import tw from "twin.macro";

import Card from "@atoms/Card";
import Button from "@atoms/Button";
import Paragraph from "@atoms/Paragraph";
import Heading from "@atoms/Heading";
import PopoverButton from "@atoms/PopoverButton";
import IconLabelTag from "@iconsV2/Interrface/label-tag-01.svg?react";
import IconFlash from "@iconsV2/Interrface/flash.svg?react";
import IconWarning from "@iconsV2/Warnings/information-circle.svg?react";

import type { IProductGalleryData } from "@/types/product";
import type { IHub } from "@/types/hubs";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import ProductCardGallerySearch from "../ProductCardGalllerySearch";
import HubsSection from "../HubsSection";
import {
  StyledIconCloseSuggest,
  StyledLoaderWrapper,
  StyledSuggest,
  StyledWrapperButton,
  StyledWrapperCatgoriesAndHubs,
  StyledWrapperContent,
  StyledWrapperEmpty,
  StyledWrapperHeading,
  StyledWrapperProductCard,
  StyledWrapperSuggests,
} from "./styles";
import { type UseQueryResult } from "@tanstack/react-query";
import IconLoader from "@components/Icons/V2/Loader/loader-light.svg?react";

interface DropdownSearchInputProps {
  onClickSuggest?: (query: string) => void;
  onClean?: () => void;
  value: string;
  itemCategories?: Array<{
    name: string;
    slug: string;
  }>;
  itemHubs: IHub[];
  suggestions: string[];
  querySearch: UseQueryResult<IProductGalleryData[], Error>;
}

const DropdownSearchInput = ({
  value,
  onClickSuggest,
  onClean,
  itemHubs,
  itemCategories,
  suggestions,
  querySearch,
}: DropdownSearchInputProps) => {
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const handleClickLink = () => {
    onClean?.();
  };

  const [searchParams] = useSearchParams();
  const query = searchParams.get("queryParams");
  const [lastSearches, setLastSearches] = useState<string[]>([]);
  const resultsSearch = querySearch.data;

  useEffect(() => {
    const refetchLastSearches = JSON.parse(
      localStorage.getItem("lastSearches") ?? "[]"
    ).reverse() as string[];
    setLastSearches(refetchLastSearches);
  }, [query]);

  const handleDeleteLastSearches = () => {
    setLastSearches([]);
    localStorage.removeItem("lastSearches");
  };

  const handleDeleteLastSearch = (index: number) => {
    const lastSearches = [...JSON.parse(localStorage.getItem("lastSearches") ?? "[]").reverse()];
    lastSearches.splice(index, 1);
    setLastSearches(lastSearches);
    localStorage.setItem("lastSearches", JSON.stringify(lastSearches));
  };

  const handleClickSuggest = (query: string) => {
    onClickSuggest?.(query);
  };

  return (
    <Card
      classes={{
        root:
          resultsSearch && resultsSearch.length > 0
            ? tw`relative shadow-none p-0 bg-transparent overflow-y-auto mb-[3.5rem] medium:(h-full max-h-[75dvh] medium:(mb-[3.625rem])) large:(mb-[3.875rem])`
            : tw`relative shadow-none p-0 bg-transparent overflow-y-auto medium:(h-full max-h-[75dvh])`,
      }}
    >
      <StyledWrapperContent>
        {/* Suggestions about value */}
        {value?.trim()?.length > 0 && (
          <>
            <StyledWrapperSuggests>
              <StyledSuggest onClick={() => handleClickSuggest(value)}>
                <span tw="line-clamp-1">{value}</span>
              </StyledSuggest>

              {suggestions.length > 0 && (
                <>
                  {suggestions.map((item, i) => (
                    <StyledSuggest onClick={() => handleClickSuggest(item)} key={i}>
                      <span tw="line-clamp-1">{item}</span>
                    </StyledSuggest>
                  ))}
                </>
              )}
            </StyledWrapperSuggests>
          </>
        )}

        {/* Last searches */}
        {lastSearches.length > 0 && (
          <StyledWrapperSuggests>
            <StyledWrapperHeading>
              <Heading type="h6">
                {lastSearches.length === 1 ? "Tu última búsqueda" : "Tus últimas búsquedas"}
              </Heading>

              <Button sizeVariant="md" variant="text" color="secondary" onClick={handleDeleteLastSearches}>
                Borrar todo
              </Button>
            </StyledWrapperHeading>

            {lastSearches.map((item, i) => (
              <StyledSuggest onClick={() => handleClickSuggest(item)} key={i}>
                <span tw="line-clamp-1">{item}</span>

                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteLastSearch(i);
                  }}
                >
                  <StyledIconCloseSuggest />
                </button>
              </StyledSuggest>
            ))}
          </StyledWrapperSuggests>
        )}

        {/* Suggest about recommby */}
        {/* <StyledWrapperSuggests>
            <StyledWrapperHeading>
              <Heading type="h6">Sugerencias</Heading>

              <Button sizeVariant="md" variant="text" color="secondary">
                Borrar todo
              </Button>
            </StyledWrapperHeading>

            <StyledSuggest">
              <span>Harinas</span>

              <StyledIconCloseSuggest />
            </StyledSuggest>
            <StyledSuggest">
              <span>Productos para piel</span>

              <StyledIconCloseSuggest />
            </StyledSuggest>
            <StyledSuggest">
              <span>Dulces para diabéticos</span>

              <StyledIconCloseSuggest />
            </StyledSuggest>
          </StyledWrapperSuggests> */}

        {value.length > 0 && (
          <StyledWrapperProductCard>
            {resultsSearch && (
              <>
                {/* results  */}
                {resultsSearch?.length >= 1 &&
                  resultsSearch.map((product) => {
                    return (
                      <Link to={`/p/${product.slug}`} key={product.id} onClick={handleClickLink}>
                        <ProductCardGallerySearch product={product} showButtonCart={false} />
                      </Link>
                    );
                  })}
                {/* no results found  */}
                {resultsSearch?.length === 0 && !querySearch.isFetching && value?.trim()?.length >= 1 && (
                  <StyledWrapperEmpty>
                    <div>
                      <IconWarning tw={"[& path]:(fill-primaryV2-70)"} />
                      <Heading type="h6">Sin resultados</Heading>
                    </div>
                    <Paragraph tw="text-neutral-40" sizeVariant="md">
                      No se encontraron resultados
                    </Paragraph>
                  </StyledWrapperEmpty>
                )}
              </>
            )}

            {/* Fetch Error no results  */}
            {!resultsSearch && querySearch.error && (
              <StyledWrapperEmpty>
                <div>
                  <IconWarning tw={"[& path]:(fill-primaryV2-70)"} />
                  <Heading type="h6">Error al cargar los resultados</Heading>
                </div>
                <Paragraph tw="text-neutral-40" sizeVariant="md">
                  "Ocurrió un error inesperado. Por favor, intenta nuevamente."
                </Paragraph>
              </StyledWrapperEmpty>
            )}

            {/* fetch load content */}
            {querySearch.isFetching && (
              <StyledLoaderWrapper>
                <IconLoader width={25} height={25} aria-label="Cargando contenido" tw="animate-spin" />
                <Heading type="h6">Buscando resultados</Heading>
              </StyledLoaderWrapper>
            )}
          </StyledWrapperProductCard>
        )}
      </StyledWrapperContent>

      {value.length === 0 && !isMedium && (
        <StyledWrapperCatgoriesAndHubs>
          {itemHubs.length > 0 && <HubsSection hubs={itemHubs} isLoading={false} />}

          <ul tw="flex flex-col gap-sm">
            <li>
              <PopoverButton
                label="Promofit"
                endIcon={<IconLabelTag />}
                as={Link}
                to="/c/promofit"
                title="Promofit"
                onClick={onClean}
              />
            </li>
            <li>
              <PopoverButton
                label="Nuevos productos"
                endIcon={<IconFlash />}
                as={Link}
                title="Nuevos productos"
                to="/c/nuevos"
                onClick={onClean}
              />
            </li>
            <li>
              <PopoverButton
                label="Nuestra marca"
                title="Nuestra marca"
                as={Link}
                to="/c/nuestra-marca"
                onClick={onClean}
              />
            </li>
            {itemCategories?.map((category) => (
              <li key={category.slug}>
                <PopoverButton as={Link} label={category.name} to={category.slug} onClick={onClean} />
              </li>
            ))}
          </ul>
        </StyledWrapperCatgoriesAndHubs>
      )}

      {value.length > 0 && resultsSearch && resultsSearch?.length >= 1 && (
        <>
          <StyledWrapperButton>
            <Button
              sizeVariant="lg"
              fullWidth
              color="secondary"
              variant="outlined"
              onClick={() => handleClickSuggest(value)}
            >
              Ver todos los resultados
            </Button>
          </StyledWrapperButton>
        </>
      )}
    </Card>
  );
};

export default DropdownSearchInput;
