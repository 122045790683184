import posthog from "posthog-js";
import detectDeviceType from "./detectDeviceType";
import { formatDate } from "./date";
import { type IProduct } from "@/Pages/Product/type";
import type {
  ISendEventAddPaymentInfo,
  ISendEventGenerateLeadForm,
  ISendEventPurchaseDiscountInfo,
  ISendEventPurchaseUserInfo,
  ISendEventShipingInfoAddress,
  ISendEventShipingInfoMethod,
  ISendEventShipingInfoUser,
  ISendEventViewPromotionBasic,
  TUserType,
} from "@/types/analytics";

// Used for get the category page in useAnalytics Hook
const allPagesViewPage = [
  {
    startWith: "/p/",
    page: "product",
  },
  {
    startWith: "/checkout/confirm-cart",
    page: "checkout",
  },
  {
    startWith: "/c/",
    page: "category",
  },
  {
    startWith: "/h/",
    page: "hub",
  },
  {
    startWith: "/b/",
    page: "brand",
  },
  {
    startWith: "/search",
    page: "search",
  },
  {
    startWith: "/orders/",
    page: "order",
  },
  {
    startWith: "/orders",
    page: "orders",
  },
  {
    startWith: "/profile",
    page: "profile",
  },
  {
    startWith: "/profile/plus",
    page: "plus",
  },
  {
    startWith: "/referral",
    page: "referral",
  },
  {
    startWith: "/payment-method/validate",
    page: "payment-method-validate",
  },
  {
    startWith: "/referred",
    page: "referred",
  },
  {
    startWith: "/terms",
    page: "terms",
  },
  {
    startWith: "/policy",
    page: "policy",
  },
  {
    startWith: "/flash",
    page: "flash",
  },
  {
    startWith: "/gift-cards/buy",
    page: "gift-card-buy",
  },
  {
    startWith: "/gift-cards/redeem",
    page: "gift-card-redeem",
  },
  {
    startWith: "/promo/",
    page: "promo",
  },
];

const getMultipleProductInfo = (products: Cart[]) => {
  let value = 0;

  for (let index = 0; index < products.length; index++) {
    const item = products[index];
    value += (item?.availability?.price?.gross ?? 0) * (item?.quantity ?? 1);
  }

  return {
    currency: products?.[0]?.availability?.price?.currency,
    value,
    items: products.map((item) => ({
      item_id: item?.pk,
      item_name: item?.details?.name,
      quantity: item?.quantity,
      price: item?.availability?.price?.gross,
    })),
  };
};

const getProductInfo = (product: Cart) => {
  return {
    currency: product?.availability?.price?.currency,
    value: (product?.availability?.price?.gross ?? 0) * (product?.quantity ?? 0),
    items: [
      {
        item_id: product?.pk,
        item_name: product?.details?.name,
        item_brand: product?.details?.brand,
        item_category: product?.details?.categories?.edges?.[0]?.node?.name, // NOTE: This value just exist in product page endpoint used in view_item
        item_category2: product?.details?.categories?.edges?.[1]?.node?.name, // NOTE: This value just exist in product page endpoint used in view_item
        item_variant: undefined, // NOTE: This value doesnt exist
        item_size: undefined, // NOTE: This value doesnt exist
        quantity: product?.quantity,
        price: product?.availability?.price?.gross,
      },
    ],
  };
};

const validateExistGtag = () => {
  return window?.gtag !== undefined;
};

export const identify = ({ id, user }: IdentifyProps): void => {
  // We need to merge users in Recombee, so previous userId (anonymous) will merge with new user
  // https://docs.recombee.com/api.html#users - Merge users
  // TODO: By the time, MergeUsers can only be performed on server side
  // const prevId = posthog.get_distinct_id();
  // recombeeClient.send(new window.recombee.MergeUsers(id, prevId, true));

  // Crisp
  // Set user public id to identify the user in Crisp
  window.CRISP_TOKEN_ID = id;

  if (window?.$crisp) {
    window.$crisp.push(["do", "session:reset"]);
    window.$crisp.push(["set", "user:email", [user.email]]);
    window.$crisp.push(["set", "user:phone", [user.phone]]);
    window.$crisp.push(["set", "user:nickname", [`${user.firstName} ${user.lastName}`]]);
  }
  if (window?.gtag) {
    window.gtag("set", "userId", id);
  }

  posthog.identify(id);
  posthog.people.set({ email: user.email, firstName: user.firstName, telephone: user.phone });

  // Klaviyo
  if (window?._learnq) {
    window._learnq.push([
      "identify",
      {
        id,
        $email: user.email,
        $first_name: user.firstName,
        $last_name: user.lastName,
        $phone_number: user.phone,
        created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
        membership: user.membership === undefined ? null : user.membership,
      },
    ]);
  }

  if (window?.frizbit) {
    window.frizbit?.identify(id, {
      email: user.email,
      name: `${user.firstName} ${user.lastName}`,
      phone: user.phone,
      smsOptin: true,
      emailOptin: true,
      created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
    });
  }
};

/* Page view */
const sendEventLocationPageView = () => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_title: document.title,
    page_location: window.location.href,
  });
};

const sendEventPathnamePageView = (pathname: string) => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_title: document.title,
    page_path: pathname,
  });
};

const sendEventTimingPageView = () => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_load_time: performance?.now(),
  });
};

const sendEventScrollPageView = (scrollDepth: number) => {
  if (!validateExistGtag()) return;

  window.gtag("event", "page_view", {
    page_title: document.title,
    scroll_depth: `${scrollDepth}%`,
  });
};

const sendEventPageViewCategory = (pathname: string, userType: TUserType) => {
  if (!validateExistGtag()) return;

  const sendEvent = (pageCategory: string) => {
    window.gtag("event", "page_view", {
      page_title: document.title,
      page_location: window.location.href,
      user_type: userType, // 'logged_in' o 'guest'
      page_category: pageCategory, // ejemplo: 'product' , 'home', 'category', 'checkout']
      device_type: detectDeviceType(), // 'mobile'  o 'desktop', 'tablet'
    });
  };

  if (pathname === "/") {
    sendEvent("home");
  }

  for (let index = 0; index < allPagesViewPage.length; index++) {
    const item = allPagesViewPage[index];

    if (pathname.startsWith(item.startWith)) {
      sendEvent(item.page);
      break;
    }
  }
};

export const pageView = {
  eventLocation: sendEventLocationPageView,
  eventPathname: sendEventPathnamePageView,
  eventTiming: sendEventTimingPageView,
  eventPageScroll: sendEventScrollPageView,
  eventPageCategory: sendEventPageViewCategory,
};

/* Add shipping info */
const sendEventShippingInfoMethod = ({
  currency,
  value,
  shippingMethod,
  estimatedDeliveryDate,
}: ISendEventShipingInfoMethod) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_shipping_info", {
    currency,
    value,
    shipping_method: shippingMethod,
    estimated_delivery_date: formatDate(estimatedDeliveryDate, "YYYY-MM-DD"),
  });
};

const sendEventShippingInfoUser = ({
  currency,
  value,
  userId,
  userType,
  isFirstPurchase,
}: ISendEventShipingInfoUser) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_shipping_info", {
    currency,
    value,
    user_type: userType,
    user_id: userId,
    is_first_purchase: isFirstPurchase,
  });
};

const sendEventShippingInfoAddress = ({ currency, value, shippingAddress }: ISendEventShipingInfoAddress) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_shipping_info", {
    currency,
    value,
    shipping_address: shippingAddress,
  });
};

export const addShippingInfo = {
  eventInfoMethod: sendEventShippingInfoMethod,
  eventInfoUser: sendEventShippingInfoUser,
  eventInfoAddress: sendEventShippingInfoAddress,
};

/* Add payment info */
const sendEventAddPaymentInfo = ({
  currency,
  value,
  paymentType,
  cardType,
  cardLastFour,
}: ISendEventAddPaymentInfo) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_payment_info", {
    currency,
    value,
    payment_type: paymentType, // pse | credit_card | bank_terminal
    card_type: cardType,
    card_last_four: cardLastFour,
  });
};

export const addPaymentInfo = {
  eventPaymentInfo: sendEventAddPaymentInfo,
};

/* Add cart to cart */
// Use this function only if you add two or more products, if its just one product then use `sendEventCartProduct`
// NOTE: If is multiple doesnt if use sendEventCartProduct and sendEventCartDiscountInfo from here
const sendEventCartMultipleProducts = (products: Cart[]) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_to_cart", getMultipleProductInfo(products));
};

const sendEventCartProduct = (product: Cart) => {
  if (!validateExistGtag()) return;
  sendEventCartDiscountInfo(product);
  window.gtag("event", "add_to_cart", getProductInfo(product));
};

const sendEventCartDiscountInfo = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "add_to_cart", {
    currency: product?.availability?.price?.currency,
    value: (product?.availability?.price?.gross ?? 0) * (product?.quantity ?? 0),
    items: [
      {
        item_id: product?.pk,
        item_name: product?.details?.name,
        original_price: product.availability.priceUndiscounted.gross,
        discount: product.availability.discount.gross,
        coupon: undefined, // NOTE: This value doesnt exist
        quantity: product?.quantity,
        price: product?.availability?.price?.gross,
      },
    ],
  });
};

export const addToCart = {
  eventMultipleProducts: sendEventCartMultipleProducts,
  eventProduct: sendEventCartProduct,
};

/* Begin checkout */
const sendEventCheckoutCartInfo = (cartProducts: Cart[], total: number) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "begin_checkout", {
    currency: cartProducts?.[0]?.availability?.price?.currency,
    value: total,
    items: cartProducts.map((item) => ({
      item_id: item?.pk,
      item_name: item?.details?.name,
      quantity: item?.quantity,
      price: item?.availability?.price?.gross,
    })),
  });
};

const sendEventCheckoutUserInfo = ({
  currency,
  value,
  userId,
  userType,
  isFirstPurchase,
}: ISendEventShipingInfoUser) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "begin_checkout", {
    currency,
    value,
    userId,
    userType,
    isFirstPurchase,
  });
};

export const beginCheckout = {
  eventCartInfo: sendEventCheckoutCartInfo,
  eventUserInfo: sendEventCheckoutUserInfo,
};

/* Sign up */
const sendEventSignUpUserInfo = ({ method, userId }: { method: string; userId?: string }) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "sign_up", {
    method, // 'email' or 'telephone'
    user_id: userId,
    user_type: "new", // NOTE: What are the other options?
  });
};

export const signUp = {
  eventUserInfo: sendEventSignUpUserInfo,
};

/* Remove cart */
// NOTE: This function is not used because we do not have an option to remove multiple products
const sendEventRemoveCartMultipleProducts = (products: Cart[]) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "remove_from_cart", getMultipleProductInfo(products));
};

const sendEventRemoveCartProduct = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "remove_from_cart", getProductInfo(product));
};

export const removeFromCart = {
  eventMultipleProducts: sendEventRemoveCartMultipleProducts,
  eventProduct: sendEventRemoveCartProduct,
};

/* View cart */
const sendEventViewCartMultipleProducts = (products: Cart[]) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_cart", getMultipleProductInfo(products));
};

const sendEventViewCartProduct = (product: Cart) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_cart", getProductInfo(product));
};

const sendEventViewCartUser = ({
  product,
  userType,
  userId,
}: {
  product: Cart;
  userType: TUserType;
  userId: string;
}) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_cart", {
    ...getMultipleProductInfo([product]),
    user_type: userType,
    user_id: userId,
  });
};

export const viewCart = {
  eventMultipleProducts: sendEventViewCartMultipleProducts,
  eventProduct: sendEventViewCartProduct,
  eventUserInfo: sendEventViewCartUser,
};

/* View promotion */
const sendEventViewPromotionBasic = (promotions: ISendEventViewPromotionBasic) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_promotion", {
    promotions: promotions.map((item) => ({
      promotion_id: item.promotionId,
      promotion_name: item.promotionName,
      creative_name: item.creativeName,
      creative_slot: item.creativeSlot,
      location_id: item.locationId,
    })),
  });
};

export const viewPromotion = {
  eventBasic: sendEventViewPromotionBasic,
};

/* View item */
const sendEventViewItemInfo = (product: IProduct) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "view_item", getProductInfo({ ...product, quantity: 1 }));
};

export const viewItem = {
  eventViewItemInfo: sendEventViewItemInfo,
};

/* Login */
const sendEventLogin = ({ userId }: { userId: string }) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "login", {
    method: "email",
    user_id: userId,
    user_type: "logged_in", // NOTE: It is always logged_in
  });
};

export const login = {
  loginBasic: sendEventLogin,
};

/* Purchase */
const sendEventPurchaseUserInfo = ({
  transactionId,
  value,
  currency,
  userId,
  userType,
  items,
}: ISendEventPurchaseUserInfo) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "purchase", {
    transaction_id: transactionId,
    value,
    currency,
    user_id: userId,
    user_type: userType,
    items,
  });
};

const sendEventPurchaseDiscountInfo = ({
  transactionId,
  value,
  currency,
  coupon,
  discountAmount,
  items,
}: ISendEventPurchaseDiscountInfo) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "purchase", {
    transaction_id: transactionId,
    value,
    currency,
    discount_amount: discountAmount,
    coupon,
    items,
  });
};

export const purchase = {
  userInfo: sendEventPurchaseUserInfo,
  discountInfo: sendEventPurchaseDiscountInfo,
};

/* Generate lead */
const sendEventGenerateLeadForm = ({
  value,
  currency,
  formName,
  formLength,
  formFields,
}: ISendEventGenerateLeadForm) => {
  if (!validateExistGtag()) return;
  window.gtag("event", "generate_lead", {
    currency,
    value,
    form_name: formName,
    form_length: formLength,
    form_fields: formFields,
  });
};

export const generateLead = {
  generateLeadForm: sendEventGenerateLeadForm,
};
