import React from "react";
import tw from "twin.macro";
import { useIsMobile } from "../../../../hooks/useIsMobile";

interface ModalTitleProps {
  title: string;
}
export const ModalTitle = ({ title }: ModalTitleProps): React.ReactElement => {
  const isMobile = useIsMobile();

  return (
    <div tw="pt-6 md:(pt-10)">
      <h2
        tw="font-primary font-semibold text-primary-200 leading-tight whitespace-pre-line"
        css={[isMobile ? tw`text-xl` : tw`text-3xl`]}
      >
        {title}
      </h2>
    </div>
  );
};
