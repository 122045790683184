import React from "react";
import "twin.macro";

export const CreditCard = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 17"
    tw="fill-current"
    height={24}
    width={24}
    {...props}
  >
    <defs>
      <mask x={0} y={0.79} width={23.96} height={15.01} maskUnits="userSpaceOnUse">
        <path fillRule="evenodd" fill="#000" d="M0 .79h23.96V15.8H0V.79z" />
      </mask>
    </defs>
    <g data-name="Desktop/Tienda/PaymentMethod">
      <g mask="url(#prefix__a)" data-name="002-credit-card">
        <path
          d="M2.23.79h19.5A2.22 2.22 0 0124 3v10.57a2.23 2.23 0 01-2.23 2.23H2.23A2.23 2.23 0 010 13.57V3A2.23 2.23 0 012.23.79zm16.23 8.67a2.22 2.22 0 010 4.43 2.18 2.18 0 01-1-.25 2.21 2.21 0 11-2-3.93 2.18 2.18 0 012 0 2.14 2.14 0 011-.25zM16.64 13a2.21 2.21 0 010-2.55.9.9 0 00-.23 0A1.3 1.3 0 0016.3 13h.11a1 1 0 00.24 0zm2.73-2.19a1.3 1.3 0 00-.91-.38.9.9 0 00-.23 0 2.21 2.21 0 010 2.55h.23a1.29 1.29 0 00.91-2.21zm-1.93.11a1.28 1.28 0 000 1.61 1.31 1.31 0 000-1.66zM.92 3.43H23V3a1.31 1.31 0 00-1.27-1.3H2.23A1.3 1.3 0 00.92 3zM23 7.3H.92v6.27a1.3 1.3 0 001.31 1.32h19.5A1.31 1.31 0 0023 13.58zM.92 6.38H23v-2H.92z"
          fill={props.fill || "#F96800"}
          fillRule="evenodd"
        />
      </g>
    </g>
  </svg>
);
