import type { CSSProperties, HTMLAttributes } from "react";
import type { ITypographyCommon } from "@/types/common";

import { StyledWrapperCaption } from "./styles";

export interface ICaption
  extends HTMLAttributes<HTMLParagraphElement>,
    Omit<ITypographyCommon, "sizeVariant"> {
  italic?: boolean;
  fontWeight?: CSSProperties["fontWeight"];
}

const Caption = ({ size, color, italic = false, fontWeight, ...props }: ICaption) => {
  return (
    <StyledWrapperCaption
      {...props}
      $size={size}
      $color={color}
      $isItalic={italic}
      $fontWeight={fontWeight}
    />
  );
};

export default Caption;
