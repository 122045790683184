import IconWarning from "@iconsV2/Warnings/information-circle.svg?react";

import Caption from "../Caption";
import Paragraph from "../Paragraph";
import {
  StyledWrapperBestSeller,
  StyledWrapperComingSoon,
  StyledWrapperCustomLabel,
  StyledWrapperDiscount,
  StyledWrapperIsPromoted,
  StyledWrapperNew,
} from "./styles";
import Tooltip, { TooltipContent, TooltipTrigger } from "@/components/Atoms/Tooltip";
import IconTagUnion from "@iconsV2/Illustration/tagUnion.svg?react";

interface IProductCardTagDiscountProps {
  type: "discount";
  amount: string;
}

interface IProductCardTagCustomLabelProps {
  type: "customLabel";
  label: string;
  labelBackground?: string;
}

interface IProductCardTagProps {
  type: "new" | "promoted" | "bestSeller" | "comingSoon";
}

type TProductCardTagProps = (
  | IProductCardTagDiscountProps
  | IProductCardTagCustomLabelProps
  | IProductCardTagProps
) &
  Record<string, any>;

const ProductCardTag = ({ type, ...props }: TProductCardTagProps) => {
  if (!type) {
    throw new TypeError("Type is required");
  }

  if (type === "comingSoon") {
    return (
      <StyledWrapperComingSoon {...props}>
        <Paragraph sizeVariant="sm" color="white" fontWeight="700">
          Próximamente
        </Paragraph>

        <IconTagUnion />
      </StyledWrapperComingSoon>
    );
  }

  if (type === "new") {
    return (
      <StyledWrapperNew {...props}>
        <Paragraph sizeVariant="sm" color="white" fontWeight="700">
          Nuevo
        </Paragraph>

        <IconTagUnion />
      </StyledWrapperNew>
    );
  }
  if (type === "discount") {
    return (
      <StyledWrapperDiscount {...props}>
        <Paragraph sizeVariant="sm" color="white" fontWeight="700">
          {(props as IProductCardTagDiscountProps).amount}%
        </Paragraph>

        <IconTagUnion />
      </StyledWrapperDiscount>
    );
  }

  if (type === "promoted") {
    return (
      <StyledWrapperIsPromoted {...props}>
        <Caption>Promocionado</Caption>
      </StyledWrapperIsPromoted>
    );
  }
  if (type === "bestSeller") {
    return (
      <StyledWrapperBestSeller {...props}>
        <Paragraph sizeVariant="sm" fontWeight="700" color="white">
          Más vendido
        </Paragraph>
      </StyledWrapperBestSeller>
    );
  }
  if (type === "customLabel") {
    const { labelBackground, label, ...rest } = props as IProductCardTagCustomLabelProps;

    return (
      <Tooltip placement="bottom-end">
        <TooltipTrigger>
          <StyledWrapperCustomLabel {...rest} $bg={labelBackground} $label={label}>
            <Paragraph sizeVariant="sm" fontWeight="700" color="inherit">
              {label}
            </Paragraph>

            <IconWarning />
          </StyledWrapperCustomLabel>
        </TooltipTrigger>
        <TooltipContent tw="max-w-[14.313rem] z-1">
          <Paragraph sizeVariant="sm">
            Son productos en excelente condición pero con fecha cercanas de vencimiento.
          </Paragraph>
        </TooltipContent>
      </Tooltip>
    );
  }
};

export default ProductCardTag;
