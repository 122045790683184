import tw, { styled } from "twin.macro";
import IconPassed from "@/components/Icons/V2/Controllers/radio-checked-outline-gradient.svg?react";
import IconUnpassed from "@/components/Icons/V2/Warnings/attention-triangle.svg?react";
import { type TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

interface StyledTipsWrapperProps {
  isPassed?: boolean;
}

interface StyledIconProps {
  $size?: TSize;
}

const styles = {
  icon: {
    size: {
      small: tw`w-[0.833rem] h-[0.833rem]`,
      medium: tw`h-5 w-5`,
      large: tw`h-6 w-6`,
    },
  },
};

export const StyledTipsWrapper = styled.div<StyledTipsWrapperProps>(({ isPassed }) => [
  tw`flex flex-col gap-s  p-[0.063rem] overflow-hidden rounded-lg`,
  isPassed && tw`bg-gradient-1`,
  !isPassed && tw`bg-primaryV2-70`,
]);

export const StyledTipsBody = styled.div`
  ${tw`p-2 bg-white w-full h-full rounded-lg flex flex-col gap-s`}
`;

export const StyledIconPassed = styled(IconPassed)<StyledIconProps>`
  ${tw`h-[54px] w-[54px]`}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.icon.size,
    })}
`;

export const StyledIconUnpassed = styled(IconUnpassed)<StyledIconProps>`
  ${tw`h-6 w-6 fill-primaryV2-50 `}
  ${({ $size }) =>
    applyResponsiveStyles({
      size: $size,
      stylesBreakpoints: styles.icon.size,
    })}
`;

export const StyledTips = tw.div`flex  gap-s items-center`;
