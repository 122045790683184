import React from "react";
import "twin.macro";

export const ChevronUp = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg tw="fill-current" height={props.height || 24} width={props.width || 24} viewBox="0 0 24 24" {...props}>
    <path
      fill={props.fill || "#FFF"}
      d="M18.7 15.7c-.2.2-.4.3-.7.3-.3 0-.5-.1-.7-.3L12 10.4l-5.3 5.3c-.4.4-1 .4-1.4 0-.4-.4-.4-1 0-1.4l6-6c.4-.4 1-.4 1.4 0l6 6c.4.4.4 1 0 1.4z"
    />
  </svg>
);
