import Button from "@/components/Atoms/Button";
import tw, { css, styled } from "twin.macro";

interface StyledSwiperNavButtonProps {
  $swiperDirection: "prev" | "next";
  $isCircular?: boolean;
}

const styles = {
  base: tw`flex items-center justify-center hover:shadow-elevation-3`,
  default: tw`rounded-none p-0 w-[3.75rem] h-[4rem] flex py-4 
`,
  circular: tw`rounded-full w-[2.375rem] h-[2.375rem]`,
};

export const StyledSwiperNavButton = styled(Button)<StyledSwiperNavButtonProps>`
  ${styles.base}

  ${({ $isCircular }) => ($isCircular ? styles.circular : styles.default)}

  & > span > svg {
    ${({ $isCircular }) => ($isCircular ? tw`w-3 h-3` : tw`w-4 h-4 large:(w-6 h-6)`)}
  }
  &.swiper-navigation-hidden,
  &.swiper-navigation-disabled {
    ${tw`opacity-0 hidden`}
  }

  ${({ $swiperDirection, $isCircular }) =>
    !$isCircular &&
    css`
      ${$swiperDirection === "prev" ? tw`rounded-r-[100px]` : tw`rounded-l-[100px]`}
    `}
`;
