import { CheckoutEnum } from "@/recourses/queryKeys";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import useAppSelector from "./useAppSelector";
import useAppDispatch from "./useAppDispatch";
import useCart from "./useCartV2";
import { getCartAvailableProducts } from "@/services/checkout";
import { cartStoresActions } from "@/store/cartStore";

export const useKeepCartFresh = (onChangeReValidate?: boolean) => {
  const dispatch = useAppDispatch();
  const storeId = useAppSelector((state) => state.cart.storeId);
  const { items } = useCart();

  // Get cart available products
  const { data: cartAvailables } = useQuery({
    queryKey: [CheckoutEnum.getCartAvailableProducts, String(storeId), JSON.stringify(items)],
    queryFn: async () => {
      const productsId = items.map((product) => String(product.details.sku)).join(",");
      return await getCartAvailableProducts(Number(storeId), productsId);
    },
    enabled: items.length > 0 && !!storeId,
    staleTime: 60 * 1000 * 1, // 1 minutes
  });

  useEffect(() => {
    // TODO: Check if this is necessary
    if (!cartAvailables) return;

    items.forEach((item) => {
      const product = cartAvailables?.payload.find(
        (product) => String(product.sku) === String(item.details.sku)
      );

      if (!product) {
        dispatch(
          cartStoresActions.changeQuatityAvailable({
            productId: Number(item?.pk),
            maxQtyToOrder: 0,
            isAvailable: false,
          })
        );
      }

      dispatch(
        cartStoresActions.changeQuatityAvailable({
          productId: Number(item?.pk),
          maxQtyToOrder: product?.quantity_available ?? 0,
          isAvailable: product?.is_available ?? false,
          price: product?.price ?? { gross: 0, currency: "COP" },
          preceUndiscounted: product?.price_undiscounted ?? { gross: 0, currency: "COP" },
          onSale: product?.on_sale ?? false,
        })
      );
    });
  }, [JSON.stringify(cartAvailables?.payload)]);
};

export default useKeepCartFresh;
