import { type RuleSet } from "styled-components";
import tw, { type TwStyle, css, styled } from "twin.macro";

const stylesShowCenter = css`
  ${tw`flex items-center justify-center`}
`;

const stylesShowCenterBackDrop = css`
  ${stylesShowCenter}
  ${tw`bg-modal-overlay`}
`;

export const StyledWrapperBackDrop = styled.div<{
  $isAdjust?: boolean;
  $classess?: RuleSet<object> | TwStyle;
  $showCenter?: boolean;
}>`
  ${({ $isAdjust }) => {
    if ($isAdjust) {
      return tw`absolute w-fit h-fit z-50`;
    }

    return css`
      ${stylesShowCenterBackDrop}
      ${tw`fixed inset-0 z-50 w-full h-[100dvh]`}
    `;
  }}

  ${({ $classess }) => $classess && $classess}
`;

export const StyledContent = styled.div<{ $classess?: RuleSet<object> | TwStyle }>`
  ${({ $classess }) => $classess && $classess}
`;
