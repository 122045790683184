import { useMemo } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import NotificationAlert from "@atoms/NotificationAlert";
import IconPlusLogo from "@iconsV2/Header/plus-logo.svg?react";
import { type MembershipType } from "@/services/membership/type";
import { listMemberships } from "@/services/membership";
import { useMembership } from "@/hooks";
import { formatCurrency } from "@/utils/money";
import { MembershipEnum } from "@/recourses/queryKeys";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { IS_ACTIVE_PLUS } from "@/recourses/constants";

const NotificationPlus = ({ handleClose }: { handleClose: () => void }) => {
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const [hasMembership] = useMembership();

  const canShowPlusBar = !hasMembership && IS_ACTIVE_PLUS;

  const { data: listMembershipInfo, isLoading: isLoadingListMembershipInfo } = useQuery({
    queryKey: [MembershipEnum.listMemebership],
    queryFn: listMemberships,
    enabled: canShowPlusBar,
  });

  const cheapMembershipResult = useMemo(() => {
    if (!listMembershipInfo || isLoadingListMembershipInfo) return undefined;

    let cheapMembership: MembershipType | undefined = listMembershipInfo?.results?.[0];

    listMembershipInfo.results.forEach((membership: MembershipType) => {
      if (membership.is_available || membership.is_available === undefined) {
        const cheapMembershipPrice =
          cheapMembership && +cheapMembership?.price.gross / cheapMembership?.duration;
        const membershipPrice = +membership.price.gross / membership.duration;
        if (cheapMembershipPrice && membershipPrice < cheapMembershipPrice) {
          cheapMembership = membership;
        }
      }
    });

    return cheapMembership && +cheapMembership?.price.gross / cheapMembership?.duration;
  }, [listMembershipInfo, isLoadingListMembershipInfo]);

  return (
    <Link to="/plus">
      <NotificationAlert
        startIcon={<IconPlusLogo />}
        title={
          !isMedium && cheapMembershipResult
            ? `Disfruta Plus desde ${formatCurrency(Math.trunc(+cheapMembershipResult))}/ mes`
            : "Una membresía exclusiva con beneficios increíbles"
        }
        handleClose={handleClose}
      />
    </Link>
  );
};

export default NotificationPlus;
