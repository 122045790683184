import { type RouteObject } from "react-router-dom";

const newRoutes: RouteObject[] = [
  {
    index: true,
    lazy: async () => {
      const { default: Home } = await import("@pages/Home");
      return {
        element: <Home />,
      };
    },
  },
  {
    path: "search",
    lazy: async () => {
      const { loader, default: Search } = await import("@pages/Search");
      return {
        loader,
        element: <Search />,
      };
    },
  },
  {
    path: "/p/:productSlug",
    lazy: async () => {
      const { default: Product } = await import("@/Pages/Product");
      return {
        element: <Product />,
      };
    },
  },
  {
    path: "c/:categorySlug",
    lazy: async () => {
      const { loader, default: Category } = await import("@/Pages/Category");
      return {
        loader,
        element: <Category />,
      };
    },
  },
  {
    path: "h/:hubSlug",
    lazy: async () => {
      const { loader, default: Hub } = await import("@/Pages/Hub");
      return {
        loader,
        element: <Hub />,
      };
    },
  },
  {
    path: "b/:brandSlug",
    lazy: async () => {
      const { loader, default: Brand } = await import("@/Pages/Brand");
      return {
        loader,
        element: <Brand />,
      };
    },
  },
];

export default newRoutes;
