import { request } from "../../utils/request";
import {
  type ListMemebershipsType,
  type Subscribe,
  type ListUserMemebershipsType,
  type PartnershipListResult,
} from "./type";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;
const LIST_MEMBERSHIPS = "membership-plans/";
const SUBSCRIBE_MEMBERSHIP = "memberships/";
const CANCEL_MEMBERSHIP = "cancel/";
const REACTIVATE_MEMBERSHIP = "reactivate/";
const UPDATE_PAYMENT_METHOD = "/payment-method/update/";
const HISTORY_PAYMENT_METHOD = "/payments/";
const PARTNERSHIPS = "/partnerships/";

const listPartnerships = async (nextPage = null): Promise<PartnershipListResult> => {
  const response = await request<PartnershipListResult>(nextPage || `${BASE_API_URL}api/v1${PARTNERSHIPS}`, {
    method: "GET",
  });
  return response.data;
};

const listMemberships = async (): Promise<ListMemebershipsType> => {
  const response = await request<ListMemebershipsType>(`${BASE_API_URL}api/v1/${LIST_MEMBERSHIPS}`, {
    method: "GET",
  });
  return response.data;
};

const listUserMemberships = async (): Promise<ListUserMemebershipsType> => {
  const response = await request<ListUserMemebershipsType>(`${BASE_API_URL}api/v1/${SUBSCRIBE_MEMBERSHIP}`, {
    method: "GET",
  });
  return response.data;
};

const SubscribeToMembership = async ({ paymentMethod, planMembership }: Subscribe): Promise<any> => {
  const response = await request<any>(`${BASE_API_URL}api/v1/${SUBSCRIBE_MEMBERSHIP}`, {
    method: "POST",
    data: {
      user_payment_method: paymentMethod,
      plan: planMembership,
    },
  });
  return response.data;
};

const CancelMembership = async ({
  membershipId,
  cancel_reason,
}: {
  membershipId: string;
  cancel_reason: string;
}): Promise<any> => {
  const response = await request<any>(
    `${BASE_API_URL}api/v1/${SUBSCRIBE_MEMBERSHIP}${membershipId}/${CANCEL_MEMBERSHIP}`,
    {
      method: "POST",
      data: { cancel_reason },
    }
  );
  return response.data;
};

const ReActivaiteMembership = async ({ membershipId }: { membershipId: string }): Promise<any> => {
  const response = await request<any>(
    `${BASE_API_URL}api/v1/${SUBSCRIBE_MEMBERSHIP}${membershipId}/${REACTIVATE_MEMBERSHIP}`,
    {
      method: "POST",
    }
  );
  return response.data;
};

const UpdatePaymentMethodMembership = async ({
  membershipId,
  paymentMethodId,
}: {
  membershipId: string;
  paymentMethodId: number;
}): Promise<any> => {
  const response = await request<any>(
    `${BASE_API_URL}api/v1/${SUBSCRIBE_MEMBERSHIP}${membershipId}${UPDATE_PAYMENT_METHOD}`,
    {
      method: "POST",
      data: {
        user_payment_method: paymentMethodId,
      },
    }
  );
  return response.data;
};

const getPaymentHistory = async (nextPage = null, filters = { limit: 5 }, membershipId): Promise<any> => {
  const response = await request<any>(
    nextPage || `${BASE_API_URL}api/v1/${SUBSCRIBE_MEMBERSHIP}${membershipId}${HISTORY_PAYMENT_METHOD}`,
    {
      method: "GET",
      timeout: 10000,
      params: filters,
    }
  );
  return response.data;
};

export {
  listPartnerships,
  listMemberships,
  listUserMemberships,
  SubscribeToMembership,
  CancelMembership,
  ReActivaiteMembership,
  UpdatePaymentMethodMembership,
  getPaymentHistory,
};
