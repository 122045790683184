import Paragraph from "@/components/Atoms/Paragraph";
import { StyledAction, StyledFormSecurity, StyledFormSecurityDetail, StyledTypeCodeActions } from "./styles";
import IconLock from "@components/Icons/V2/Warnings/lock.svg?react";
import ConfirmCodeController, { type FormDataConfirmCode } from "@components/Molecules/ConfirmCodeController";
import { useForm } from "custom-hook-form";
import Button from "@/components/Atoms/Button";
import TogglePasswordInput from "@/components/Atoms/TogglePasswordInput";
import Notification, { type NotificationProps } from "@/components/Atoms/Notification";
import { useCountDown } from "@/hooks";
import IconLoader from "@/components/Icons/V2/Loader/loader-light.svg?react";
import IconDevice from "@/components/Icons/V2/Devices/mobile-02-gradient.svg?react";
import Heading from "@/components/Atoms/Heading";
import React from "react";

interface FormSecurityProps {
  onSubmit: (data?: FormDataConfirmCode) => Promise<void>;
  type?: "code" | "password";
  executeError?: string | string[];
  textHelp?: {
    messages: NotificationProps["messages"];
    type: NotificationProps["type"];
  };
  processDone?: boolean;
  sendTo?: string;
  onResend?: () => void;
}
const FormSecurity = ({
  onSubmit: onSubmitProps,
  type = "password",
  textHelp,
  processDone = true,
  sendTo,
  onResend,
}: FormSecurityProps) => {
  const [countExpired, reset] = useCountDown(60);

  const { control, handleSubmit, formState, register } = useForm<FormDataConfirmCode>({
    mode: "onSubmit",
    defaultValues: {
      code: "",
    },
  });

  const onSubmit = async (data: FormDataConfirmCode) => {
    await onSubmitProps?.(data);
  };

  return (
    <StyledFormSecurity onSubmit={handleSubmit(onSubmit)}>
      <StyledFormSecurityDetail>
        {type === "password" ? (
          <>
            <IconLock width={60} height={60} aria-label="icono de candado" />
            <Paragraph sizeVariant="md">Por tu seguridad, ingresa la siguiente información</Paragraph>
            <TogglePasswordInput
              placeHolder="Ingresa tu contraseña"
              label="Por favor, ingresa tu contraseña para continuar "
              {...register("code", { required: true })}
            />
          </>
        ) : (
          <>
            <IconDevice width={60} height={60} aria-label="icono de  telefono" />
            <Heading type="h2">Confirmar número de contacto</Heading>

            <Paragraph sizeVariant="md">
              Ingresa el código que te hemos enviado a tu número de contacto <b>+{sendTo}</b>
            </Paragraph>

            <ConfirmCodeController controller={{ control }} label="Ingresa el código" />
          </>
        )}
      </StyledFormSecurityDetail>

      <StyledAction>
        {textHelp && <Notification messages={textHelp.messages} type={textHelp.type} />}
        <Button
          sizeVariant="lg"
          color="secondary"
          disabled={!formState.isValid || !processDone}
          type="submit"
        >
          {processDone && "Continuar"}
          {!processDone && <IconLoader tw="w-8 h-8 animate-spin" />}
        </Button>

        {type === "code" && (
          <StyledTypeCodeActions>
            <Notification
              type="info"
              messages={
                countExpired === 0
                  ? "Este código ya no es válido."
                  : `El código es válido por ${countExpired} segundos`
              }
              hFull
            />
            <Button
              disabled={countExpired !== 0}
              variant="outlined"
              color="secondary"
              sizeVariant="md"
              onClick={() => {
                reset();
                onResend?.();
              }}
            >
              Reenviar código
            </Button>
          </StyledTypeCodeActions>
        )}
      </StyledAction>
    </StyledFormSecurity>
  );
};
export default FormSecurity;
