import tw, { styled } from "twin.macro";
import Loader from "@/components/Icons/V2/Loader/loader.svg?react";
import CheckedIcon from "@iconsV2/Controllers/radio-checked-primary.svg?react";

export const StyledWrapperAddresForm = styled.div`
  ${tw`max-w-lg grid gap-m relative max-h-[90dvh] overflow-auto`}
`;

export const StyledWrapperHeader = styled.div`
  ${tw`flex justify-between items-center h-[5rem] bg-secondary-50 px-m`}
`;

export const StyledSectionSelect = styled.div`
  ${tw`grid gap-m p-m pt-0`}
`;

export const StyledAddresFormLoader = styled.div`
  ${tw`absolute h-full w-full top-0 bottom-0 grid place-content-center bg-white/40`}
`;

export const StyledLoaderLoading = styled(Loader)`
  ${tw`animate-spin w-14 h-14`}
`;

export const StyledLoaderCheked = styled(CheckedIcon)`
  ${tw`w-14 h-14`}
`;
