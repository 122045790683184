import "twin.macro";
import { Outlet } from "react-router-dom";

import Footer from "@/components/Organisms/Footer";
import Header from "@/components/Organisms/Header";
import PromoModal from "@/components/Organisms/PromoModal";
import { type ReactNode } from "react";
interface MainLayoutProps {
  children?: ReactNode;
}

const MainLayout = ({ children }: MainLayoutProps) => {
  return (
    <>
      <Header />
      <PromoModal />

      {!children ? <Outlet /> : children}

      <Footer />
    </>
  );
};

export default MainLayout;
