import { Navigate, type RouteObject } from "react-router-dom";

export const maintenanceRoutes: RouteObject[] = [
  {
    path: "/",
    lazy: async () => {
      const Maintenance = (await import("@/Pages/Maintenance")).default;
      return {
        element: <Maintenance />,
      };
    },
  },
];

export const checkoutRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate replace to="/checkout/confirm-cart" />,
  },
  {
    path: "confirm-cart",
    lazy: async () => {
      const { ConfirmCart } = await import("@/screens/Checkout/ConfirmCart");
      return {
        element: <ConfirmCart />,
      };
    },
  },
  {
    path: "confirm-payment",
    lazy: async () => {
      const { ConfirmPayment } = await import("@/screens/Checkout/ConfirmPayment");
      return {
        element: <ConfirmPayment />,
      };
    },
  },
  {
    path: "confirm-checkout",
    lazy: async () => {
      const { ConfirmCheckout } = await import("@/screens/Checkout/ConfirmCheckout");
      return {
        element: <ConfirmCheckout />,
      };
    },
  },
];
