import { useState } from "react";
import tw from "twin.macro";
import Button from "@atoms/Button";
import OrderButton from "@atoms/OrderButton";
import Popover, { PopoverContent, PopoverTrigger } from "@atoms/Popover";
import ShoppingCartMenu from "@/components/Molecules/ShoppingCartMenu";
import IconChevronLeft from "@iconsV2/Arrows/chevron-left-normal.svg?react";
import useCart from "@/hooks/useCartV2";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { viewCart } from "@/utils/analyticsV2";
import useAnalytics from "@/hooks/useAnalytics";

const ShoppingCart = ({ isScrolled = false }: { isScrolled?: boolean }) => {
  const [openPopover, setOpenPopover] = useState(false);
  const isMedium = useMediaQuery("( min-width: {medium} )");
  const { totalCountCart, items } = useCart();
  const { viewCart: viewCartHook } = useAnalytics();

  const handleToggleOpen = () => {
    // If currently it is false is because it will set to true
    if (!openPopover) {
      if (items.length > 0) {
        viewCart.eventMultipleProducts(items);
      }

      for (let index = 0; index < items.length; index++) {
        const item = items[index];
        viewCart.eventProduct(item);
        viewCartHook.eventUserInfo(item);
      }
    }

    setOpenPopover(!openPopover);
  };

  return (
    <Popover
      placement="bottom-end"
      open={openPopover}
      hidden={false}
      lockScroll={!isMedium}
      onOpenChange={handleToggleOpen}
    >
      <PopoverTrigger>
        <OrderButton
          sizeVariant="lg"
          counter={totalCountCart}
          onClick={handleToggleOpen}
          isScrolled={isScrolled}
        />
      </PopoverTrigger>

      <PopoverContent
        classes={{
          root: !isMedium
            ? tw`!(fixed inset-0 transform translate-x-0 w-full h-[100dvh] p-0 flex flex-col z-[52] bg-neutral-99)`
            : tw`shadow-elevation-3 border border-neutral-90 p-0 flex flex-col rounded-lg`,
        }}
      >
        <div tw="w-full px-1 py-2 flex medium:(hidden)">
          <Button
            sizeVariant="lg"
            onClick={() => setOpenPopover(false)}
            color="secondary"
            variant="text"
            startIcon={<IconChevronLeft />}
            type="button"
          >
            Regresar
          </Button>
        </div>

        <ShoppingCartMenu onAction={handleToggleOpen} onClose={() => setOpenPopover(false)} />
      </PopoverContent>
    </Popover>
  );
};

export default ShoppingCart;
