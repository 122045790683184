import { Navigate, useParams, type RouteObject } from "react-router-dom";

// Redirects
const RedirectHub = () => {
  const { hubsSlug } = useParams();
  return <Navigate to={`/h/${hubsSlug}`} replace />;
};

const RedirectCategory = () => {
  const { categorySlug } = useParams();
  return <Navigate to={`/c/${categorySlug}`} replace />;
};

const RedirectBrand = () => {
  const { brandSlug } = useParams();
  return <Navigate to={`/b/${brandSlug}`} replace />;
};

const RedirectProduct = () => {
  const { productSlug } = useParams();
  return <Navigate to={`/p/${productSlug}`} replace />;
};

const RedirectHome = () => {
  return <Navigate to={`/`} replace />;
};

// TODO: Delete component after migration
const publicRoutes: RouteObject[] = [
  // {
  //   index: true,
  //   element: <Navigate replace to="/stores/market" />,
  // },
  {
    path: "stores/:type/",
    Component: RedirectHome,
  },
  {
    path: "terms",
    lazy: async () => {
      const { TermsAndConditions } = await import("@/screens/Politics/TermsAndConditions");
      return {
        element: <TermsAndConditions />,
      };
    },
  },
  {
    path: "policy",
    lazy: async () => {
      const { PersonalDataPolicy } = await import("@/screens/Politics/PersonalDataPolicy");
      return {
        element: <PersonalDataPolicy />,
      };
    },
  },
  {
    path: "flash",
    lazy: async () => {
      const { Flash } = await import("@/screens/Flash");
      return {
        element: <Flash />,
      };
    },
  },
  {
    path: "stores/:type/categories",
    lazy: async () => {
      const { Categories } = await import("@/screens/Categories");
      return {
        element: <Categories />,
      };
    },
  },
  // {
  //   path: "search",
  //   lazy: async () => {
  //     const { Search } = await import("@/screens/Search");
  //     return {
  //       element: <Search />,
  //     };
  //   },
  // },
  {
    path: "gift-cards/buy",
    element: <Navigate to="/gift-cards/redeem" replace />,
  },
  {
    path: "gift-cards/redeem",
    lazy: async () => {
      const { RedeemGiftCard } = await import("@/screens/GiftCard");
      return {
        element: <RedeemGiftCard />,
      };
    },
  },
  {
    path: "stores/:type/c/:categorySlug",
    Component: RedirectCategory,
  },
  {
    path: "stores/:type/c/:categorySlug/:categorySlug",
    Component: RedirectCategory,
  },
  {
    path: "stores/:type/b/:brandSlug",
    Component: RedirectBrand,
  },
  {
    path: "stores/:type/h/:hubsSlug",
    Component: RedirectHub,
  },
  {
    path: "stores/:type/p/:productSlug",
    Component: RedirectProduct,
  },
  {
    path: "promo/:id",
    lazy: async () => {
      const { Promo } = await import("@/screens/Promo");
      return {
        element: <Promo />,
      };
    },
  },
  {
    path: "stores/restaurant",
    element: <Navigate to="/stores/market/c/restaurante" replace />,
  },
  {
    path: "orders/:orderId/rate",
    lazy: async () => {
      const { RateOrder } = await import("@/screens/RateOrder");
      return {
        element: <RateOrder />,
      };
    },
  },
];

/*
// import { PlusHome, PlusTermsAndCondition, PlusBuy } from "../screens/Plus";

<Route path="/plus">
      <PlusHome />
</Route>
<Route path="/plus/terms">
    <PlusTermsAndCondition />
</Route>
<Route path="/plus/buy">
    <PlusBuy />
</Route> */

export default publicRoutes;
