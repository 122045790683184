import { type IButton } from "@/components/Atoms/Button";
import { StyledSwiperNavButton } from "./styles";
import IconChevronLeft from "@iconsV2/Arrow/chevron-left-normal.svg?react";
import IconChevronRight from "@iconsV2/Arrow/chevron-right-normal.svg?react";

interface SwiperNavButtonsProps {
  type: "prev" | "next";
  buttonProps?: Omit<IButton, "sizeVariant">;
  isCircular?: boolean;
}
const SwiperNavButtons = ({ type = "next", isCircular, buttonProps }: SwiperNavButtonsProps) => {
  return (
    <StyledSwiperNavButton
      $isCircular={isCircular}
      $swiperDirection={type}
      sizeVariant={"lg"}
      variant={buttonProps?.variant ?? "outlined"}
      color={buttonProps?.color ?? "primary"}
      endIcon={type === "next" ? <IconChevronRight /> : <IconChevronLeft />}
      {...buttonProps}
    ></StyledSwiperNavButton>
  );
};

export default SwiperNavButtons;
