import { Outlet } from "react-router-dom";

import Header from "@/components/Molecules/HeaderCheckout";
import Footer from "@/components/Organisms/Footer";

const CheckoutLayout = () => {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default CheckoutLayout;
