import Heading from "@/components/Atoms/Heading";
import {
  StyledActionsWrapper,
  StyledBodyInfoModal,
  StyledNotificationBox,
  StyledPrimaryActionsWrapper,
  StyledWrapperContent,
  StyledWrapperRight,
} from "../AuthStepsModal/styles";
import Paragraph from "@/components/Atoms/Paragraph";
import Label from "@/components/Atoms/Label";
import Button from "@/components/Atoms/Button";
import { SyledResendCodeContainer } from "./styles";
import { Controller, type UseFormMethods } from "react-hook-form";
import { type AuthStep } from "@/components/Navbar/AuthModal/types";
import { useCountDown } from "@/hooks";
import DigitCode from "@/components/Molecules/DigitCode";
import IconAuth from "@/components/Atoms/IconAuth";
import IconClose from "@/components/Icons/V2/Edit/close.svg?react";
import { useEffect } from "react";
import Notification from "@/components/Atoms/Notification";
import StepOrBackHeader from "@/components/Atoms/StepOrBackHeader/inde";
import { useMediaQuery } from "@/hooks/useMediaQuery";

interface ConfirmPhoneCodeProps {
  controller: Pick<UseFormMethods, "control" | "errors" | "formState">;
  phoneNumber: string;
  setStep: React.Dispatch<React.SetStateAction<AuthStep>>;
  type?: "signUp" | "recovery";
  loading: boolean;
  fetchingError: string[] | string;
  cleanErrors: () => void;
  onClose: () => void;
  isMobile?: boolean;
}

const iconType: Record<"signUp" | "recovery", "user" | "exit"> = {
  signUp: "user",
  recovery: "exit",
};
const ConfirmPhoneCode = ({
  controller: { control, formState },
  phoneNumber,
  setStep,
  type = "signUp",
  loading,
  fetchingError,
  cleanErrors,
  onClose,
  isMobile,
}: ConfirmPhoneCodeProps) => {
  const isLarge = useMediaQuery("(min-width: {large})");
  const [count] = useCountDown(30);
  const [countExpired] = useCountDown(60);

  function handleClick(): void {
    if (type === "recovery") setStep("phoneNumberPassword");
    else {
      setStep("phoneNumber");
    }
  }

  useEffect(() => {
    if (fetchingError?.length > 0) {
      setTimeout(() => {
        cleanErrors();
      }, 5000);
    }
  }, [fetchingError]);

  return (
    <>
      <StyledWrapperRight>
        <StyledWrapperContent>
          {!isMobile && <IconClose tw="h-ml w-ml justify-self-end cursor-pointer" onClick={onClose} />}
          {isMobile && <StepOrBackHeader onBack={() => setStep("login")} />}
          <IconAuth type={iconType[type]} />
          <StyledBodyInfoModal>
            <Heading type="h2">{type === "signUp" ? `Crear cuenta` : `Reestablecer tu contraseña`}</Heading>
            <Paragraph sizeVariant="md">
              Ingresa el código que te hemos enviado a tu número de contacto<b>{phoneNumber}</b>
            </Paragraph>
          </StyledBodyInfoModal>
          <Label sizeVariant="md">Ingresa el código</Label>

          <Controller
            name="phoneCode"
            defaultValue=""
            render={({ onChange, value }): React.ReactElement => (
              <DigitCode onChange={onChange} value={value} size={isLarge ? "large" : "medium"} />
            )}
            control={control}
            rules={{
              validate: (value: string): boolean => value.replace(/\s/g, "").length === 4,
              required: true,
              maxLength: 4,
              minLength: 4,
            }}
          />
          <Notification
            type="info"
            messages={
              countExpired === 0
                ? "Este código ya no es válido."
                : `El código es válido por ${countExpired} segundos`
            }
            hFull
          />
        </StyledWrapperContent>

        <StyledActionsWrapper>
          <StyledPrimaryActionsWrapper>
            <Button
              sizeVariant="lg"
              color="secondary"
              type="submit"
              disabled={!formState.isValid || loading}
              fullWidth
            >
              Enviar código
            </Button>
          </StyledPrimaryActionsWrapper>

          <StyledNotificationBox visible={!!fetchingError && fetchingError?.length > 0}>
            <Notification type="error" messages={fetchingError} hFull />
          </StyledNotificationBox>

          <SyledResendCodeContainer>
            <Paragraph sizeVariant="sm">
              Verifica tu bandeja o puedes reenviar el mensaje de confirmación{" "}
              {count === 0 ? (
                <b> ahora</b>
              ) : (
                <>
                  en <b>{count} segundos</b>.
                </>
              )}
            </Paragraph>

            <Button
              sizeVariant="sm"
              variant="outlined"
              color="neutral"
              disabled={count > 0}
              onClick={handleClick}
              fullWidth
            >
              Reenviar código
            </Button>
          </SyledResendCodeContainer>
        </StyledActionsWrapper>
      </StyledWrapperRight>
    </>
  );
};
export default ConfirmPhoneCode;
