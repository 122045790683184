import { type TwStyle } from "twin.macro";
import type { TSize } from "@/types/common";
import { StyledWrapperChip, StyledWrapperRoundedChip } from "./styles";
import Caption from "../Caption";

interface ChipPropsBase {
  label: string;
  bgColor: TwStyle;
  size?: TSize;
  [key: string]: any;
}

interface ChipPropsRounded extends ChipPropsBase {
  textColor: TwStyle;
  variant: "rounded";
}

interface ChipPropsArrow extends ChipPropsBase {
  borderColor: TwStyle;
  variant: "arrow";
}

type ChipProps = ChipPropsRounded | ChipPropsArrow;

const Chip = ({ label, bgColor, size, variant, textColor, borderColor, ...props }: ChipProps) => {
  if (variant === "rounded") {
    return (
      <StyledWrapperRoundedChip {...props} $bgColor={bgColor} $textColor={textColor} $size={size}>
        <Caption>{label}</Caption>
      </StyledWrapperRoundedChip>
    );
  }

  return (
    <StyledWrapperChip {...props} $bgColor={bgColor} $borderColor={borderColor} $size={size}>
      <Caption>{label}</Caption>
    </StyledWrapperChip>
  );
};

export default Chip;
