import { useState } from "react";

/**
 * Will be fix when react-scripts updates its typescript-eslint deps
 * @see https://github.com/typescript-eslint/typescript-eslint/issues/2260#issuecomment-680197439
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useLocalStorage = <T>(key: string, initialValue: T) => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    const item = window.localStorage.getItem(key);
    return item !== null ? (JSON.parse(item) as T) : initialValue;
  });

  const setValue = (value: T | ((prevState: T) => T)): void => {
    if (value === null) {
      setStoredValue(value);
      window.localStorage.removeItem(key);
    } else {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    }
  };

  return [storedValue, setValue] as const;
};
