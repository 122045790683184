import { gql } from "@apollo/client";

export const GET_CATEGORIES_HEADER = gql`
  query getCategories($storeId: String!) {
    categories(storeId: $storeId, level: 0) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;

export const GET_CATEGORIES = gql`
  query getCategories($storeId: String!) {
    categories(storeId: $storeId, level: 0) {
      edges {
        node {
          pk
          name
          slug
          backgroundColor
          children {
            edges {
              node {
                id
                name
                slug
              }
            }
          }
          image(size: "540x540") {
            alt
            url
          }
        }
      }
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query getCategories($storeId: String!) {
    categories(storeId: $storeId, level: 0) {
      edges {
        node {
          id
          pk
          name
          slug
          children {
            totalCount
            edges {
              node {
                id
                pk
                name
                slug
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_FILTERS_BRAND_HUBS_CATEGORIES = gql`
  query getFiltersBrandHubsAndCategories($storeId: String!) {
    brands(storeId: $storeId) {
      edges {
        node {
          name
          slug
        }
      }
    }
    categories(storeId: $storeId) {
      edges {
        node {
          name
          slug
        }
      }
    }
    hubs(storeId: $storeId) {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`;
