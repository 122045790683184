import Button from "@/components/Atoms/Button";
import { StyledWrapperHeader, StyledLogoFithubPrimary } from "./styles";
import { Link } from "react-router-dom";

const HeaderCheckout = () => {
  return (
    <StyledWrapperHeader>
      <Link to="/" title="Fithub">
        <StyledLogoFithubPrimary />
      </Link>

      <Button
        sizeVariant="lg"
        color="secondary"
        variant="outlined"
        as={Link}
        to="/"
        title="Regresar as comprar"
      >
        Regresar a comprar
      </Button>
    </StyledWrapperHeader>
  );
};

export default HeaderCheckout;
