import { type UserPriceType } from "../../store/user";
import { request } from "../../utils/request";

const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;
const CREDITS_AVAILABLE = "credits-available";
const REFERRAL_CREDITS_AVAILABLE = "referral-credits-available/";
const MOVEMENTS = "movements/";

const getCreditsAvailable = async (): Promise<PriceType> => {
  const response = await request<PriceType>(`${BASE_API_URL}api/v1/${CREDITS_AVAILABLE}/`);

  return response.data;
};

const getReferralCreditsAvailable = async (): Promise<UserPriceType> => {
  const response = await request<UserPriceType>(`${BASE_API_URL}api/v1/${REFERRAL_CREDITS_AVAILABLE}`);

  return response.data;
};

const getReferralCreditsAvailableMovements = async (
  nextPage = null
): Promise<getReferralCreditsAvailableMovementsResponse> => {
  const response = await request<getReferralCreditsAvailableMovementsResponse>(
    nextPage || `${BASE_API_URL}api/v1/${REFERRAL_CREDITS_AVAILABLE}${MOVEMENTS}`,
    {
      method: "GET",
    }
  );

  return response.data;
};

export interface getReferralCreditsAvailableMovementsResponse {
  count: number;
  next: string | null;
  previous: string | null;
  results: getReferralCredits[];
}

export interface getReferralCredits {
  amount: PriceType;
  cancellation_reason: string | null;
  created_at: string;
  expired_at: string | null;
  expires_at: string | null;
  id: number;
  last_amount: PriceType;
  reason: string;
  source: string;
  status: string;
  status_display: string;
  user: number;
  user_notified_at: string | null;
  referred_user: {
    email: string;
    first_name: string;
    last_name: string;
  } | null;
}

export { getCreditsAvailable, getReferralCreditsAvailable, getReferralCreditsAvailableMovements };
