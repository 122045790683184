import { type PropsWithChildren } from "react";
import tw, { css, type TwStyle } from "twin.macro";
import BackDrop from "../BackDrop";
import Card from "../Card";
import { type RuleSet } from "styled-components";

export type ModalProps = PropsWithChildren<{
  isOpen: boolean;
  handleClose: () => void;
  classes?: {
    backdrop?: RuleSet<object> | TwStyle;
    card?: RuleSet<object> | TwStyle;
  };
  preventScroll?: boolean;
  closeOutSise?: boolean;
  fullScreen?: boolean;
  showCenter?: boolean;
}>;

const Modal = ({
  children,
  isOpen,
  classes,
  handleClose,
  closeOutSise = false,
  fullScreen = false,
  preventScroll = true,
  showCenter = false,
}: ModalProps) => {
  if (!isOpen) return null;

  return (
    <BackDrop
      preventScroll={preventScroll}
      onClose={() => closeOutSise && handleClose()}
      classes={{
        root: css`
          ${tw`bg-[rgba(66, 73, 87, 0.50)] z-[51]`}
          ${fullScreen ? tw`block overflow-y-auto` : ""}
          ${classes?.backdrop && classes?.backdrop}
          ${showCenter ? tw`grid [justify-content:normal]` : ""}
        `,
      }}
    >
      <Card
        classes={{
          root: css`
            ${tw`shadow-elevation-modal rounded-lg bg-primaryV2-100 w-full overflow-hidden `}
            ${fullScreen ? tw`w-full flex flex-col rounded-none min-h-screen` : ""}
            ${classes?.card && classes?.card}
             ${showCenter ? tw`mx-auto` : ""}
          `,
        }}
      >
        {children}
      </Card>
    </BackDrop>
  );
};

export default Modal;
