import { type TSize } from "@/types/common";
import { StyledFlagEmoji } from "./styles";
import React from "react";

interface FlagEmojiProps {
  ISO: string;
  size?: TSize;
}

const FlagEmoji: React.FC<FlagEmojiProps> = ({ ISO, size }) => {
  const codePoints = ISO.toUpperCase()
    .split("")
    .map((char) => 127397 + char.charCodeAt(0));
  const flag = String.fromCodePoint(...codePoints);

  return <StyledFlagEmoji $size={size}>{flag}</StyledFlagEmoji>;
};

export default FlagEmoji;
