import { type CSSProperties } from "react";
import tw, { styled, css, theme } from "twin.macro";
import type { ITypographyStyledCommon } from "@/types/common";

interface IStyledWrapperCaption extends Omit<ITypographyStyledCommon, "$sizeVariant"> {
  $isItalic?: boolean;
  $fontWeight?: CSSProperties["fontWeight"];
}

const captionStyles = {
  small: tw`text-[0.6875rem]`,
  medium: tw`text-[0.75rem]`,
  large: tw`text-[0.8125rem]`,
};

export const StyledWrapperCaption = styled.p<IStyledWrapperCaption>`
  ${tw`font-primaryV2 font-normal transition-all ease-in-out duration-300 text-neutral-10 leading-[normal]`}

  & > * {
    ${tw`[font-family: inherit] font-[inherit] [color: inherit]`};
  }

  ${({ $size }) => {
    if ($size) {
      return captionStyles[$size];
    }

    return css`
      ${captionStyles.small}

      @media (min-width: ${theme`screens.medium`}) {
        ${captionStyles.medium}
      }

      @media (min-width: ${theme`screens.large`}) {
        ${captionStyles.large}
      }
    `;
  }}

  ${({ $isItalic }) => $isItalic && tw`italic`}

  ${({ $color }) =>
    $color &&
    css`
      color: ${$color};
    `}

    ${({ $fontWeight }) => {
    if ($fontWeight) {
      return css`
        font-weight: ${$fontWeight};
      `;
    }
  }};
`;
