import tw, { css, styled } from "twin.macro";
import type { CSSProperties } from "react";

import type { TSize } from "@/types/common";
import applyResponsiveStyles from "@/utils/applyResponsiveStyles";

const styles = {
  wrapper: {
    base: tw`rounded-round bg-neutral p-xs flex items-center justify-center`,
    size: {
      small: tw`w-5 h-5`,
      medium: tw`w-[1.625rem] h-[1.625rem]`,
      large: tw`w-8 h-8`,
    },
  },
};

export const StyledWrapperAvatar = styled.div<{
  $size?: TSize;
  $backgroundColor?: CSSProperties["backgroundColor"];
}>`
  ${styles.wrapper.base}

  ${({ $backgroundColor }) => {
    if ($backgroundColor) {
      return css`
        background: ${$backgroundColor};
      `;
    }
  }}
  ${({ $size }) => applyResponsiveStyles({ stylesBreakpoints: styles.wrapper.size, size: $size })}
`;
