import { createContext, type PropsWithChildren, useContext } from "react";
import useFloatingUI, { type IuseFloatingUI } from "@hooks/useFloatingUI";

type ContextType = ReturnType<typeof useFloatingUI> | null;

export const FloatingUIContext = createContext<ContextType>(null);

const useFloatingUIContext = () => {
  const context = useContext(FloatingUIContext);

  if (context === null) {
    throw new Error("To use the component they must be wrapped in <FloatingUIContext.Provider />");
  }

  return context;
};

export type IFloatingUIProvider = PropsWithChildren<IuseFloatingUI>;

const FloatingUIProvider = ({ children, ...options }: IFloatingUIProvider) => {
  const floating = useFloatingUI(options);
  return <FloatingUIContext.Provider value={floating}>{children}</FloatingUIContext.Provider>;
};

export { useFloatingUIContext, FloatingUIProvider };
