import tw, { styled } from "twin.macro";
import IconClose from "@iconsV2/Edit/close.svg?react";

export const StyledWrapperProductCard = styled.div`
  ${tw`flex w-full flex-col py-s px-m gap-xs mb-4
    medium:(p-s mb-0)
  `}
`;

export const StyledWrapperButton = styled.div`
  ${tw`fixed bottom-0 w-full bg-primaryV2-100 shadow-elevation-top py-s px-m rounded-b-l`}
`;

export const StyledWrapperCatgoriesAndHubs = styled.div`
  ${tw`flex flex-col gap-m p-m`}
`;

export const StyledWrapperEmpty = styled.div`
  ${tw`flex flex-col gap-xxs medium:gap-xs large:gap-s`}

  & > div {
    ${tw`flex gap-xxs medium:gap-s`}
  }
`;
export const StyledWrapperContent = styled.div`
  ${tw`flex flex-col`}
`;

export const StyledWrapperSuggests = styled.div`
  ${tw`flex flex-col gap-xxs bg-secondary-100 border-b border-neutral-90 py-s px-m medium:(p-s)`}
`;

export const StyledWrapperHeading = styled.div`
  ${tw`flex items-center justify-between w-full py-xxs px-xs`}
`;

export const StyledSuggest = styled.button`
  ${tw`flex text-left items-center justify-between gap-m p-sm transition-colors text-[0.875rem] rounded-l hover:bg-neutral-90`}
`;

export const StyledIconCloseSuggest = styled(IconClose)`
  ${tw`w-[1.375rem] h-[1.375rem] fill-neutral-40`}
`;

export const StyledLoaderWrapper = styled.div`
  ${tw`flex justify-center items-center gap-s
`}
`;
