import Input from "@/components/Molecules/Input";
import Button from "@/components/Atoms/Button";
import Paragraph from "@/components/Atoms/Paragraph";
import { type FnCloseModal } from "@/components/Organisms/ModalAddressForm";
import { type ChangeEvent, type FormEvent, useState, useEffect } from "react";
import useGoogleReCaptchaV3 from "@/hooks/useGoogleReCaptchaV3";
import { SITEKEYCAPTCHA } from "@/recourses/constants";
import { sendWaitingList } from "@/services/common/waitingList";
import { useMutation } from "@tanstack/react-query";
import CheckLocation from "@iconsV2/Illustration/checkLocation.svg?react";
import { StyledCityFeedbackForm, StyledCityFeedbackSuccess } from "./styles";
import { generateLead } from "@/utils/analyticsV2";

interface WaitingListData {
  city?: string | null;
  email?: string | null;
  recaptcha_token: string;
}

interface CityFeedbackFormProps {
  isVisible: boolean;
  cityDefault?: string;
  cityForm?: string;
  onChangeCityForm: (cityName: string) => void;
  handleVisible: (open: boolean) => void;
  onClose: FnCloseModal;
  onAccept?: () => void;
}

interface Errors {
  city?: string;
  email?: string;
  recaptcha_token?: string;
  detail: string;
}

const CityFeedbackForm = ({
  isVisible,
  cityDefault,
  cityForm,
  onChangeCityForm,
  handleVisible,
  onClose,
  onAccept,
}: CityFeedbackFormProps) => {
  const { executeReCaptcha } = useGoogleReCaptchaV3({
    siteKey: SITEKEYCAPTCHA,
    shouldLoad: isVisible,
  });

  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState<Errors | null>(null);
  const [loading, setLoading] = useState(false);
  const [successFeedback, setSuccessFeedback] = useState<boolean>(false);

  const mutation = useMutation({
    mutationFn: async (data: WaitingListData) => await sendWaitingList(data),
    onSuccess: () => {
      handleVisible(false);
      setSuccessFeedback(true);
      setLoading(false);
    },
    onError: (errors: any) => {
      setLoading(false);
      if (errors?.data) {
        setErrors(errors?.data as Errors);
      } else {
        setErrors({ detail: "Ha ocurrido un error, por favor intenta de nuevo." });
      }
    },
  });

  async function onSubmit(event: FormEvent<HTMLFormElement>) {
    event.preventDefault();

    if (!cityForm || !email) return;

    setLoading(true);
    const recaptchaToken = await executeReCaptcha("submit");

    if (recaptchaToken) {
      const data: WaitingListData = {
        city: cityForm,
        email,
        recaptcha_token: recaptchaToken,
      };

      mutation.mutate(data);
    }
  }

  function onChange(event: ChangeEvent<HTMLInputElement>, type: "city" | "email") {
    if (type === "city") {
      onChangeCityForm(event.target.value);
      if (errors?.city) {
        const { city, ...rest } = errors;
        setErrors(rest);
      }
    } else if (type === "email") {
      setEmail(event.target.value);
      if (errors?.email) {
        const { email, ...rest } = errors;
        setErrors(rest);
      }
    }
  }

  const handleOpenForm = () => {
    setSuccessFeedback(false);
    onChangeCityForm("");
    handleVisible(true);
  };

  useEffect(() => {
    if (isVisible) {
      generateLead.generateLeadForm({
        formFields: ["city", "email"],
        formLength: 2,
        formName: "newletter-city-feeback",
      });
    }
  }, [isVisible]);

  return (
    <>
      {!isVisible && !successFeedback && (
        <div tw="px-m flex justify-center w-full mb-m">
          <Button
            color="secondary"
            sizeVariant="md"
            variant="text"
            type="submit"
            onClick={handleOpenForm}
            fullWidth
          >
            ¿Te encuentras en otra ciudad?
          </Button>
        </div>
      )}

      {successFeedback && !isVisible && (
        <StyledCityFeedbackSuccess>
          <CheckLocation />

          <div tw="flex flex-col text-center items-center gap-s">
            <Paragraph sizeVariant="lg">¡Gracias por tu información!</Paragraph>

            <Paragraph sizeVariant="md">
              Te mostramos los productos disponibles para {cityDefault ?? "Barranquilla"} y te contactaremos
              cuando expandamos a nuevas ciudades.
            </Paragraph>
          </div>

          <div>
            <Button
              color="secondary"
              sizeVariant="lg"
              variant="filled"
              onClick={() => {
                onClose();
                onAccept?.();
              }}
            >
              Entendido
            </Button>
          </div>
        </StyledCityFeedbackSuccess>
      )}

      {isVisible && (
        <StyledCityFeedbackForm onSubmit={onSubmit}>
          <Paragraph sizeVariant="lg">
            Cuéntanos dónde estás y nosotros te avisamos cuando tengamos cobertura allí.
          </Paragraph>
          <Input
            type="text"
            label="¿En qué ciudad te encuentras?"
            placeholder="Ciudad de domicilio"
            size="medium"
            required
            name="city"
            value={cityForm}
            errors={errors?.city ? true : undefined}
            textHelp={errors?.city}
            onChange={(ev) => onChange(ev, "city")}
          />
          <Input
            type="email"
            label="¿Cuál es tu correo para poder avisarte cuando nuestra cobertura llegue allí?"
            placeholder="Ingresa tu correo"
            size="medium"
            autoComplete="email"
            required
            name="email"
            errors={errors?.email ? true : undefined}
            textHelp={errors?.email}
            onChange={(ev) => onChange(ev, "email")}
          />
          <div>
            <Button color="secondary" sizeVariant="lg" variant="filled" type="submit" disabled={loading}>
              Enviar
            </Button>
          </div>
          {errors?.detail && typeof errors.detail === "string" && (
            <Paragraph sizeVariant="sm" tw="text-error-40">
              {errors.detail}
            </Paragraph>
          )}
          {errors?.recaptcha_token && typeof errors.recaptcha_token === "object" && (
            <Paragraph sizeVariant="sm" tw="text-error-40">
              {errors.recaptcha_token[0]}
            </Paragraph>
          )}
        </StyledCityFeedbackForm>
      )}
    </>
  );
};

export default CityFeedbackForm;
