import { ApolloClient, InMemoryCache } from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
const BASE_API_URL = import.meta.env.REACT_APP_BASE_API_URL;

export const apolloClient = new ApolloClient({
  uri: `${BASE_API_URL}graphql/`,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          products: offsetLimitPagination(),
        },
      },
    },
  }),
  connectToDevTools: import.meta.env.REACT_APP_ENVIRONMENT !== "prod",
});
