import { forwardRef } from "react";
import TextField, { type ITextFieldProps } from "@atoms/TextField";
import FormControl from "../FormControl";

export interface IInputProps extends ITextFieldProps {
  label: string; // If you do not need a label, please use Atoms/TextField.
  textHelp?: string;
}

const Input = forwardRef<HTMLInputElement, IInputProps>(
  ({ label, textHelp, fullWidth = true, id, ...props }, ref) => {
    return (
      <FormControl
        label={label}
        textHelp={textHelp}
        disabled={props.disabled}
        success={props.success}
        errors={props.errors}
        fullWidth={fullWidth}
        size={props.size}
        id={id}
      >
        <TextField {...props} fullWidth={fullWidth} ref={ref} />
      </FormControl>
    );
  }
);

export default Input;
