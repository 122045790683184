import tw, { styled } from "twin.macro";

export const StyledModalHearAboutUsRegistrationRoot = styled.div`
  ${tw`flex max-w-[50rem] h-full`}
`;

export const StyledHeaderAboutUs = styled.div`
  ${tw`flex py-m justify-end bg-neutral-99 -mx-m pr-m`}
`;

export const StyledContentRoot = styled.div`
  ${tw`grid h-full w-full medium:(h-auto py-l) gap-m px-m`}
`;
export const StyledContentHearAboutUs = styled.div`
  ${tw`flex flex-col  gap-m w-full medium:(self-end) `}
`;

export const StyledRowContent = styled.div`
  ${tw`flex items-center gap-s last:(border-b pb-m border-b-neutral-90) large:(max-w-[20.43rem])`}
`;
