import { type FormDataConfirmCode } from "@/components/Molecules/ConfirmCodeController";
import FormSecurity from "@/components/Molecules/FormSecurity";
import { getTokenPlus } from "@/services/auth";
import Cookies from "js-cookie";
import { useState } from "react";
import { type renderMessagesProps } from "@/components/Atoms/Notification";
import Modal from "@/components/Atoms/Modal";
import useAppSelector from "@/hooks/useAppSelector";
import useAppDispatch from "@/hooks/useAppDispatch";
import { genericsAction } from "@/store/generics";
import tw from "twin.macro";
import { useMediaQuery } from "@/hooks/useMediaQuery";
import { StyledHeadModalGetTokenPlus, StyledIconClose } from "./styles";
import { CALLBACK_MODAL_TOKEN_PLUS } from "@/Pages/UpdatePhone";

export const ModalGetTokenPlus = () => {
  const [textHelps, setTextHelps] = useState<renderMessagesProps | undefined>();
  const [isLoading, setIsLoading] = useState(false);
  const isOpen = useAppSelector((state) => state.generics.modalTokenPlus.openModalTokenPlus);
  const dispatch = useAppDispatch();
  const isLarge = useMediaQuery("( min-width: {large} )");

  const getToken = async (data?: FormDataConfirmCode) => {
    if (!data?.code) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await getTokenPlus(data.code);
      const token = response.data.payload.token as string;
      setCookieWithExpiration(token);
      dispatch(
        genericsAction.setOpenModalTokenPlus({
          open: false,
          callbackIdentifier: CALLBACK_MODAL_TOKEN_PLUS.CONTINUE,
        })
      );
    } catch (error) {
      const errorResponse = error as ErrorV2;

      if (errorResponse.status === 400 && errorResponse.data?.message === "Invalid password") {
        setTextHelps({ messages: "La contraseña es incorrecta", type: "error" });
      } else {
        setTextHelps({
          messages: `Ha ocurrido un error inesperado ${errorResponse?.message}`,
          type: "error",
        });
      }
    } finally {
      setIsLoading(false);

      if (textHelps) {
        setTextHelps(undefined);
      }
    }
  };

  const setCookieWithExpiration = (value: string) => {
    const expiresInMinutes = 15 / (24 * 60);
    Cookies.set("authTokenPlus", value, { expires: expiresInMinutes });
  };

  const handleClose = () => {
    dispatch(genericsAction.setOpenModalTokenPlus({ open: false }));
  };
  return (
    <>
      <Modal
        closeOutSise
        showCenter={!isLarge}
        isOpen={isOpen}
        handleClose={handleClose}
        classes={{
          card: tw`w-[92%]  medium:(w-[32rem]) large:max-h-96`,
        }}
      >
        <StyledHeadModalGetTokenPlus>
          <StyledIconClose onClick={handleClose} />
        </StyledHeadModalGetTokenPlus>
        <FormSecurity onSubmit={getToken} type="password" textHelp={textHelps} processDone={!isLoading} />
      </Modal>
    </>
  );
};

export default ModalGetTokenPlus;
