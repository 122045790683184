import { useState, useEffect, useRef, type ChangeEvent } from "react";

/**
 * Custom hook to manage digit input fields. This hook allows the user to input a series
 * of digits across multiple input fields, handle backspace navigation, and properly distribute
 * pasted values across the fields.
 *
 * @param length - The number of digit input fields.
 * @param value - The current value representing the digit code.
 * @param onChange - Callback function to handle changes to the digit code.
 *
 * @returns An array of input properties for each digit input field.
 */
export function useDigitInput(length: number, value: string, onChange: (value: string) => void) {
  const [digits, setDigits] = useState<string[]>(Array(length).fill(""));
  const inputsRef = useRef<Array<HTMLInputElement | null>>([]);

  // Synchronize the internal state with the external value prop
  useEffect(() => {
    if (value.length === length) {
      setDigits(value.split(""));
    }
  }, [value, length]);

  // Handle individual digit changes
  const handleChange = (index: number) => (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const newDigits = [...digits];

    // Only allow numerical input
    if (/^\d*$/.test(inputValue)) {
      newDigits[index] = inputValue;
      setDigits(newDigits);
      onChange(newDigits.join(""));

      // Move focus to the next input if available
      const nextInput = inputsRef.current[index + 1];
      if (nextInput && inputValue.length === 1) {
        nextInput.focus();
      }
    }
  };

  // Handle backspace key press
  const handleKeyDown = (index: number) => (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Backspace") {
      if (digits[index] === "" && index > 0) {
        inputsRef.current[index - 1]?.focus(); // Move focus to the previous input if empty
      } else {
        const newDigits = [...digits];
        newDigits[index] = ""; // Clear the current digit
        setDigits(newDigits);
        onChange(newDigits.join(""));
      }
    }
  };

  // Handle pasting of a digit code
  const handlePaste = (index: number) => (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = e.clipboardData.getData("text");
    const newDigits = [...digits];

    if (/^\d*$/.test(pasteData)) {
      pasteData.split("").forEach((digit, i) => {
        if (index + i < length) {
          newDigits[index + i] = digit;
        }
      });
      setDigits(newDigits);
      onChange(newDigits.join(""));

      // Focus on the last input that was filled by the paste action
      const nextInput = inputsRef.current[index + pasteData.length - 1];
      nextInput?.focus();

      e.preventDefault(); // Prevent default paste behavior
    }
  };

  // Return the properties for each digit input field
  return digits.map((digit, index) => ({
    ref: (el: HTMLInputElement) => (inputsRef.current[index] = el),
    value: digit,
    onChange: handleChange(index),
    onKeyDown: handleKeyDown(index),
    onPaste: handlePaste(index),
    maxLength: 1,
  }));
}
