import dayjs, { type OpUnitType } from "dayjs";

export function formatDate(date: Date | string, formatStr: string = "DD/MM/YY") {
  return dayjs(date).format(formatStr);
}

export const hasPassedMoreThanDate = (date?: string, type: OpUnitType = "day", quantity: number = 1) => {
  if (!date) return;
  const now = dayjs();
  const dateToCompare = dayjs(date);

  // Calculate the difference in days
  const daysDifference = now.diff(dateToCompare, type);

  // Return true if the difference is greater than days props
  return daysDifference >= quantity;
};
