import React from "react";
import "twin.macro";

export const Headphones = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg tw="fill-current" viewBox="0 0 24 24" {...props}>
    <defs>
      <path
        id="head"
        d="M12 2C6.5 2 2 6.5 2 12v7c0 1.7 1.3 3 3 3h1c1.7 0 3-1.3 3-3v-3c0-1.7-1.3-3-3-3H4v-1c0-4.4 3.6-8 8-8s8 3.6 8 8v1h-2c-1.7 0-3 1.3-3 3v3c0 1.7 1.3 3 3 3h1c1.7 0 3-1.3 3-3v-7c0-5.5-4.5-10-10-10zM6 15c.6 0 1 .4 1 1v3c0 .6-.4 1-1 1H5c-.6 0-1-.4-1-1v-4h2zm14 4c0 .6-.4 1-1 1h-1c-.6 0-1-.4-1-1v-3c0-.6.4-1 1-1h2v4z"
      />
    </defs>
    <g fillRule="evenodd">
      <mask id="head2">
        <use xlinkHref="#head" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#head" />
      <g mask="url(#head2)">
        <path d="M0 0h24v24H0z" />
      </g>
    </g>
  </svg>
);
