import { useCallback, useEffect } from "react";

import { SITEKEYCAPTCHA } from "@/recourses/constants";
import { getProjectWindow, injectScript, removeScript } from "@/recourses/captchaV3";
const SCRIPT_ID = "google-recaptcha-script";
const ERROR_SCRIPT_NOT_AVAILABLE = "Google reCAPTCHA is not available";
const ERROR_NOT_A_WEB_BROWSER = "Running outside a web browser";

interface IGoogleReCaptchaOptions {
  action?: string;
  siteKey?: string;
  shouldLoad?: boolean;
  asynch?: boolean;
}

interface IGoogleReCaptchaV3HookReturn {
  executeReCaptcha: (action?: string) => Promise<string>;
}

type TGoogleReCaptchaV3Hook = (options?: IGoogleReCaptchaOptions) => IGoogleReCaptchaV3HookReturn;

const useGoogleReCaptchaV3: TGoogleReCaptchaV3Hook = (options = {}) => {
  const { action = "load", siteKey = SITEKEYCAPTCHA, shouldLoad = false, asynch = false } = options;

  const executeReCaptcha = useCallback(
    async (action: string = "default"): Promise<string> => {
      const window = getProjectWindow();

      if (!window) {
        throw new Error(ERROR_NOT_A_WEB_BROWSER);
      }

      const { grecaptcha } = window as any;
      if (!grecaptcha) {
        throw new Error(ERROR_SCRIPT_NOT_AVAILABLE);
      }

      return await new Promise((resolve) => {
        grecaptcha.ready(() => {
          grecaptcha.execute(siteKey, { action }).then((token: string) => resolve(token));
        });
      });
    },
    [siteKey]
  );

  useEffect(() => {
    if (!siteKey) return;
    if (!shouldLoad) return;

    const window = getProjectWindow();
    if (window) {
      const scriptTag = window.document.getElementById(SCRIPT_ID);

      if (!scriptTag) {
        injectScript(SCRIPT_ID, `https://www.google.com/recaptcha/api.js?render=${siteKey}`);
        const badge = document.querySelector(".grecaptcha-badge");
        badge?.setAttribute("style", "visibility: hidden");
      }
    }

    return () => {
      removeScript(SCRIPT_ID);
    };
  }, [siteKey, action, asynch, shouldLoad]);

  return {
    executeReCaptcha,
  };
};

export default useGoogleReCaptchaV3;
