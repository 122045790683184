import { createPortal } from "react-dom";
import { useDispatch } from "react-redux";
import tw, { css, theme } from "twin.macro";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";

import { X } from "../Icons";
import { useTypedSelector } from "../../store";
import { confirmationModalSlice } from "../../store/confirmationModal";
import { ModalTitle } from "./Components/ModalTitle";
import { ModalFooter } from "./Components/ModalFooter";

const modalRoot = document.getElementById("modal-root") as HTMLDivElement;

export interface ModalProps {
  isOpen: boolean;
  title: string;
  body: any;
  maxWidth?: string;
  dismissable?: boolean;
  buttonCancelTitle?: string;
  buttonAcceptTitle?: string;
  onSuccess?: (data?: any) => void;
}

export const ConfirmationModal = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const paramsModal = useTypedSelector((state) => state.confirmationModal);
  const dismissable = paramsModal.dismissable ?? false;
  const isMobile = useMediaQuery({ query: `(max-width: ${theme`screens.md`})` });
  const preventPropagation = (event: React.MouseEvent<HTMLElement, MouseEvent>): void =>
    event.stopPropagation();
  const onModalClose = (): any => dispatch(confirmationModalSlice.actions.emptyConfirmation());

  const onModalAccepted = (): void => {
    if (paramsModal.onSuccess) {
      paramsModal.onSuccess();
    }
    dispatch(confirmationModalSlice.actions.emptyConfirmation());
    setIsLoading(false);
  };

  useEffect(() => {
    setIsOpen(paramsModal.isOpen);
  }, [paramsModal]);

  const textButtonCancel = paramsModal.buttonCancelTitle ? paramsModal.buttonCancelTitle : "CANCELAR";
  const textButtunAccept = paramsModal.buttonAcceptTitle ? paramsModal.buttonAcceptTitle : "ACEPTAR";
  if (!isOpen) return <React.Fragment />;

  return createPortal(
    <div
      css={[
        css`
          backdrop-filter: blur(5px);
          ${tw`fixed top-0 w-screen h-screen z-40 flex items-center justify-center px-3`}
        `,
      ]}
      aria-modal="true"
      role="dialog"
      onClick={(): any => dismissable && onModalClose()}
    >
      <section
        css={[
          isMobile
            ? css`
                width: 98%;
                min-height: 13rem;
                ${tw`flex flex-col relative rounded-2xl bg-white overflow-visible shadow-2xl`}
              `
            : css`max-width: ${paramsModal.maxWidth ? `${paramsModal.maxWidth};` : "31rem;"} min-width: 25rem; min-height: 13rem;
                ${tw`flex flex-col relative rounded-2xl bg-white overflow-visible shadow-2xl`}`,
        ]}
        onClick={preventPropagation}
      >
        {dismissable && (
          <button
            css={[tw`absolute z-10 right-0 mt-3 mr-3 text-primary-200`, tw`lg:(left-0 ml-5 mr-0)`]}
            onClick={onModalClose}
            type="button"
          >
            <X width="1.5rem" />
          </button>
        )}
        <div
          css={[
            isMobile
              ? tw`px-5 pb-4 flex flex-col justify-between`
              : tw`px-5 pb-8 flex flex-col justify-between w-full h-full`,
          ]}
        >
          <div>
            <ModalTitle title={paramsModal.title} />
          </div>
          <div tw="w-full pt-4 ">
            <div tw="text-base" dangerouslySetInnerHTML={{ __html: paramsModal.body }} />
          </div>
          {/* <div tw="flex flex-col md:(flex-row) justify-between w-full">
                        <Button css={ [ tw`w-full`, isMobile ? tw`text-xs` : tw`mx-1 text-base` ] } onClick={ () => { setIsLoading(true); onModalAccepted(); } } isLoading={ isLoading }>
                            { textButtunAccept }
                        </Button>
                        <Button css={ [ tw`w-full`, isMobile ? tw`text-xs mt-2` : tw`mx-1 text-base` ] } isPrimary={ false } isSecondary={ true } onClick={ onModalClose }>
                            { textButtonCancel }
                        </Button>
                    </div> */}
        </div>
        <ModalFooter
          showPrimaryButton={true}
          showSecondaryButton={true}
          PrimaryButtonConfig={{
            isLoading,
            css: [tw`w-full md:(w-6/12) `],
            onClick: () => {
              setIsLoading(true);
              onModalAccepted();
            },
            content: <p tw="">{textButtunAccept}</p>,
          }}
          SecondaryButtonConfig={{
            css: [tw`w-full md:(w-6/12)`],
            onClick: onModalClose,
            content: <p tw="">{textButtonCancel}</p>,
          }}
        />
      </section>
    </div>,
    modalRoot
  );
};
