import { type Location, type SetURLSearchParams } from "react-router-dom";
import type { TQueryParams } from "@/types/common";

export const setQueryParams = (
  params: TQueryParams,
  location: Location,
  setSearchParams: SetURLSearchParams
) => {
  const searchParams = new URLSearchParams(location.search);

  Object.entries(params).forEach(([key, value]) => {
    if (!value) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, String(value));
    }
  });

  // Validate if the previous route its the same, if it is the same route no redirect
  if (location.search === "?" + searchParams.toString()) {
    return;
  }

  setSearchParams(searchParams);
};
