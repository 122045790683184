import Icon404 from "@/components/Icons/V2/Communication/404.svg?react";
import Error from "@/components/Molecules/Error";

const Error404 = () => {
  return (
    <Error
      options={{
        title: "La página que buscas no se encuentra disponible",
        message:
          " Comprueba que la URL sea la correcta, intenta usando el buscador o ve a la página inicial.",
        withHeader: true,
        hScreen: true,
        adornment: <Icon404 fill="transparent" width={250} height={250} aria-label="Icono de Lupa" />,
      }}
      buttonsProps={{
        color: "primary",
        sizeVariant: "lg",
        buttonText: "Volver al Inico",
        to: "/",
      }}
    />
  );
};
export default Error404;
